<template>
    <div class="d-flex justify-content-between">
        <Sidebar></Sidebar>
        <Content class="content">
            <slot/>
        </Content>
        <TariffSnapshotModal/>
        <LocalitySnapshotModal/>
        <TvChannelsModal/>
        <ShowLocalitiesModal />
        <ProductModal />
        <LocalitiesPickerModal />
        <ScriptModal />
        <NoteMessageModal/>
    </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import TariffSnapshotModal from "@/modals/TariffSnapshotModal";
import LocalitySnapshotModal from "@/modals/LocalitySnapshotModal";
import Sidebar from "@/components/Sidebar";
import Content from "@/components/Content";
import TvChannelsModal from "@/modals/TvChannelsModal";
import ShowLocalitiesModal from "@/modals/ShowLocalitiesModal";
import ProductModal from "@/modals/ProductModal.vue";
import LocalitiesPickerModal from "@/modals/LocalitiesPickerModal.vue";
import ScriptModal from "@/modals/ScriptModal.vue";
import NoteMessageModal from "@/modals/NoteMessageModal";

export default {
    name: "DefaultLayout",
    components: {
        NoteMessageModal,
        Preloader,
        TariffSnapshotModal,
        LocalitySnapshotModal,
        Sidebar,
        Content,
        TvChannelsModal,
        ShowLocalitiesModal,
        ProductModal,
        LocalitiesPickerModal,
        ScriptModal
    },
}
</script>

<style scoped>

</style>
