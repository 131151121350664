<template>
    <div>
        <label class="font-weight-bold">{{ $t('Телефон') }}</label>
        <div class="card p-4 bg-light">
            <div>
                <div v-for="(item, index) in phones" :key="index" class="input-group mb-4">
                    <div class="input-group-prepend">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ types[phones[index].type] || $t('Тип') }}
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="(name, type) in types"
                               :key="type"
                               @click.prevent="phones[index].type = type" href="#">
                                {{ name }}
                            </a>
                        </div>
                    </div>
                    <input v-model="phones[index].phone" type="text" class="form-control">
                    <div class="input-group-append">
                        <span v-if="isValidPhone(index)" class="input-group-text text-success">
                            <i class="fas fa-check"></i>
                        </span>
                        <button class="btn btn-danger"
                                @click="remove(index)" type="button">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
                <button :disabled="filledPhones.length !== phones.length"
                        @click="phones.push({type: '', phone: ''})"
                        class="btn btn-primary btn-block">
                        {{ $t('Добавить номер') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import {userPhoneTypes} from "@/views/Users"

export default {
    name: "CreateUserModalPhones",
    data() {
        return {
            types: userPhoneTypes,
            phones: [],
        }
    },
    methods: {
        remove(remIndex) {
            this.phones = this.phones.filter((item, index) => index !== remIndex);
        },
        isValidPhone(index) {
            return this.phones[index].phone.length > 2
                && this.phones[index].type.length;
        }
    },
    computed: {
        filledPhones() {
            return this.phones.filter(item => {
                return item.phone.length > 3 && item.type.length;
            });
        }
    },
    watch: {
        filledPhones: {
            deep: true,
            handler(val) {
                this.$emit('change', val);
            }
        }
    }
}
</script>

<style lang="less">

</style>
