<template>
    <FullHeightBlock :offsetBottom="10" class="custom-scroll">
        <div :key="key" class="overflow-hidden pr-1">
            <div v-if="preloaderLoading" class="flex-fill">
                <div class="w-100 d-flex justify-content-center align-items-center" style="padding-top: 50%;">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div v-else class="flex-fill">
                <div v-if="!currentGroupNotes.length && !currentGroupGroupsNotes.length"
                     class="w-100 font-weight-bolder text-muted text-center" style="padding-top: 50%;">
                    {{ $t('Нет заметок для текущей группы тарифов') }} <br>
                    <a v-if="is('administrator') && (isUserDepartment || isDepartment('su'))"
                       @click="openCreateLocalityNoteModal"
                       href="#" class="font-weight-bold">{{ $t('Добавить для одного НП') }}</a>
                    <br>
                    <a v-if="is('administrator') && (isUserDepartment || isDepartment('su'))"
                       @click="openCreateLocalityGroupNoteModal"
                       href="#" class="font-weight-bold">{{ $t('Добавить для нескольких НП') }}</a>
                </div>
                <div v-else class="d-flex flex-column pt-4">
                    <button v-if="is('administrator') && (isUserDepartment || isDepartment('su'))"
                            class="btn btn-success d-block mb-4 dropdown-toggle position-relative"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t('Добавить заметку') }}
                        <div class="dropdown-menu">
                            <a @click.stop="openCreateLocalityNoteModal" class="dropdown-item font-weight-bold"
                               href="#">
                                <i class="fas fa-plus fa-fw"></i>
                                {{ $t('Для одного НП') }}
                            </a>
                            <div class="dropdown-divider"></div>
                            <a @click.stop="openCreateLocalityGroupNoteModal" class="dropdown-item font-weight-bold"
                               href="#">
                                <i class="fas fa-tasks fa-fw"></i>
                                {{ $t('Для нескольких НП') }}
                            </a>
                        </div>
                    </button>

                    <SortableBox datasetKey="id"
                                 :preloader="preloaderSortNotes"
                                 :enabled="is('administrator')"
                                 @onSort="onSortNotes">
                        <div v-for="(item, index) in sortedLocalityNotes"
                             :data-id="item.id"
                             :key="item.id"
                             class="pb-4">
                            <LocalityNote controls
                                          :sortable="sortedLocalityNotes.length > 1"
                                          :note="item"
                                          @deleted="loadLocalityNotes">
                            </LocalityNote>
                        </div>
                    </SortableBox>

                    <div v-if="currentGroupNotes.length" class="notes-divider"></div>

                    <SortableBox datasetKey="id"
                                 :preloader="preloaderSortGroupNotes"
                                 :enabled="is('administrator')"
                                 @onSort="onSortGroupNotes">
                        <div v-for="(item, index) in sortedLocalityGroupNotes"
                             :data-id="item.id"
                             :key="item.id"
                             class="pb-4">
                            <LocalityGroupNote controls
                                               :sortable="sortedLocalityGroupNotes.length > 1"
                                               :note="item"
                                               @deleted="loadLocalityGroupNotes">
                            </LocalityGroupNote>
                        </div>
                    </SortableBox>
                </div>
            </div>
            <CreateLocalityNoteModal @created="loadLocalityNotes"/>
            <UpdateLocalityNoteModal @updated="loadLocalityNotes"/>
            <CreateLocalityGroupNoteModal @created="loadLocalityGroupNotes"/>
            <UpdateLocalityGroupNoteModal @updated="loadLocalityGroupNotes"/>
        </div>
    </FullHeightBlock>
</template>

<script>

import LocalityNoteService from "@/services/LocalityNoteService";
import {EventBus, Events} from "@/EventBus";
import LocalityNote from "@/components/LocalityNote";
import CreateLocalityNoteModal from "@/modals/CreateLocalityNoteModal";
import UpdateLocalityNoteModal from "@/modals/UpdateLocalityNoteModal";
import FullHeightBlock from "@/components/FullHeightBlock";
import LocalityGroupNoteService from "@/services/LocalityGroupNoteService";
import LocalityGroupNote from "@/components/LocalityGroupNote";
import CreateLocalityGroupNoteModal from "@/modals/CreateLocalityGroupNoteModal";
import UpdateLocalityGroupNoteModal from "@/modals/UpdateLocalityGroupNoteModal";
import SortableBox from "@/components/SortableBox";
import OrderNotesService from "@/services/OrderNotesService";
import _ from "underscore";
import {mapState} from "vuex";

const LNote = new LocalityNoteService();
const LGroupNote = new LocalityGroupNoteService();
const OrderNotes = new OrderNotesService();

export default {
    name: "LocalityNotes",
    components: {
        LocalityNote,
        CreateLocalityNoteModal,
        UpdateLocalityNoteModal,
        FullHeightBlock,
        LocalityGroupNote,
        CreateLocalityGroupNoteModal,
        UpdateLocalityGroupNoteModal,
        SortableBox
    },
    props: {
        trademark_id: [String, Number],
        locality: Object,
        currentGroupID: [String, Number],
    },
    data() {
        return {
            preloaderLoading: false,
            preloaderSortGroupNotes: false,
            preloaderSortNotes: false,
            localityNotes: [],
            localityGroupNotes: [],
            orderNotes: {},
            ww: $(window).width(),
            key: Math.random()
        }
    },
    methods: {
        async init () {
            this.preloaderLoading = true;
            await this.loadOrderNotes();
            await this.loadLocalityGroupNotes();
            await this.loadLocalityNotes();
            this.preloaderLoading = false;
        },
        async onSortGroupNotes(e) {
            this.preloaderSortGroupNotes = true;
            const {code, body} = await OrderNotes.set(this.locality.id, this.currentGroupID, "group", e);

            if (code === 200) {
                await this.loadOrderNotes();
                this.$toast.success(this.$t('Порядок групповых заметок изменен'));
            } else {
                this.$toast.error(body.message);
            }

            this.preloaderSortGroupNotes = false;
        },
        async onSortNotes(e) {
            this.preloaderSortNotes = true;
            const {code, body} = await OrderNotes.set(this.locality.id, this.currentGroupID, "default", e);

            if (code === 200) {
                await this.loadOrderNotes();
                this.$toast.success(this.$t('Порядок заметок изменен'));
            } else {
                this.$toast.error(body.message);
            }

            this.preloaderSortNotes = false;
        },
        openCreateLocalityNoteModal() {
            EventBus.$emit(Events.CREATE_LOCALITY_NOTE_MODAL_OPEN, this.locality);
        },
        openCreateLocalityGroupNoteModal() {
            EventBus.$emit(Events.CREATE_LOCALITY_GROUP_NOTE_MODAL_OPEN);
        },
        async loadLocalityNotes() {
            const {code, body} = await LNote.fetchByLocalityId(this.locality?.id);

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.localityNotes = body;
            }
        },
        async loadLocalityGroupNotes() {
            const {code, body} = await LGroupNote.fetchByLocalityId(this.locality?.id);

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.localityGroupNotes = body;
            }
        },
        async loadOrderNotes() {
            const {code, body} = await OrderNotes.get(this.locality?.id, true);

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.orderNotes = body;
                this.key = Math.random();
            }
        },
        sortNotes(ids, notes) {

            if (!ids?.length) return notes

            return _.sortBy(notes, (item) => {
                return ids.indexOf(item.id)
            });
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            currentDepartment: state => state.currentDepartment
        }),
        isUserDepartment() {
            return this.user.department_id === this.currentDepartment?.id;
        },
        currentGroupNotes() {
            return this.localityNotes.filter(item => {
                return item.groups?.length === 0 || item.groups?.map(item => item * 1).indexOf(this.currentGroupID) > -1
            });
        },
        currentGroupGroupsNotes() {
            return this.localityGroupNotes.filter(item => {
                return item.group_id.length === 0 || item.group_id.indexOf(this.currentGroupID) > -1
            });
        },
        sortedLocalityNotes() {
            return this.sortNotes(this.orderNotes[this.currentGroupID]?.notes, this.currentGroupNotes)
        },
        sortedLocalityGroupNotes() {
            return this.sortNotes(this.orderNotes[this.currentGroupID]?.group_notes, this.currentGroupGroupsNotes).sort((a, b) => {
                if (b.color === 'dark') return -1;
            })
        },
        slidesPerPage() {
            if (this.ww > 1270) {
                return 3;
            } else if (this.ww > 800) {
                return 2;
            }

            return 1;
        },
        countAllNotes() {
            return this.currentGroupGroupsNotes?.length + this.currentGroupNotes?.length;
        }
    },
    watch: {
        async trademark_id() {
            await this.loadLocalityNotes();
        },
        countAllNotes(val) {
            this.$emit("init", val);
        }
    },
    mounted() {
        $(window).resize(() => {
            this.ww = $(window).width();
        });

        if (this.trademark_id) {
            this.init();

        }

        EventBus.$on(Events.DEPARTMENT_CURRENT_TAB_CHANGED, this.init);
    },
    beforeDestroy() {
        EventBus.$off(Events.DEPARTMENT_CURRENT_TAB_CHANGED, this.init);
    }
}
</script>

<style lang="less" scoped>

.notes-divider {
    width: 100%;
    height: 1px;
    background: #ccc;
    position: relative;
    top: -10px;
}

</style>
