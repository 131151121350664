<template>
    <div>
        <!-- Navbar -->
        <Navbar>
            <div>
                <h4 class="text-light mb-0 mx-3">{{ $t('Активность') }}</h4>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <div class="card py-3 mb-3">
                    <h4 class="mb-3 mx-3">{{ $t('Фильтры') }}</h4>
                    <!-- Фільтри -->
                    <FiltersComponent :users="users" :departments="departments" :filters.sync="filters"/>
                </div>
                <div v-show="activities.length" id="accordion" class="mb-3">
                    <div class="card">
                        <div id="headingChart" class="card-header d-flex justify-content-end">
                            <button
                                aria-controls="collapseChart"
                                aria-expanded="true"
                                class="btn btn-primary"
                                data-target="#collapseChart"
                                data-toggle="collapse"
                                @click="toggleChartDisplay"
                            >
                                {{ $t('График активности') }}
                            </button>
                        </div>

                        <div id="collapseChart" aria-labelledby="headingChart" class="collapse"
                             data-parent="#accordion">
                            <div class="card-body">
                                <button
                                    class="btn mr-3 mb-3"
                                    :class="[chartType === chartTypeEvent ? 'btn-primary' : 'btn-secondary']"
                                    @click="chartType = chartTypeEvent"
                                >
                                    {{ $t('Событие') }}
                                </button>
                                <button
                                    class="btn mr-3 mb-3"
                                    :class="[chartType === chartTypeDepartment ? 'btn-primary' : 'btn-secondary']"
                                    @click="chartType = chartTypeDepartment"
                                >
                                    {{ $t('Департамент') }}
                                </button>
                                <button
                                    class="btn mr-3 mb-3"
                                    :class="[chartType === chartTypeUser ? 'btn-primary' : 'btn-secondary']"
                                    @click="chartType = chartTypeUser"
                                >
                                    {{ $t('Пользователь') }}
                                </button>
                                <!-- Компонент для відображення графіка -->
                                <ChartEventsDisplay
                                    v-if="chartType === chartTypeEvent"
                                    :events="filteredEvents"
                                    :isAccordionOpen="isAccordionOpen"
                                    :selectedEvents="selectedEvents"/>
                                <ChartDepartmentsDisplay
                                    v-if="chartType === chartTypeDepartment"
                                    :departmentsCharts="departmentsCharts"
                                    :isAccordionOpen="isAccordionOpen"/>
                                <ChartUsersDisplay
                                    v-if="chartType === chartTypeUser"
                                    :usersCharts="usersCharts"
                                    :isAccordionOpen="isAccordionOpen"/>
                                <div
                                    v-if="chartType === chartTypeEvent" id="accordion1" class="mb-3">
                                    <div class="card">
                                        <div id="headingChart" class="card-header d-flex justify-content-end">
                                            <button aria-controls="collapseChart1" aria-expanded="true"
                                                    class="btn btn-primary"
                                                    data-target="#collapseChart1" data-toggle="collapse">
                                                {{ $t('Выбор столбцов графика') }}
                                            </button>
                                        </div>

                                        <div
                                            id="collapseChart1"
                                            aria-labelledby="headingChart"
                                            class="collapse"
                                            data-parent="#accordion1">
                                            <div class="card-body">
                                                <!-- Компонент для вибору стовпців графіка -->
                                                <ChartColumnFilter
                                                    :events="events"
                                                    :selectedEvents.sync="selectedEvents"
                                                    @updateSelectedEvents="updateSelectedEvents"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="activities.length" class="row">
                    <div class="col-12 mb-3">
                        <vue-good-table
                            :columns="columns"
                            :isLoading.sync="isLoading"
                            :pagination-options="paginationOptions"
                            :rows="activities"
                            :totalRows="totalActivities"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'id'">
                                    {{ props.row.id }}
                                </div>
                                <div v-else-if="props.column.field === 'user'">
                                    {{ props.row.user?.name }}
                                </div>
                                <div v-else-if="props.column.field === 'route'">
                                    {{ props.row.route }}
                                </div>
                                <div v-else-if="props.column.field === 'name'">
                                    {{ props.row.name }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import UserActivitiesService from "@/services/UserActivitiesService";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import FullHeightBlock from "@/components/FullHeightBlock.vue";
import ChartEventsDisplay from "@/components/ChartEventsDisplay.vue";
import ChartDepartmentsDisplay from "@/components/ChartDepartmentsDisplay.vue";
import ChartUsersDisplay from "@/components/ChartUsersDisplay.vue";
import ChartColumnFilter from "@/components/ChartColumnFilter.vue";
import FiltersComponent from "@/components/FiltersComponent.vue";

const UserActivities = new UserActivitiesService();

const chartTypeEvent = "event";
const chartTypeDepartment = "department";
const chartTypeUser = "user";

export default {
    name: 'Activities',
    components: {
        FullHeightBlock,
        Navbar,
        VueGoodTable,
        ChartEventsDisplay,
        ChartDepartmentsDisplay,
        ChartUsersDisplay,
        ChartColumnFilter,
        FiltersComponent
    },
    data() {
        return {
            isLoading: false,
            isAccordionOpen: false,
            chartTypeEvent: chartTypeEvent,
            chartTypeDepartment: chartTypeDepartment,
            chartTypeUser: chartTypeUser,
            events: [],
            departmentsCharts: [],
            usersCharts: [],
            selectedEvents: [],
            users: [],
            departments: [],
            activities: [],
            totalActivities: 0,
            chartType: chartTypeEvent,
            limit: 20,
            offset: 0,
            filters: {
                search_query: null,
                user_id: null,
                department_id: null,
                start_date: null,
                end_date: null,
            },
            paginationOptions: {
                enabled: true,
                perPageDropdown: [20, 50, 100],
                dropdownAllowAll: false,
                nextLabel: this.$t('туда'),
                prevLabel: this.$t('сюда'),
                ofLabel: this.$t('из'),
                rowsPerPageLabel: this.$t('Записей на страницу'),
            }
        }
    },
    methods: {
        init() {
            this.$preloader.show();
            this.fetchUsers();
            this.fetchDepartments();
        },
        async fetchUsers() {
            const {code, body} = await UserActivities.fetchUsers({
                department_id: this.filters.department_id
            });

            if (code === 200) {
                this.users = body;
            } else {

            }
        },
        async fetchDepartments() {
            const {code, body} = await UserActivities.fetchDepartments();

            if (code === 200) {
                this.departments = body;
            } else {

            }
        },
        async fetchUserActivities() {
            this.isLoading = true;
            const {code, body} = await UserActivities.fetch({
                ...this.filters,
                limit: this.limit,
                offset: this.offset,
            });
            this.isLoading = false;

            if (code === 200) {
                this.activities = body.content;
                this.totalActivities = body.total;
            } else {

            }
        },
        async fetchUserEvents() {
            this.isLoading = true;
            const {code, body} = await UserActivities.fetchUserEvents({
                ...this.filters
            });
            this.isLoading = false;

            if (code === 200) {
                this.events = body;
                this.selectedEvents = this.events.map(event => event.name);
            }
        },
        async fetchDepartmentsCharts() {
            this.isLoading = true;
            const {code, body} = await UserActivities.fetchDepartmentsCharts({
                ...this.filters
            });
            this.isLoading = false;

            if (code === 200) {
                this.departmentsCharts = body;
                this.selectedEvents = this.events.map(event => event.name);
            }
        },
        async fetchUsersCharts() {
            this.isLoading = true;
            const {code, body} = await UserActivities.fetchUsersCharts({
                ...this.filters
            });
            this.isLoading = false;

            if (code === 200) {
                this.usersCharts = body;
                this.selectedEvents = this.events.map(event => event.name);
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.fetchUserActivities();
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.fetchUserActivities();
        },
        toggleChartDisplay() {
            setTimeout(() => {
                this.isAccordionOpen = !this.isAccordionOpen;
            }, 300);
        },
        applyFilters() {
            this.$preloader.show();
            Promise.all([
                this.fetchUserActivities(),
                this.fetchUserEvents(),
                this.fetchDepartmentsCharts(),
                this.fetchUsersCharts(),
                this.fetchUsers(),
            ]).finally(() => {
                this.$preloader.hide();
            });
        },
        updateSelectedEvents(value) {
            this.selectedEvents = [...value];
        },
    },
    computed: {
        filteredEvents() {
            return this.events.filter(event => this.selectedEvents.includes(event.name));
        },
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('Название'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$tc('Роут'),
                    field: 'route',
                    sortable: false,
                },
                {
                    label: this.$tc('Пользователь'),
                    field: 'user',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'date_time',
                    sortable: false,
                }
            ];
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.applyFilters();
            }
        }
    },
    mounted() {
        document.title = this.$tc("Активность");
        this.init();
    }
}
</script>
