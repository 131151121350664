<template>
    <transition :enter-active-class="classIn"
                :leave-active-class="classOut">
        <div v-if="visible" class="preloader">
            <img src="../assets/preloader.gif">
        </div>
    </transition>
</template>

<script>

import {EventBus, Events} from "@/EventBus";

export default {
    name: "Preloader",
    data() {
        return {
            visible: false,
            animation: true
        }
    },
    methods: {
        onShowPreloader(animation) {
            this.animation = animation;
            this.visible = true;
            document.body.style.overflow = 'hidden';
        },
        onHidePreloader(animation) {
            this.animation = animation;
            this.visible = false;
            document.body.style.overflow = 'auto';
        },
    },
    computed: {
        classIn() {
            return this.animation ? 'animate__animated animate__fadeIn' : '';
        },
        classOut() {
            return this.animation ? 'animate__animated animate__fadeOut' : '';
        }
    },
    mounted() {
        EventBus.$on(Events.SHOW_PRELOADER, this.onShowPreloader);
        EventBus.$on(Events.HIDE_PRELOADER, this.onHidePreloader);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_PRELOADER, this.onShowPreloader);
        EventBus.$off(Events.HIDE_PRELOADER, this.onHidePreloader);
    }
}
</script>

<style lang="less" scoped>

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: fade(#35363A, 100);

    img {
        @media (max-width: 768px) {
            width: 64px;
            height: 64px;
        }
    }
}

</style>
