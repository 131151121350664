<template>
    <div v-show="localities.length > 0">
        <Navbar>
            <div class="row w-75">
                <div class="col-9">
                    <div class="input-group w-100">
                        <input v-model="search" type="text" class="form-control" placeholder="Начните поиск">
                        <div v-if="search.length" @click="search = ''" class="input-group-append">
                            <button class="btn btn-primary" type="button">
                                <i class="fas fa-times px-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="btn-group">
                        <button @click="pickerVariant = 1" :class="{ active : pickerVariant === 1 }" class="btn btn-primary">3 {{ $t('ряда')}}</button>
                        <button @click="pickerVariant = 2" :class="{ active : pickerVariant === 2 }" class="btn btn-primary">4 {{ $t('ряда')}}</button>
                    </div>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid pt-4">
            <div ref="container" class="row" style="height: auto; overflow-x: auto">
                <div v-show="!search.length" class="col-12 h-100">
                    <LocalitiesPicker2 v-show="pickerVariant === 1" :localities="localities"></LocalitiesPicker2>
                    <LocalitiesPicker v-show="pickerVariant === 2" :localities="localities"></LocalitiesPicker>
                </div>

                <div v-show="search.length > 0" class="col-12 h-100">
                    <LocalitiesSerp :localities="localities" :search="search"></LocalitiesSerp>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import LocalitiesPicker from "@/components/LocalitiesPicker";
import LocalitiesPicker2 from "@/components/LocalitiesPicker2";
import LocalitiesSerp from "@/components/LocalitiesSerp";
import StorageAdapter from "@/classess/StorageAdapter";

const storage = new StorageAdapter();

export default {
    name: 'Home',
    components: {
        Navbar,
        LocalitiesPicker,
        LocalitiesPicker2,
        LocalitiesSerp,
    },
    data() {
        return {
            search: "",
            pickerVariant: storage.get("pickerVariant", 1)
        }
    },
    computed: {
        localities() {
            return this.$store.state.localities || [];
        }
    },
    watch: {
        pickerVariant(val) {
            storage.set("pickerVariant", val);
        },
    },
    mounted() {
        document.title = this.$tc("Населенные пункты");
    }
}
</script>
