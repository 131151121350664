import AbstractService from "@/services/AbstractService";

export default class OrderNotesService extends AbstractService {

    constructor() {
        super();
    }

    get(locality_id, group_by_groups = false) {
        return this.axios.get(`/order_notes/${locality_id}`, {
            params: {group_by: group_by_groups ? "groups" : null}
        });
    }

    set(locality_id, group_id, type, notes = []) {
        return this.axios.post(`/order_notes`, {locality_id, group_id, notes, type});
    }

    delete(locality_id) {
        return this.axios.delete(`/order_notes/${locality_id}`);
    }
}
