import AbstractService from "@/services/AbstractService";

export default class UserRoleService extends AbstractService {

    constructor() {
        super();
    }

    fetch(do_not_track = null) {
        return this.axios.get(`/user_role`, {
            params : {do_not_track}
        });
    }

    get(id) {
        return this.axios.get(`/user_role/${id}`);
    }

    create({slug, name, comment}) {
        return this.axios.post(`/user_role`, {slug, name, comment});
    }

    update({id, name, comment}) {
        return this.axios.put(`/user_role/${id}`, {name, comment});
    }

    delete(id) {
        return this.axios.delete(`/user_role/${id}`);
    }
}
