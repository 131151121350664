import AbstractService from "@/services/AbstractService";

export default class TariffSnapshotService extends AbstractService {

    constructor() {
        super();
    }

    fetch({limit = 10, offset = 0, note_id = null}) {
        return this.axios.get('/tariff_snapshot', {
            params: {limit, offset, note_id}
        });
    }

    get(id) {
        return this.axios.get(`/tariff_snapshot/${id}`);
    }

    create({note_id, tariff_snapshot, tariff_id}) {
        return this.axios.post('/tariff_snapshot', {note_id, tariff_snapshot, tariff_id});
    }

    update({id, tariff_snapshot}) {
        return this.axios.put(`/tariff_snapshot/${id}`, {tariff_snapshot});
    }

    delete(id) {
        return this.axios.delete(`/tariff_snapshot/${id}`);
    }

}
