import AbstractService from "@/services/AbstractService";

export default class OptionService extends AbstractService {

    constructor() {
        super();
    }

    fetch() {
        return this.axios.get('/option');
    }

    set({option_key, option_value, expired = null}) {
        return this.axios.post('/option', {option_key, option_value, expired});
    }

    delete(id) {
        return this.axios.delete(`/option/${id}`);
    }

}
