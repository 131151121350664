<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Отправить СМС сообщение') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Сообщение') }} <span class="text-danger">*</span></label>
                        <div class="position-relative">
                            <textarea v-model="message" class="form-control" maxlength="160" rows="3"></textarea>
                            <SmsSamplePopover :sms-samples="sms_samples"
                                              @click="message = $event"/>
                            <small class="text-muted">{{ $t('Символов') }}: {{ message.length }}</small>
                            <small v-if="message.length >= 70" class="text-muted">
                                ({{ Math.ceil(message.length / 70) }} {{ $t('смс') }})
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold w-100">
                            {{ $t('Получатели') }}
                            <span v-if="phones.length">({{ phones.length }})</span>
                            <span class="text-danger">*</span>
                        </label>
                        <div @click="$refs.parsePhonesInput.focus()" class="pseudo-textarea">
                            <div v-for="(item, index) in phones" :key="index" class="pseudo-textarea__tag">
                                <div @click="removePhone(item)" class="pseudo-textarea__tag-remove">&#215;</div>
                                {{ item }}
                            </div>
                            <input :style="inputStyle" ref="parsePhonesInput" @input="parsePhones" type="text">
                        </div>
                        <small class="text-muted">
                            {{ $t('Разделитель: новая строка, пробел, запятая. Формат номера: 0991234567') }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold d-block">{{ $t('Отправитель (Альфа имя)') }} <span
                            class="text-danger">*</span></label>
                        <select class="form-control" @change="sender = $event.target.value">
                            <option :value="0">{{ $t('Не выбрано') }}</option>
                            <option v-for="item in configuration.senders"
                                    :selected="item === sender" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="send" :disabled="processing || !isValidForm" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Отправить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import SmsMessageService from "@/services/SmsMessageService";
import SmsSamplePopover from "@/components/SmsSamplePopover";

const SmsMessage = new SmsMessageService();

export default {
    name: "SendSmsMessageModal",
    components: {
        SmsSamplePopover
    },
    data() {
        return {
            visible: false,
            processing: false,
            sender: "",
            message: "",
            configuration: {},
            sms_samples: [],
            phones: []
        }
    },
    methods: {
        parsePhones(e) {
            e.target.value.split(/[,\n ]+/).forEach(item => {
                const phone = item.replace(/\D+/g, "");

                if (this.isValidPhone(phone) && this.phones.indexOf(phone) === -1) {
                    this.phones.push(phone);
                    e.target.value = "";
                }
            });

            this.$refs.parsePhonesInput.focus();
        },
        removePhone(phone) {
            this.phones = this.phones.filter(item => item !== phone);
        },
        async send() {
            this.processing = true;
            const {code, body} = await SmsMessage.send({
                message: this.message,
                sender: this.sender,
                phones: this.phones.map(item => '38' + item),
                debug: 0,
            });

            if (code === 201) {
                this.$toast.success(this.$t('СМС отправлено!'));
                this.$emit("messageSent");
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }

            this.processing = false;
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        isValidPhone(phone) {
            return /^([0-9]{10})$/.test(phone);
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal({configuration, sms_samples}) {
            this.visible = true;
            this.configuration = configuration;
            this.sms_samples = sms_samples;
        },
    },
    computed: {
        isValidForm() {
            return this.message.length >= 3
                && this.message.length <= 160
                && this.sender.length
                && this.phones.length;
        },
        inputStyle() {
            return this.phones.length ? {} : {width: '100%'};
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.SHOW_SEND_SMS_MESSAGE_MODAL, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_SEND_SMS_MESSAGE_MODAL, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style lang="less" scoped>

.color-radio {

    .color-radio-bg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;

        i {
            margin-top: 12px;
            color: #fff;
            visibility: hidden;
        }
    }

    input {
        display: none;
    }

    input:checked + .color-radio-bg i {
        visibility: visible;
    }
}

.pseudo-textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    max-height: 150px;
    min-height: 50px;

    &__tag {
        display: inline-block;
        height: 27px;
        border-radius: 3px;
        color: #fff;
        background: #007BFF;
        padding: 5px 25px 5px 5px;
        margin: 0 0 5px 5px;
        position: relative;
        font-size: 12px;
    }

    &__tag-remove {
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 100%;
        color: #000;
        position: absolute;
        right: 5px;
        top: 6px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        line-height: 14px;
        font-size: 14px;
    }

    input {
        border: none;
        outline: none;
        box-shadow: none;
        width: 100px;
        padding-left: 5px;
        font-size: 14px;
    }
}


</style>
