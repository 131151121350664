<script>
export default {
    methods: {
        isDepartment: function (slug) {
            if (typeof slug === "string") {
                slug = [slug];
            }

            return slug.indexOf(this.$store.state?.user?.department?.slug) > -1;
        },
        isDepartmentId: function (id) {
            if (typeof id === "string") {
                id = [id];
            }

            return id.indexOf(this.$store.state?.user?.department_id) > -1;
        },
        isNot: function (slug) {
            return !this.is(slug);
        },
    },
    computed:{
        userDepartmentSlug() {
            return this.$store.state?.user?.department?.slug;
        },
        userDepartmentName() {
            return this.$store.state?.user?.department?.name;
        },
        userDepartmentId() {
            return this.$store.state?.user?.department_id;
        }
    }
}
</script>
