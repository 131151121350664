<template>
    <div>
        <navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Настройки') }}</h4>
                </div>
            </div>
        </navbar>
        <div class="container-fluid pt-4">
            <div class="row mb-4">
                <div class="col-12 col-md-6">
                    <label class="font-weight-bold">{{ $t('Язык системы') }}</label>
                    <select class="form-control" v-model="selected" @change="toggleLanguage(selected)">
                        <option v-for="option in languages" :key="option.id" :value="option">
                            {{ convertLangCode(option) }}
                        </option>
                    </select>
                </div>
            </div>
            <SettingsAllowUsersRegister v-if="is('administrator')"/>
        </div>
    </div>
</template>

<script>
import ConfigurationService from "@/services/ConfigurationService";
import Navbar from '@/components/Navbar.vue';
import {mutationTypes} from "@/store";
import SettingsAllowUsersRegister from "@/components/SettingsAllowUsersRegister.vue";

const Configuration = new ConfigurationService();

export default {
    components: {
        Navbar,
        SettingsAllowUsersRegister
    },
    data() {
        return {
            languages: [],
            selected: 'uk',
        }
    },
    methods: {
        async getConfig() {
            this.$preloader.show();
            const {code, body} = await Configuration.get();

            if (code === 200) {
                this.languages = body.available_locales
                this.convertLangCode();
            }
            this.$preloader.hide();
        },
        toggleLanguage(e) {
            this.$store.commit(mutationTypes.SET_APP_LANGUAGE, e);
            location.reload();
        },
        convertLangCode(e) {
            const languages = {
                uk: 'Українська',
                ru: 'Русский',
                en: 'English',
                bg: 'Български'
            };
            return languages[e] ?? e;
        }
    },
    async mounted() {
        await this.getConfig();
        this.selected = this.$store.state.appLanguage;
    }
}
</script>
