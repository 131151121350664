<template>
    <div class="wrapper">
        <Navbar>
            <div class="row w-80">
                <div class="col-1">
                    <button @click="$router.back()" class="btn btn-primary">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                </div>
                <LocalityNavbarFullInfo :locality="locality"
                                        :trademark="trademark"/>
            </div>
        </Navbar>
        <div v-if="tariffs.length" class="container-fluid pt-4">
            <LocalityTariffsTabs :tariffsGroups="tariffsGroups"
                                 :currentGroup="currentGroup"
                                 :trademark_id="trademark_id"
                                 @change="currentGroup = $event">
                <div class="d-none d-md-flex justify-content-between flex-column flex-md-row">
                    <button v-if="tariffWithNotes.length"
                            @click.stop="visibleTariffNotesSidebar = !visibleTariffNotesSidebar"
                            class="btn btn-sm btn-primary">
                        <i class="fas fa-fw fa-clipboard"></i>
                        <span class="d-md-none d-xl-inline">{{ $t('Все заметки тарифов') }}</span>
                    </button>
                    <a :href="`${locality.guid}#${currentGroupID}`" target="_blank" class="btn btn-sm btn-primary ml-2">
                        <i class="fas fa-external-link-alt"></i>
                        <span class="d-md-none d-xl-inline"> {{ $t('НП на сайте') }}</span>
                    </a>
<!--                    <div v-if="tariffsList.length !== tariffs.length || showMode === 'all'" class="btn-group ml-2">-->
<!--                        <div class="btn-group ml-2">-->
<!--                            <button @click="showMode = 'active'"-->
<!--                                    :class="{ active : showMode === 'active' }"-->
<!--                                    class="btn btn-sm btn-outline-primary">-->
<!--                                Активные <span class="d-none d-xl-inline">тарифы</span>-->
<!--                            </button>-->
<!--                            <button @click="showMode = 'all'"-->
<!--                                    :class="{ active : showMode === 'all' }"-->
<!--                                    class="btn btn-sm btn-outline-primary">-->
<!--                                Все <span class="d-none d-xl-inline">тарифы</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </LocalityTariffsTabs>
            <div class="d-md-none mb-2">
                <button v-if="tariffWithNotes.length"
                        @click.stop="visibleTariffNotesSidebar = !visibleTariffNotesSidebar"
                        class="btn btn-sm btn-block btn-primary">
                    <i class="fas fa-fw fa-clipboard"></i>
                    <span class="d-md-none">{{ $t('Все заметки тарифов') }}</span>
                </button>
                <a :href="`${locality.guid}#${currentGroupID}`" target="_blank"
                   class="btn btn-sm btn-block btn-primary">
                    <i class="fas fa-external-link-alt"></i>
                    <span class="d-md-none">{{ $t('НП на сайте') }}</span>
                </a>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row" ref="tariffs_container">
                        <div class="col-12 col-lg-8">
                            <FullHeightBlock class="custom-scroll pr-2">

                                <template v-if="currentGroupID === 60">
                                    <TariffsAccordion :currentGroupTariffs="currentGroupTariffs"
                                                      @createNote="openCreateTariffNote"/>
                                </template>

                                <template v-else>
                                    <TariffCard v-for="(tariff, index) in currentGroupTariffs"
                                                :tariff="tariff"
                                                :locality_id="locality_id"
                                                :key="tariff.id"
                                                @createNote="openCreateTariffNote">
                                    </TariffCard>
                                </template>


                            </FullHeightBlock>
                        </div>
                        <div class="col-12 col-lg-4 border-left">
                            <LocalityServices :currentGroupTariffs="currentGroupTariffs"
                                              :locality_id="locality_id"
                                              :locality="locality"
                                              :trademark_id="trademark_id"
                                              :currentGroupID="currentGroupID"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FullHeightBlock v-else style="display: flex; align-items: center; justify-content: center;">
            <div class="display-4 text-center text-black-50">
                {{ $t('Данного населённого пункта ещё не существует, или он был удалён') }} :(
            </div>
        </FullHeightBlock>
        <CreateTariffNoteModal @created="loadTariffs"/>
        <UpdateTariffNoteModal @updated="loadTariffs"/>
        <LocalityTariffsNotesSidebar :visible="visibleTariffNotesSidebar"
                                     :tariffs="tariffs"
                                     @close="visibleTariffNotesSidebar = false"/>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import TariffService from "@/services/TariffService";
import _ from "underscore";
import Helper from "@/Helper";
import TariffCard from "@/components/TariffCard";
import {EventBus, Events} from "@/EventBus";
import CreateTariffNoteModal from "@/modals/CreateTariffNoteModal";
import LocalityTariffsNotesSidebar from "@/components/LocalityTariffsNotesSidebar";
import FullHeightBlock from "@/components/FullHeightBlock";
import UpdateTariffNoteModal from "@/modals/UpdateTariffNoteModal";
import LocalityNavbarFullInfo from "@/components/LocalityNavbarFullInfo";
import LocalityTariffsTabs from "@/components/LocalityTariffsTabs";
import LocalityServices from "@/components/LocalityServices";
import TariffsAccordion from "@/components/TariffsAccordion.vue";

const Tariff = new TariffService();

export default {
    name: "Locality",
    props: ["locality_id"],
    components: {
        TariffsAccordion,
        Navbar,
        TariffCard,
        CreateTariffNoteModal,
        LocalityTariffsNotesSidebar,
        FullHeightBlock,
        UpdateTariffNoteModal,
        LocalityNavbarFullInfo,
        LocalityTariffsTabs,
        LocalityServices,
    },
    data() {
        return {
            visibleTariffNotesSidebar: false,
            tariffs: [],
            showMode: "active",
            currentGroup: null,
        }
    },
    methods: {
        async loadTariffs() {
            const {code, body} = await Tariff.fetch(this.locality_id, this.trademark_id);

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.tariffs = body;
            }
        },
        openCreateTariffNote(tariff) {
            EventBus.$emit(Events.CREATE_TARIFF_NOTE_MODAL_OPEN, {
                ...tariff,
                trademark_id: this.trademark_id
            });
        },
        getParentGroupIdByGroupName(groupName) {
            const result = this.tariffsList?.find(item => {
                return item.parent_group_name.trim().toLowerCase() === groupName.trim().toLowerCase();
            })?.parent_group_id;

            return result ? result * 1 : 0;
        }
    },
    computed: {
        trademark() {
            return _.find(this.$store.state.trademarks, item => {
                return item.id === this.locality.trademark_id;
            }) || {};
        },
        locality() {
            return _.find(this.$store.state.localities, item => {
                return item.id == this.locality_id;
            }) || {};
        },
        trademark_id() {
            return this.locality?.trademark_id || null;
        },
        tariffsList() {
            return _.filter(this.tariffs, item => {
                if (this.showMode === "active") {
                    return !(item?.hidden || item?.deleted || item?.duplicate)
                        && Helper.isActiveDate(item?.date_start, item?.date_stop);
                } else {
                    return !item?.duplicate
                        && Helper.isActiveDate(item?.date_start, item?.date_stop);
                }
            });
        },
        currentGroupTariffs() {
            return _.chain(this.tariffsList)
                .filter(item => {
                    return this.currentGroup === item.parent_group_name;
                })
                .sort((a, b) => {
                    if (a.speed < b.speed) return -1;
                    if (a.speed > b.speed) return 1;

                    if (a.iptv_count_channels < b.iptv_count_channels) return -1;
                    if (a.iptv_count_channels > b.iptv_count_channels) return 1;
                    return 0;
                })
                .reverse()
                .value();
        },
        tariffsGroups() {
            const comparisonList = [
                1, //"многоэтажный сектор",
                2, //"частный сектор",
                4, //"ктв",
                3, //"радио"
                60, //архивные
            ];

            return _.unique(_.pluck(this.tariffsList, "parent_group_name")).sort((a, b) => {
                a = this.getParentGroupIdByGroupName(a);
                b = this.getParentGroupIdByGroupName(b);
                return comparisonList.indexOf(b) > comparisonList.indexOf(a) ? -1 : 1;
            });
        },
        currentGroupID() {
            return _.find(this.tariffsList, item => {
                return this.currentGroup === item.parent_group_name;
            })?.parent_group_id;
        },
        tariffWithNotes() {
            return this.tariffs.filter(item => item.notes.length)
        },

    },
    watch: {
        async trademark_id() {
            this.$preloader.show();
            await this.loadTariffs();
            this.$preloader.hide();
        },
        // при загрузке делаем активной первую группу
        tariffsGroups(val) {
            if (!this.currentGroup) {
                this.currentGroup = val[0];
            }
        },
        locality(val) {
            document.title = val.locality_name;
        }
    },
    async mounted() {
        EventBus.$on(Events.TARIFF_NOTE_DELETED, this.loadTariffs);

        if (this.trademark_id) {
            this.$preloader.show();
            await this.loadTariffs();
            this.$preloader.hide();
            document.title = `${this.trademark.name} > ${this.locality.locality_name} > ${this.locality.area_name} ${this.$tc('р-н')}`;
        }
    },
    beforeDestroy() {
        EventBus.$off(Events.TARIFF_NOTE_DELETED, this.loadTariffs);
    }
}
</script>

<style lang="less">


</style>
