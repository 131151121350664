<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 1200px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Выбор НП') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12" style="max-height: 80vh; overflow-y: auto;">
                        <ModalLocalityPicker v-if="visible"
                                             :selectedLocalitiesProp="selectedLocalities"
                                             @change="selectedLocalities = $event"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="save" class="btn btn-block btn-primary">
                        {{ $t('Ок') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import Helper from "@/Helper";
import ModalLocalityPicker from "@/components/ModalLocalityPicker.vue";

export default {
    name: "LocalitiesPickerModal",
    components: {
        ModalLocalityPicker
    },
    data() {
        return {
            visible: false,
            selectedLocalities: []
        }
    },
    methods: {
        save() {
            EventBus.$emit(Events.LOCALITIES_PICKER_MODAL_SELECTED, this.selectedLocalities);
            this.visible = false;
        },
        nl2br: Helper.nl2br,
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal(localities) {
            this.visible = true;
            this.selectedLocalities = localities;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.SHOW_LOCALITIES_PICKER_MODAL, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_LOCALITIES_PICKER_MODAL, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style lang="less" scoped>

.product-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}


</style>
