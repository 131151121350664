import AbstractService from "@/services/AbstractService";

export default class SmsMessageService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/sms/${id}`);
    }

    fetch({limit = 10, offset = 0, start_date, end_date}) {
        return this.axios.get("/sms", {
            params: {limit, offset, start_date, end_date}
        });
    }

    send({message, sender, phones, debug = 0}) {
        return this.axios.post(`/sms`, {message, sender, phones, debug});
    }

    configuration() {
        return this.axios.get("/sms/configuration");
    }

    delete(id) {
        return this.axios.delete(`/sms/${id}`);
    }
}
