<template>
    <div v-if="locality && locality.trademark_id" class="col-11 text-center pt-1" style="font-size: 1rem; white-space: nowrap">
        <img class="position-relative"
             style="width: 25px; height: 25px; top: -2px; object-fit: contain;"
             :src="trademark.icon"/>
        <span class="pr-3 pl-2 font-weight-bold text-light pr-3">{{ trademark.name }},</span>
        <i class="fas fa-map-marker-alt text-primary pr-2"></i>
        <span class="font-weight-bold text-primary pr-3">{{ locality.locality_name }},</span>
        <span class="font-weight-bold text-light pr-3">{{ locality.area_name }} {{ $t('р-н') }}.,</span>
        <span class="font-weight-bold text-light pr-3">{{ locality.region_name }} {{ $t('обл') }}.,</span>
        <span class="font-weight-bold text-light pr-3">(ID: {{ locality.id }})</span>
    </div>
</template>

<script>
export default {
    name: "LocalityNavbarFullInfo",
    props: {
        locality: {
            type: Object,
            required: true
        },
        trademark: {
            type: Object,
            required: true
        },
    }
}
</script>

<style scoped>

</style>
