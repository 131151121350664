<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">
                        {{ $t('') }}Профиль
                    </h4>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid pt-4">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card p-4">
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">ID</label>
                            <input v-model="user.id" type="text" readonly class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">{{ $t("Имя") }}</label>
                            <input v-model="user.name" type="text" readonly class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">Email</label>
                            <input v-model="user.email" type="text" readonly class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">{{ $t("Роль") }}</label>
                            <input v-model="roleName" type="text" readonly class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">{{ $t("Департамент") }}</label>
                            <input v-model="departmentName" type="text" readonly class="form-control">
                        </div>
                        <UpdateUserModalPhones :phones="phones"
                                               :preloader="phones_preloader"
                                               @create="createPhone"
                                               @update="updatePhone"
                                               @remove="removePhone">
                        </UpdateUserModalPhones>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <ChangeUserPassword/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import UpdateUserModalPhones from "@/components/UpdateUserModalPhones";
import UserPhoneService from "@/services/UserPhoneService";
import {mapState} from "vuex";
import AuthService from "@/services/AuthService";
import {mutationTypes} from "@/store";
import ChangeUserPassword from "@/components/ChangeUserPassword.vue";

const UserPhone = new UserPhoneService();
const Auth = new AuthService();

export default {
    name: 'Profile',
    components: {
        Navbar,
        UpdateUserModalPhones,
        ChangeUserPassword
    },
    data() {
        return {
            phones_preloader: false,
        }
    },
    methods: {
        async createPhone(item) {
            this.phones_preloader = true;

            const {code, body} = await UserPhone.create({
                user_id: this.user.id,
                ...item
            });

            if (code === 201) {
                this.$toast.success("Телефон добавлен");
                await this.refreshMyData();
            } else {
                this.$toast.error(body.message);
            }

            this.phones_preloader = false;
        },
        async updatePhone(item) {
            this.phones_preloader = true;

            const {code, body} = await UserPhone.update(item);

            if (code === 200) {
                this.$toast.success("Телефон обновлен");
                await this.refreshMyData();
            } else {
                this.$toast.error(body.message);
            }

            this.phones_preloader = false;
        },
        async removePhone(item) {

            if (!confirm("Удалить?")) return;

            this.phones_preloader = true;

            const {code, body} = await UserPhone.delete(item.id);

            if (code === 200) {
                this.$toast.success("Телефон удален");
                await this.refreshMyData();
            } else {
                this.$toast.error(body.message);
            }

            this.phones_preloader = false;
        },

        async refreshMyData() {
            const {code, body} = await Auth.me();
            this.$store.commit(mutationTypes.SET_USER, body);
        }
    },
    computed: {
        ...mapState({
            phones: state => state.user?.phones,
            user: state => state.user,
            roleName: state => state.user?.role?.name,
            currentDepartment: state => state.currentDepartment,
        }),
        departmentName() {
            return this.currentDepartment?.name || this.$t('Не указан');
        }
    },
    async mounted() {
        document.title = "Профиль";
    }
}
</script>
