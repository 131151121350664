<template>
    <div class="card p-4">
        <div class="form-group">
            <label class="font-weight-bold text-black-50">{{ $t("Старый пароль") }}</label>
            <input v-model="old_password" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label class="font-weight-bold text-black-50">{{ $t("Новый пароль") }}</label>
            <input v-model="new_password" type="text" class="form-control">
        </div>
        <div class="form-group mb-4">
            <label class="font-weight-bold text-black-50">{{ $t("Новый пароль (повтор)") }}</label>
            <input v-model="new_password_check" type="text" class="form-control">
        </div>
        <div class="form-group">
            <button @click="change" :disabled="loading || !isValid" class="btn btn-block btn-primary">
                <div v-if="loading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">{{ $t('Загрузка...') }}</span>
                </div>
                <span v-else>{{ $t('Сменить пароль') }}</span>
            </button>
        </div>
    </div>
</template>

<script>

import UserService from "@/services/UserService";

const User = new UserService();

export default {
    name: "ChangeUserPassword",
    data() {
        return {
            old_password: "",
            new_password: "",
            new_password_check: "",
            loading: false
        }
    },
    methods: {
        async change() {
            this.loading = true;
            const {code, body} = await User.changePassword(this.old_password, this.new_password);
            this.loading = false;

            if (code === 200) {
                this.$toast.success(this.$tc("Пароль изменен"));
                Object.assign(this.$data, this.$options.data.apply(this));
            } else {
                this.$toast.error(body.message);
            }
        }
    },
    computed: {
        isValid() {
            return this.old_password.length
                && this.new_password.length
                && this.new_password === this.new_password_check;
        }
    },

}
</script>

<style scoped>

</style>
