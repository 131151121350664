import axios from "axios";
import store, { mutationTypes } from "@/store/index";
import config from "@/config";
import memoized from "nano-memoize";

const refreshToken = memoized(
    () => {
        return new AbstractService().refreshToken();
    },
    { maxAge: 10000 }
);

export default class AbstractService {
    constructor() {
        const instance = axios.create({
            baseURL: config.baseApiUrl,
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
            timeout: 30000,
            validateStatus: (status) => status >= 200,
        });

        instance.interceptors.request.use(
            (config) => {
                config.headers = {
                    ...config.headers,
                    "Accept-Language": store.state.appLanguage,
                    Authorization: `Bearer ${store.state.bearerToken}`,
                };

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            async (response) => {
                const config = response?.config;
                const status = response?.status;

                if (status === 401 && !config?.sent) {
                    config.sent = true;

                    const token = await refreshToken();

                    if (token) {
                        config.headers = {
                            ...config.headers,
                            Authorization: `Bearer ${token}`,
                        };
                    } else {
                        delete config.headers["Authorization"];
                    }

                    return axios(config);
                }

                return response;
            },
            // сюда падают 500 >
            async (error) => {
                console.log("---", error);
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use((response) => {
            return {
                code: response.status,
                body: response.data,
            };
        });

        this.axios = instance;
    }

    async refreshToken() {
        const { code, body } = await this.axios.post("auth/refresh", {
            validateStatus: false,
        });

        if (code === 200) {
            store.commit(mutationTypes.SET_BEARER_TOKEN, body.access_token);
        } else {
            store.commit(mutationTypes.SET_BEARER_TOKEN, null);
        }

        return store.state.bearerToken;
    }
}
