import AbstractService from "@/services/AbstractService";

export default class ProductService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/product/${id}`);
    }

    fetch(limit = 10, offset = 0, orderColumn = null, orderType = null) {
        return this.axios.get(`/product`, {
            params: {limit, offset, orderColumn, orderType}
        });
    }

    create(formData) {
        return this.axios.post(`/product`, formData, {
            headers: {"Content-Type": "multipart/form-data"}
        });
    }

    update(id, formData) {
        return this.axios.post(`/product/${id}`, formData, {
            headers: {"Content-Type": "multipart/form-data"}
        });
    }

    delete(id) {
        return this.axios.delete(`/product/${id}`);
    }
}
