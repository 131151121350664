<template>
    <div class="w-100 pt-4 mr-0">
        <FullHeightBlock :offsetBottom="15" class="custom-scroll">
            <div v-for="item in products" :key="item.id" class="product-item">
                <div>
                    <img :src="item.images_links[0] || 'https://via.placeholder.com/75x75?text=no%20image'">
                </div>
                <div>
                    <div class="product-item__name" @click="$router.push(`/product/${item.id}`)">
                        {{ stringLimit(item.name, 30) }}
                        <span class="product-item__price">
                            {{ item.price !== null ? item.price + ' ' + $t('грн') : '-' }}
                        </span>
                    </div>

                    <div v-if="item.description" class="product-item__comment">
                        {{ stringLimit(item.description, 40) }}
                        <i @click="showProduct(item)"
                           class="fas fa-eye float-right cursor-pointer pt-1"></i>
                    </div>
                </div>
            </div>
            <div v-if="!products.length" class="pt-5 text-center text-muted">
                {{ $t('Нет товаров для даного НП') }}
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import FullHeightBlock from "@/components/FullHeightBlock";
import ProductService from "@/services/ProductService";
import Helper from "@/Helper";
import {EventBus, Events} from "@/EventBus";

const Product = new ProductService();

export default {
    name: "LocalityProducts",
    components: {
        vueCustomScrollbar,
        FullHeightBlock
    },
    props: {
        trademark_id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            products: [],
        }
    },
    methods: {
        stringLimit: Helper.stringLimit,
        showProduct(product) {
            EventBus.$emit(Events.SHOW_PRODUCT_MODAL, product);
        }
    },
    async mounted() {
        const {code, body} = await Product.fetch(1000, 0, "priority", "desc");
        if (code === 200) {
            this.products = body.content.filter(item => {
                return item.trademark.indexOf(this.trademark_id * 1) > -1
                    && item.active;
            });
            this.$emit('init', this.products.length);
        }
    }
}
</script>

<style lang="less" scoped>

.product-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #ccc;
    padding: 10px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    min-height: 100px;

    & > div:last-child {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    img {
        width: 75px;
        height: 75px;
        object-fit: cover;
        margin-right: 10px;
        border: 1px solid #ccc;
    }

    &__name {
        color: #007BFF;
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__comment {
        border-radius: 5px;
        background: #fff4cd;
        color: #856404;
        border: 1px solid #ffeeba;
        font-size: 13px;
        padding: 10px;
    }

    &__price {
        background: #ff5100;
        color: #ffffff;
        font-weight: bold;
        padding: 3px 5px;
        border-radius: 10px;
        font-size: 11px;
        float: right;
    }

}


</style>
