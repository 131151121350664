<template>
    <a href="javascript:void(0)"
       v-show="notes.length"
       data-toggle="popover"
       data-placement="top"
       data-html="true"
       class="text-secondary history-popover"
       :title="title"
       :data-content="content">
        <i class="fas fa-history"></i>
    </a>
</template>

<script>

import {mapState} from "vuex";
import {uniqueId} from "lodash";
import Helper from "@/Helper";

export default {
    name: "NoteHistoryPopover",
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            uniqPopoverId: uniqueId("history-popover-")
        }
    },
    methods: {
        handleNoteClick(elementId) {
            const noteIndex = elementId.replace("_", "") * 1;
            this.$emit("click", this.notes[noteIndex].note);
        }
    },
    computed: {
        ...mapState({
            localityNotesHistory: state => state.localityNotesHistory,
            tariffNotesHistory: state => state.tariffNotesHistory,
        }),
        title() {
            return this.type === "locality"
                ? this.$t('История добавленных заметок НП')
                : this.$t('История добавленных заметок тарифов')
        },
        notes() {
            return this.type === "locality"
                ? this.localityNotesHistory
                : this.tariffNotesHistory;
        },
        content() {
            let notes = this.notes;

            let html = `<div class="history-popover__wrapper custom-scroll">`;
            html += `<div id="${this.uniqPopoverId}" class="history-popover__inner">`;

            for (let i in notes) {
                // data атрибут какого то хуя не устанавливается, сделал через id
                // language=HTML
                html += `
                    <div id="_${i}" class="alert p-2 mb-2 alert-${notes[i].color}">
                        <span class="d-block">${Helper.nl2br(notes[i].note)}</span>
                    </div>
                `;
            }

            html += `</div></div>`;

            return html;
        },
    },
    beforeDestroy() {
        let self = this;
        $(document).off("click", `#${this.uniqPopoverId} .alert`, function () {
            self.handleNoteClick($(this).attr("id"));
        });
    },
    mounted() {
        let self = this;
        $(document).on("click", `#${this.uniqPopoverId} .alert`, function () {
            self.handleNoteClick($(this).attr("id"));
        });
    }
}
</script>

<style lang="less">

.history-popover {
    position: absolute;
    bottom: .5rem;
    right: .5rem;

    &__wrapper {
        max-height: 500px;
        overflow-y: auto;
    }

    &__inner {
        .alert {
            cursor: pointer;
            margin-right: .5rem;

            &:hover {
                border-color: #0055ff !important;
            }
        }
    }
}


</style>
