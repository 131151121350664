<template>
    <div class="row">
        <div class="col-12">
            <DepartmentTabs/>
        </div>
        <div class="col-12">
            <div class="btn-group btn-block">
                <button @click="currentInfoTab = 'locality_notes'"
                        :class="{ active : currentInfoTab === 'locality_notes' }"
                        class="btn btn-outline-primary btn-sm">
                    <i class="far fa-clipboard d-inline d-xxl-block pt-1"></i>
                    <span class="d-none d-xxl-inline pl-1">{{ $t('Заметки НП') }}</span> - {{ countLocalityNotes }}
                </button>
                <button @click="currentInfoTab = 'products'"
                        :class="{ active : currentInfoTab === 'products' }"
                        class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-store d-inline d-xxl-block pt-1"></i>
                    <span class="d-none d-xxl-inline pl-1">{{ $t('Товары') }}</span> - {{ countProducts }}
                </button>
                <button @click="currentInfoTab = 'actions'"
                        :class="{ active : currentInfoTab === 'actions' }"
                        class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-gift d-inline d-xxl-block pt-1"></i>
                    <span class="d-none d-xxl-inline pl-1">{{ $t('Акции') }}</span> - {{ countActions }}
                </button>
                <button @click="currentInfoTab = 'opponents'"
                        :class="{ active : currentInfoTab === 'opponents' }"
                        class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-wheelchair d-inline d-xxl-block pt-1"></i>
                    <span class="d-none d-xxl-inline pl-1">{{ $t('Конкуренты') }}</span> - {{ countOpponents }}
                </button>
                <button @click="currentInfoTab = 'comments'"
                        :class="{ active : currentInfoTab === 'comments' }"
                        class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-comments d-inline d-xxl-block pt-1"></i>
                    <span class="d-none d-xxl-inline pl-1">{{ $t('Комментарии') }}</span> - {{ countComments }}
                </button>
                <button @click="currentInfoTab = 'scripts'"
                        :class="{ active : currentInfoTab === 'scripts' }"
                        class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-scroll d-inline d-xxl-block pt-1"></i>
                    <span class="d-none d-xxl-inline pl-1">{{ $t('Скрипты') }}</span> - {{ countScripts }}
                </button>
            </div>
        </div>
        <div class="col-12">
            <LocalityNotes v-show="currentInfoTab === 'locality_notes'"
                                 :trademark_id="trademark_id"
                                 :currentGroupID="currentGroupID"
                                 :locality="locality"
                                 @init="count => this.countLocalityNotes = count">
            </LocalityNotes>
            <ActionInfo v-show="currentInfoTab === 'actions'"
                        @init="count => this.countActions = count"
                        :tariffs="currentGroupTariffs">
            </ActionInfo>
            <Opponents v-show="currentInfoTab === 'opponents'"
                       :group_id="currentGroupID"
                       :locality_id="locality_id"
                       @init="count => this.countOpponents = count">
            </Opponents>
            <Comments v-show="currentInfoTab === 'comments'"
                      :group_id="currentGroupID"
                      :locality_id="locality_id"
                      @init="count => this.countComments = count">
            </Comments>
            <LocalityProducts v-show="currentInfoTab === 'products'"
                       :trademark_id="trademark_id"
                       @init="count => this.countProducts = count">
            </LocalityProducts>
            <LocalityScripts v-show="currentInfoTab === 'scripts'"
                             :locality_id="locality_id"
                              @init="count => this.countScripts = count">
            </LocalityScripts>
        </div>
    </div>
</template>

<script>
import ActionInfo from "@/components/ActionInfo";
import Opponents from "@/components/Opponents";
import Comments from "@/components/Comments";
import LocalityNotes from "@/components/LocalityNotes";
import LocalityProducts from "@/components/LocalityProducts";
import LocalityScripts from "@/components/LocalityScripts.vue";
import DepartmentTabs from "@/components/DepartmentTabs.vue";

export default {
    name: "LocalityServices",
    components: {
        DepartmentTabs,
        ActionInfo,
        Opponents,
        Comments,
        LocalityNotes,
        LocalityProducts,
        LocalityScripts
    },
    props: {
        currentGroupTariffs: Array,
        locality_id: [String, Number],
        trademark_id: [String, Number],
        currentGroupID: [String, Number],
        locality: Object,
    },
    data() {
        return {
            currentInfoTab: 'locality_notes',
            countActions: 0,
            countComments: 0,
            countOpponents: 0,
            countLocalityNotes: 0,
            countProducts: 0,
            countScripts: 0
        }
    },
}
</script>

<style lang="less" scoped>


</style>
