import AbstractService from "@/services/AbstractService";

export default class LocalitySnapshotService extends AbstractService {

    constructor() {
        super();
    }

    fetch({limit = 10, offset = 0, note_id = null}) {
        return this.axios.get('/locality_snapshot', {
            params: {limit, offset, note_id}
        });
    }

    get(id) {
        return this.axios.get(`/locality_snapshot/${id}`);
    }

    create({note_id, locality_snapshot, locality_id}) {
        return this.axios.post('/locality_snapshot', {note_id, locality_snapshot, locality_id});
    }

    update({id, locality_snapshot}) {
        return this.axios.put(`/locality_snapshot/${id}`, {locality_snapshot});
    }

    delete(id) {
        return this.axios.delete(`/locality_snapshot/${id}`);
    }

}
