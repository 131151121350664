var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',[_c('div',{staticClass:"row w-75"},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"text-light mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Заметки тарифов'))+" ")])]),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"input-group w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Поиск по заметкам тарифов"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}),(_vm.searchQuery.length)?_c('div',{staticClass:"input-group-append",on:{"click":function($event){_vm.searchQuery = ''}}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-times px-2"})])]):_vm._e()])])])]),_c('FullHeightBlock',{staticClass:"custom-scroll"},[_c('div',{staticClass:"container-fluid py-4"},[_c('DepartmentTabs'),(_vm.notes.length || _vm.is_init)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.notes,"mode":"remote","totalRows":_vm.total_notes,"isLoading":_vm.isLoading,"search-options":{
                        enabled: true,
                        externalQuery: _vm.searchQuery,
                    },"pagination-options":{
                        enabled: true,
                        perPageDropdown: [10, 20, 50, 100],
                        dropdownAllowAll: false,
                        nextLabel: _vm.$t('туда'),
                        prevLabel: _vm.$t('сюда'),
                        ofLabel: _vm.$t('из'),
                        rowsPerPageLabel: _vm.$t('Записей на страницу'),
                    }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'locality')?_c('div',[_c('a',{staticClass:"d-block font-weight-bold text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/locality/' + props.row.locality_id)}}},[_vm._v(_vm._s(_vm.localityData(props.row.locality_id).locality_name || '-'))]),_c('small',{staticClass:"d-block text-black-50"},[_vm._v(_vm._s(_vm.localityData(props.row.locality_id).area_name))]),_c('small',{staticClass:"d-block text-black-50 font-weight-bold"},[_vm._v(_vm._s(_vm.localityData(props.row.locality_id).region_name))])]):(props.column.field === 'note')?_c('div',[_c('TariffNote',{attrs:{"controls":_vm.is('administrator'),"tariff":props.row.tariff,"note":props.row}})],1):(props.column.field === 'actions')?_c('div',[_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.remove(props.row.id)}}},[_vm._v(_vm._s(_vm.$t('Удалить'))+" ")])]):(props.column.field === 'tariff')?_c('div',[_c('span',{staticClass:"text-primary font-weight-bold d-block"},[_vm._v(" "+_vm._s(props.row.tariff && props.row.tariff.name)+" ")]),_c('small',{staticClass:"text-secondary font-weight-bold d-block"},[_vm._v(" ID: "+_vm._s(props.row.tariff_id)+" ")]),_c('small',[_vm._v(" "+_vm._s(props.row.tariff && props.row.tariff.group_name)+" ")])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2477531514)})],1)]):_c('div',{staticClass:"centered"},[_c('div',{staticClass:"display-4 text-black-50 mb-4"},[_vm._v(_vm._s(_vm.$t('Тут пока ничего нет')))])])],1)]),_c('UpdateTariffNoteModal',{on:{"updated":_vm.loadTariffs}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }