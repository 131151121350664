<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Обновление заметки тарифа') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal" :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Тариф') }} <span class="text-danger">*</span></label>
                        <input v-model="tariff_name" readonly type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Тариф UNIQ ID') }} <span class="text-danger">*</span></label>
                        <input v-model="tariff_id" readonly type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Заголовок') }} <span class="text-danger">*</span></label>
                        <div class="position-relative">
                            <input v-model="title" type="text" class="form-control">
                            <TitleHistoryPopover type="tariff" @click="title = $event" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Текст') }} <span class="text-danger">*</span></label>
                        <div class="position-relative">
                            <textarea v-model="note" class="form-control" rows="3"></textarea>
                            <NoteHistoryPopover type="tariff" @click="note = $event"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold d-block">{{ $t('Цвет заметки') }}</label>
                        <div class="d-flex justify-content-between">
                            <label v-for="item in colors" :key="item" class="color-radio">
                                <input type="radio" v-model="selectedColor" :value="item">
                                <div class="color-radio-bg" :class="'bg-' + item">
                                    <i class="fas fa-check"></i>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold d-block">{{ $t('Тип заметки (влияет на цвет)') }}</label>
                        <select class="form-control" @change="selectedColor = $event.target.value === 0 ? 'primary' : $event.target.value">
                            <option :value="0">{{ $t('Не выбрано') }}</option>
                            <option :selected="selectedColor === 'warning'" value="warning">{{ $t('Исполнитель / координатор') }}</option>
                            <option :selected="selectedColor === 'primary'" value="primary">{{ $t('Реализация оборудования') }}</option>
                            <option :selected="selectedColor === 'danger'" value="danger">{{ $t('Исключения') }}</option>
                            <option :selected="selectedColor === 'success'" value="success">{{ $t('Переход') }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create" :disabled="processing || !isValidForm" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import TariffNoteService from "@/services/TariffNoteService";
import TariffSnapshotService from "@/services/TariffSnapshotService";
import NoteHistoryPopover from "@/components/NoteHistoryPopover";
import TitleHistoryPopover from "@/components/TitleHistoryPopover";
import {mutationTypes} from "@/store";

const TariffNote = new TariffNoteService();
const TariffSnapshot = new TariffSnapshotService();

export default {
    name: "UpdateTariffNoteModal",
    components: {
        VueTypeaheadBootstrap,
        NoteHistoryPopover,
        TitleHistoryPopover
    },
    data() {
        return {
            visible: false,
            processing: false,
            note_id: null,
            title: "",
            note: "",
            tariff_snapshot: null,
            tariff_name: null,
            trademark_id: null,
            tariff_id: null,
            locality_id: null,
            selectedColor: "primary",
            colors: [
                "primary",
                "secondary",
                "success",
                "danger",
                "warning",
                "info",
                "dark",
            ]
        }
    },
    methods: {
        async create() {
            this.processing = true;
            const {code, body} = await TariffNote.update({
                note: this.note,
                title: this.title,
                id: this.note_id,
                color: this.selectedColor
            });

            if (code === 200) {
                await this.updateTariffSnapshot(body);
                this.$store.commit(mutationTypes.SET_TARIFF_NOTES_HISTORY, body);
                this.$toast.success(this.$t('Заметка Обновлена'));
                this.$emit("updated");
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }

            this.processing = false;
        },
        async updateTariffSnapshot(note) {

            // получаем id слепка по note_id
            const {body} = await TariffSnapshot.fetch({
                note_id: note.id
            })

            if (body?.content?.length > 0) {

                // обновляем слепок
                const {code} = await TariffSnapshot.update({
                    id: body.content[0].id,
                    tariff_snapshot: this.tariff_snapshot
                });

                if (code !== 200) {
                    this.$toast.error(this.$t('Ошибка при обновлении слепка тарифа'));
                }
            } else {
                // если заметка была создана ранее, до появления функционала слепков
                const {code} = await TariffSnapshot.create({
                    note_id: note.id,
                    tariff_id: this.tariff_id,
                    tariff_snapshot: this.tariff_snapshot
                });

                if (code !== 201) {
                    this.$toast.error(this.$t('Ошибка при создании слепка тарифа'));
                }
            }
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal(data) {
            this.tariff_snapshot = JSON.stringify(data.tariff);
            this.note_id = data.note.id;
            this.trademark_id = data.note.trademark_id;
            this.tariff_id = data.note.tariff_id;
            this.locality_id = data.note.locality_id;
            this.tariff_name = data.tariff.name;
            this.note = data.note.note;
            this.title = data.note.title;
            this.selectedColor = data.note.color;
            this.visible = true;
        },
    },
    computed: {
        isValidForm() {
            return this.title.length && this.note.length
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.UPDATE_TARIFF_NOTE_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.UPDATE_TARIFF_NOTE_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style lang="less" scoped>

.color-radio {

    .color-radio-bg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;

        i {
            margin-top: 12px;
            color: #fff;
            visibility: hidden;
        }
    }

    input {
        display: none;
    }

    input:checked + .color-radio-bg i {
        visibility: visible;
    }
}

</style>
