<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Комментарии') }}</h4>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid pt-4">
            <div v-if="comments.length" class="row">
                <div class="col-12">
                    <vue-good-table
                        :columns="columns"
                        :rows="comments"
                        mode="remote"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        :totalRows="total_comments"
                        :isLoading.sync="isLoading"
                        :pagination-options="{
                            enabled: true,
                            perPageDropdown: [7, 10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field === 'name'">
                                <small class="d-block font-weight-bold text-primary">{{ props.row.user.name }}</small>
                                <small class="d-block text-black-50">{{ props.row.user.role.name }}</small>
                                <small class="d-block text-black-50 font-weight-bold">{{ props.row.user.email }}</small>
                            </div>
                            <div v-else-if="props.column.field === 'locality'">
                                <small class="d-block font-weight-bold text-primary">{{ localityData(props.row.locality_id).locality_name }}</small>
                                <small class="d-block text-black-50">{{ localityData(props.row.locality_id).area_name }}</small>
                                <small class="d-block text-black-50 font-weight-bold">{{ localityData(props.row.locality_id).region_name }}</small>
                            </div>
                            <div v-else-if="props.column.field === 'group'">
                                <small class="">{{ $store.state.groupsList[props.row.group_id] }}</small>
                            </div>
                            <div v-else-if="props.column.field === 'comment'">
                                <small v-if="prepComment(props.row)"
                                       :class="{ 'font-weight-bold' : $store.state.user.id !== props.row.user_id
                                       && (props.row.read_at === null || props.row.read_at.read_at === null) }">
                                    {{ prepComment(props.row) }}
                                </small>
                                <small v-else class="font-italic text-black-50">{{ $t('Комментарий удален')}} {{ props.row.deleted_at | formatDate }}</small>
                            </div>
                            <div v-else-if="props.column.field === 'date'">
                                <small class="">{{ props.row.created_at | formatDate }}</small>
                            </div>
                            <div v-else-if="props.column.field === 'action'">
                                <div class="btn-group btn-block">
                                    <button @click="$router.push({name: 'Locality', params: {locality_id : props.row.locality_id}})"
                                            class="btn btn-sm btn-primary">
                                        {{ $t('На страницу НП')}}
                                        <i class="fas fa-arrow-right fa-fw"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </div>
            <div v-else class="centered">
                <div class="display-4 text-black-50">{{ $t('Тут пока ничего нет')}}</div>
            </div>
        </div>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import _ from "underscore";
import MessageService from "@/services/MessageService";
import Helper from "@/Helper";
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru'

const Message = new MessageService();

export default {
    name: 'Comments',
    components: {
        Navbar,
        VueGoodTable,
    },
    filters: {
        formatDate(date) {
            const timeAgo = new TimeAgo('ru')
            return timeAgo.format(new Date(date));
        },
    },
    data() {
        return {
            comments: [],
            total_comments: 0,
            limit: 7,
            offset: 0,
            isLoading: false,
            columns: [
                {
                    label: this.$tc('Имя'),
                    field: 'name',
                    sortable: false,
                    tdClass: this.tdClassFunc,
                },
                {
                    label: this.$tc('НП'),
                    field: 'locality',
                    sortable: false,
                    tdClass: this.tdClassFunc,
                },
                {
                    label: this.$tc('Группа'),
                    field: 'group',
                    sortable: false,
                    tdClass: this.tdClassFunc,
                },
                {
                    label: this.$tc('Комментарий'),
                    field: 'comment',
                    width: '400px',
                    sortable: false,
                    tdClass: this.tdClassFunc,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'date',
                    sortable: false,
                    tdClass: this.tdClassFunc,
                },
                {
                    label: this.$tc('Действия'),
                    field: 'action',
                    width: '250px',
                    sortable: false,
                    tdClass: this.tdClassFunc,
                },
            ],
        }
    },
    methods: {
        tdClassFunc(row) {
            return row.user.id === this.$store.state.user.id ? 'my-comment' : '';
        },
        prepComment(comment) {
            return comment?.deleted_at === null
                ? Helper.stringLimit(comment.message, 100)
                : false;
        },
        localityData(locality_id) {
            return _.find(this.$store.state.localities, item => {
                return item.id == locality_id;
            }) || {};
        },
        async loadComments(limit, offset) {
            this.isLoading = true;
            const {code, body} = await Message.fetch(null, limit, offset);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.comments = body.content;
                this.total_comments = body.total
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadComments(this.limit, this.offset);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadComments(this.limit, this.offset);
        },
    },
    created() {
        TimeAgo.addLocale(ru);
    },
    async mounted() {
        document.title = this.$tc("Комментарии");
        this.$preloader.show();
        await this.loadComments(this.limit, this.offset);
        this.$preloader.hide();
    }
}
</script>

<style lang="less">
.my-comment {
    background: #e1f2ff;
}
</style>
