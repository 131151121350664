<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-5">
                    <h4 class="text-light mb-0">
                        {{ $t('Сообщения') }}
                        <button @click="showSendSmsMessageModal"
                                class="ml-3 btn btn-primary">
                            {{ $t('Отправить') }}
                        </button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <div class="d-flex align-items-center justify-content-between">
                    <ul class="app-nav-tabs nav nav-tabs mb-3">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">{{ $t('Сообщения') }}</a>
                        </li>
                        <li @click.prevent="$router.push({name: 'SmsSample'})" class="nav-item">
                            <a class="nav-link" href="#">{{ $t('Шаблоны сообщений') }}</a>
                        </li>
                    </ul>
                    <div class="d-flex align-items-center">
                        <date-range-picker
                            :class="{ 'date-picker-default-label' : !dateRange.startDate && !dateRange.endDate }"
                            :locale-data="localDate"
                            opens="left"
                            :auto-apply="true"
                            :ranges="false"
                            :maxDate="(new Date()).toISOString()"
                            :linked-calendars="false"
                            single-date-picker="range"
                            v-model="dateRange"
                        >
                        </date-range-picker>
                        <button class="btn btn-danger ml-3"
                                @click="resetDateRange()"
                                v-if="dateRange.startDate && dateRange.endDate"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div v-if="sms_messages.length || is_init" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="sms_messages"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="total"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'message'">
                                    <small class="alert alert-secondary d-block mb-0"
                                           data-toggle="tooltip"
                                           :title="props.row.message">
                                        {{ stringLimit(props.row.message, 50) }}
                                    </small>
                                </div>
                                <div v-else-if="props.column.field === 'phones'">
                                    <div v-if="Math.random()"
                                         class="text-success"
                                         data-toggle="tooltip"
                                         :title="props.row.phones.join('<br>')">
                                        {{ $t('получателей') }}: {{ props.row.phones.length }}

                                    </div>
                                </div>
                                <div v-else-if="props.column.field === 'user'"
                                     class="text-primary">
                                    {{ props.row.user ? props.row.user.name : '-' }}
                                </div>
                                <div v-else-if="props.column.field === 'result'"
                                     class="text-danger"
                                     data-toggle="tooltip"
                                     :title="getFailedPhones(props.row.result).join('<br>')">
                                    {{ $t('ошибок') }}: {{ getFailedPhones(props.row.result).length }}
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
        <SendSmsMessageModal @messageSent="onMessageSent"/>
        <SendNotifierMessageModal @messageSent="onMessageSent"/>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import FullHeightBlock from "@/components/FullHeightBlock";
import Helper from "@/Helper";
import SmsMessageService from "@/services/SmsMessageService";
import SendSmsMessageModal from "@/modals/SendSmsMessageModal";
import {EventBus, Events} from "@/EventBus";
import SmsSampleService from "@/services/SmsSampleService";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment-mini";
import NotifierService from "@/services/NotifierService";
import SendNotifierMessageModal from "@/modals/SendNotifierMessageModal.vue";

const SmsMessage = new SmsMessageService();
const SmsSamples = new SmsSampleService();
const Notifier = new NotifierService();

export default {
    name: 'SmsMessage',
    components: {
        Navbar,
        VueGoodTable,
        FullHeightBlock,
        SendSmsMessageModal,
        SendNotifierMessageModal,
        DateRangePicker
    },
    data() {
        return {
            is_init: false,
            sms_messages: [],
            sms_samples: [],
            total: 0,
            limit: 10,
            offset: 0,
            isLoading: false,
            configuration: {},
            notifierConfiguration: {},
            dateRange: {
                startDate: null,
                endDate: null
            },
            localDate: {
                daysOfWeek: [this.$tc('Вс'), this.$tc('Пн'), this.$tc('Вт'), this.$tc('Ср'), this.$tc('Чт'), this.$tc('Пт'), this.$tc('Сб')],
                monthNames: [this.$tc('Янв'), this.$tc('Фев'), this.$tc('МарТ'), this.$tc('Апр'), this.$tc('Май'), this.$tc('Июнь'), this.$tc('Июль'), this.$tc('Авг'), this.$tc('Сент'), this.$tc('Окт'), this.$tc('Ноя'), this.$tc('Дек')],
                firstDay: 1,
                format: 'yyyy.mm.dd',
            }
        }
    },
    methods: {
        resetDateRange() {
            this.dateRange.startDate = null;
            this.dateRange.endDate = null;
        },
        async onMessageSent() {
            this.offset = 0;
            await this.loadSmsMessages();
        },
        showSendSmsMessageModal() {
            // EventBus.$emit(Events.SHOW_SEND_SMS_MESSAGE_MODAL, {
            //     configuration: this.configuration,
            //     sms_samples: this.sms_samples,
            // });

            EventBus.$emit(Events.SHOW_SEND_NOTIFIER_MESSAGE_MODAL, {
                configuration: this.notifierConfiguration,
                sms_samples: this.sms_samples,
            });
        },
        getFailedPhones(data) {
            return Object.entries(data)
                .filter(item => item[1] !== true)
                .map(item => item[0]);
        },
        stringLimit: Helper.stringLimit,
        async remove(id) {
            this.isLoading = true;
            await SmsMessage.delete(id);
            await this.loadSmsMessages();
            this.isLoading = false;
        },
        async loadConfiguration() {
            this.isLoading = true;
            const {code, body} = await SmsMessage.configuration();
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.configuration = body;
            }
        },
        async loadNotifierConfiguration() {
            this.isLoading = true;
            const {code, body} = await Notifier.configuration();
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.notifierConfiguration = body;
            }
        },
        async loadSmsMessages(limit = 10, offset = 0) {
            this.isLoading = true;

            const start_date = this.dateRange.startDate ? moment(this.dateRange.startDate).unix() : null;
            const end_date = this.dateRange.endDate ? moment(this.dateRange.endDate).unix() : null;

            const {code, body} = await SmsMessage.fetch({
                limit,
                offset,
                start_date,
                end_date,
            });

            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.sms_messages = body.content;
                this.total = body.total;
            }
        },
        async loadSmsSamples() {
            const {code, body} = await SmsSamples.fetch(100, 0);

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.sms_samples = body.content;
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadSmsMessages(this.limit, this.offset);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadSmsMessages(this.limit, this.offset);
        },
    },
    computed: {
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('Сообщение'),
                    field: 'message',
                    sortable: false,
                },
                {
                    label: this.$tc('Отправитель'),
                    field: 'sender',
                    sortable: false,
                },
                {
                    label: this.$tc('Получатели'),
                    field: 'phones',
                    sortable: false,
                },
                // {
                //     label: this.$tc('Статус'),
                //     field: 'result',
                //     sortable: false,
                // },
                {
                    label: this.$tc('Кто отправил'),
                    field: 'user',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'updated_at',
                    sortable: false,
                    width: "250px",
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                }
            ];
        },
    },
    watch: {
        dateRange: {
            deep: true,
            handler() {
                this.$nextTick(this.loadSmsMessages);
            }
        }
    },
    async mounted() {
        document.title = this.$tc("СМС Сообщения");
        this.$preloader.show();
        await this.loadSmsSamples();
        await this.loadSmsMessages();
        await this.loadConfiguration();
        await this.loadNotifierConfiguration();
        this.$preloader.hide();
        this.is_init = true;
    }
}
</script>

<style lang="less">

.date-picker-default-label {

    & > div > span {
        display: none;
    }

    & b:before {
        content: "Выбрать дату";
    }
}

</style>
