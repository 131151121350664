<script>
export default {
    methods: {
        is: function (roles) {
            if (typeof roles === "string") {
                roles = [roles];
            }

            return roles.indexOf(this.$store.state?.user?.role?.slug) > -1;
        },
        isNot: function (roles) {
            return !this.is(roles);
        },
    },
    computed:{
        userRoleSlug() {
            return this.$store.state?.user?.role?.slug;
        },
        userRoleName() {
            return this.$store.state?.user?.role?.name;
        }
    }
}
</script>
