<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Отправить сообщение') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Сообщение') }} <span class="text-danger">*</span></label>
                        <div class="position-relative">
                            <textarea v-model="message" class="form-control" maxlength="160" rows="3"></textarea>
                            <SmsSamplePopover :sms-samples="sms_samples"
                                              @click="message = $event"/>
                            <small class="text-muted">{{ $t('Символов') }}: {{ message.length }}</small>
                            <small v-if="message.length >= 70" class="text-muted">
                                ({{ Math.ceil(message.length / 70) }} {{ $t('смс') }})
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold w-100">
                            {{ $t('Тип контактов') }}
                            <span class="text-danger">*</span>
                        </label>
                        <div class="btn-group btn-block" role="group">
                            <button v-for="type in configuration.contact_types"
                                    :class="{ 'active' : contacts_type === type }"
                                    @click="contacts_type = type"
                                    :key="type"
                                    type="button" class="btn btn-outline-primary">{{ getContactTypeName(type) }}
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold w-100">
                            {{ $t('Получатели') }}
                            <span v-if="contacts.length">({{ contacts.length }})</span>
                            <span class="text-danger">*</span>
                        </label>
                        <textarea v-model="raw_contacts" class="form-control" rows="5"></textarea>
                        <small class="text-muted">
                            {{ $t('Разделитель: новая строка, пробел, запятая.') }}
                            {{ $t('За один раз можно отправить сообщения только на один тип контактов.') }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold d-block">{{ $t('Отправитель') }} <span
                            class="text-danger">*</span></label>
                        <select v-model="label" class="form-control">
                            <option v-for="item in labels" :value="item">
                                {{ item || 'Выберите отправителя' }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="send" :disabled="processing || !isValidForm" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Отправить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import SmsSamplePopover from "@/components/SmsSamplePopover";
import NotifierService from "@/services/NotifierService";
import {cloneDeep, every} from "lodash";

const Notifier = new NotifierService();

export default {
    name: "SendNotifierMessageModal",
    components: {
        SmsSamplePopover
    },
    data() {
        return {
            visible: false,
            processing: false,
            label: null,
            message: "",
            contacts: [],
            contacts_type: null,
            configuration: {},
            sms_samples: [],
            raw_contacts: ""
        }
    },
    methods: {
        getContactTypeName(type) {
            const map = {
                phone: this.$t("Телефон"),
                email: this.$t("еПочта"),
                account_id: this.$t("Лицевой счет"),
            }

            return map[type] || type;
        },
        validateContacts() {
            if (this.contacts_type === "phone") {
                return every(this.contacts, this.isValidPhone);
            }

            if (this.contacts_type === "email") {
                return every(this.contacts, this.isValidEmail);
            }

            if (this.contacts_type === "account_id") {
                return every(this.contacts, this.isValidAccountId);
            }

            return true;
        },
        async send() {
            if (!this.validateContacts()) {
                this.$toast.error(this.$tc('Тип контактов не соответствует указанным контактам.'));
                return;
            }

            this.processing = true;
            const {code, body} = await Notifier.send({
                message: this.message,
                label: this.label,
                contacts: this.contacts,
                contacts_type: this.contacts_type,
            });
            this.processing = false;

            if (code === 201) {
                this.$toast.success(this.$tc('Сообщение отправлено!'));
                this.$emit("messageSent");
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        isValidPhone(contact) {
            return /^([0-9]{10})$/.test(contact);
        },
        isValidEmail(contact) {
            return /^\S+@\S+\.\S+$/.test(contact);
        },
        isValidAccountId(contact) {
            return /^([0-9]{1,9})$/.test(contact);
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal({configuration, sms_samples}) {
            this.visible = true;
            this.configuration = configuration;
            this.sms_samples = sms_samples;
            this.contacts_type = configuration.contact_types[0];
        },
    },
    computed: {
        isValidForm() {
            return this.message.length
                && this.label?.length
                && this.contacts.length;
        },
        labels() {
            return [null, ...this.configuration?.labels || []];
        }
    },
    watch: {
        raw_contacts(val) {
            this.contacts = [];
            val.split(/[,\n ]+/).forEach(item => {
                this.contacts.push(item.trim());
            });
        },
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.SHOW_SEND_NOTIFIER_MESSAGE_MODAL, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_SEND_NOTIFIER_MESSAGE_MODAL, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style lang="less" scoped>

.color-radio {

    .color-radio-bg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;

        i {
            margin-top: 12px;
            color: #fff;
            visibility: hidden;
        }
    }

    input {
        display: none;
    }

    input:checked + .color-radio-bg i {
        visibility: visible;
    }
}

.pseudo-textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    max-height: 150px;
    min-height: 50px;

    &__tag {
        display: inline-block;
        height: 27px;
        border-radius: 3px;
        color: #fff;
        background: #007BFF;
        padding: 5px 25px 5px 5px;
        margin: 0 0 5px 5px;
        position: relative;
        font-size: 12px;
    }

    &__tag-remove {
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 100%;
        color: #000;
        position: absolute;
        right: 5px;
        top: 6px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        line-height: 14px;
        font-size: 14px;
    }

    input {
        border: none;
        outline: none;
        box-shadow: none;
        width: 100px;
        padding-left: 5px;
        font-size: 14px;
    }
}


</style>
