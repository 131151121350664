import AbstractService from "@/services/AbstractService";

export default class MessageService extends AbstractService {

    constructor() {
        super();
    }

    fetch(locality_id = null, limit = 1000, offset = 0) {
        return this.axios.get('/message', {
            params: {locality_id, limit, offset}
        });
    }

    create({locality_id, group_id, message}) {
        return this.axios.post('/message', {locality_id, group_id, message});
    }

    update({id, message}) {
        return this.axios.put(`/message/${id}`, {message});
    }

    delete(id) {
        return this.axios.delete(`/message/${id}`);
    }

    read(ids) {
        return this.axios.post(`/message/read`, {ids});
    }

    countUnreadMessages(do_not_track = null) {
        return this.axios.get(`/message/count_unread`, {
            params : {do_not_track}
        });
    }
}
