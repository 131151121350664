import uk from './uk.json'
import bg from './bg.json'
import en from './en.json'

export default {
    uk,
    bg,
    en,
    ru: Object.fromEntries(Object.entries(uk).map(item => [item[0], item[0]]))
}
