<template>
    <div class="row pt-4">
        <div v-if="connect.length" class="col-12">
            <div class="alert alert-danger mb-3">
                <span>{{ $t('В этой группе есть тарифы с акционной') }} <span class="font-weight-bold">{{ $t('стоимостью подключения') }}</span>.</span>
                <ul class="pt-3">
                    <li v-for="(item, index) in connect" :key="index">
                        "{{ item.name }}" - <strong>{{ parseInt(item.action.connect_price) }}{{ $t('грн') }} </strong>
                        <span v-html="endAction(item.action)"></span>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="connect_device.length" class="col-12">
            <div class="alert alert-danger mb-3">
                <span>{{ $t('В этой группе есть тарифы с акционным') }} <span class="font-weight-bold">{{ $t('переходом от другого провайдера') }}</span>.</span>
                <ul class="pt-3">
                    <li v-for="(item, index) in connect_device" :key="index">
                        "{{ item.name }}" - <strong>{{ parseInt(item.action_connect_device.value) }}{{ $t('грн') }} </strong>
                        <span v-html="endAction(item.action_connect_device)"></span>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="!connect_device.length && !connect.length" class="col-12">
            <div class="alert alert-primary mb-3">
                <span>{{ $t('В этой группе тарифов нет акционных предложений') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "underscore";
import Helper from "@/Helper";
import moment from "moment-mini";

export default {
    name: "ActionInfo",
    props: ["tariffs"],
    methods: {
        isActiveConnectPeriod(data) {
            if (!data || !data.connect_price) {
                return false;
            }

            return Helper.isActiveDate(data?.date_start, data?.date_stop);
        },
        isActiveConnectDevicePeriod(data) {
            if (!data) {
                return false;
            }

            return Helper.isActiveDate(data?.date_start, data?.date_stop);
        },
        endAction(data) {
            if (!data?.date_stop) {
                return this.$t('(без даты окончания)');
            }

            return this.$t('окончание акции -') + ' <strong>' + moment(data?.date_stop).format("YYYY-MM-DD") + '</strong>';

            // let days = moment(data?.date_stop).diff(moment(), "days");
            // let daysWord = Helper.wordForms(days, ["день", "дня", "дней"])
            //
            // return `(окончание акции через - <strong>${days} ${daysWord}</strong>)`;
        }
    },
    computed: {
        connect() {
            return _.chain(this.tariffs)
                .filter(item => {
                    return this.isActiveConnectPeriod(item.action);
                }).map(item => {
                    return item;
                }).value();
        },
        connect_device() {
            return _.chain(this.tariffs)
                .filter(item => {
                    return this.isActiveConnectDevicePeriod(item.action_connect_device);
                }).map(item => {
                    return item;
                }).value();
        }
    },
    watch: {
        connect(val) {
            this.$emit("init", val.length + this.connect_device.length);
        },
        connect_device(val) {
            this.$emit("init", this.connect.length + val.length);
        }
    },
    mounted() {
        this.$emit("init", this.connect.length + this.connect_device.length);
    }
}
</script>

<style scoped>

</style>
