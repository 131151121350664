import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Preloader from "@/plugins/Preloader";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import UserRole from "@/mixins/UserRole";
import i18n from "@/i18n";
import UserDepartment from "@/mixins/UserDepartment.vue";
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

} catch (e) {
}
Vue.use(VueToast, {
    position: "top-right"
});

Vue.use(Preloader);
Vue.mixin(UserRole);
Vue.mixin(UserDepartment);

Vue.config.productionTip = false;

window.Vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
