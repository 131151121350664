<template>
    <ul class="app-nav-tabs nav nav-tabs mb-4 flex-column flex-md-row">
        <li v-for="(item, index) in tariffsGroups"
            @click.prevent="$emit('change', item)"
            :key="index"
            :class="{ active : item === currentGroup }"
            class="nav-item">
            <a class="nav-link" href="#">
                {{ (item === 'КТВ' && trademark_id * 1 === 1) ? 'ОТТ Вселенная' : item }}
            </a>
        </li>
        <li class="ml-auto">
            <slot></slot>
        </li>
    </ul>
</template>

<script>
export default {
    name: "LocalityTariffsTabs",
    props: {
        tariffsGroups: Array,
        trademark_id: [String, Number],
        currentGroup: [String, Number],
    },
}
</script>

<style lang="less">

</style>
