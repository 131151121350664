<template>
    <div id="archived-tariffs-accordion">
        <div v-for="(tariffs, name, index) in groupedTariffs" :key="name" class="card bg-transparent">
            <div class="card-header bg-light">
                <h5 class="mb-0">
                    <button class="btn btn-link font-weight-bold" data-toggle="collapse" :data-target="`#${toSlug(name)}`"
                            aria-expanded="false"
                    >
                        {{ name }}
                    </button>
                </h5>
            </div>
            <div :id="toSlug(name)" class="collapse" data-parent="#archived-tariffs-accordion">
                <div class="p-4">
                    <TariffCard v-for="(tariff, index) in tariffs"
                                :tariff="tariff"
                                :key="tariff.id"
                                class="mb-4"
                                @createNote="$emit('createNote', tariff)">
                    </TariffCard>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {groupBy} from "lodash";
import slugify from "slugify";
import TariffCard from "@/components/TariffCard.vue";

export default {
    name: "TariffsAccordion",
    components: {TariffCard},
    props: {
        currentGroupTariffs: {
            type: Array,
            required: true
        }
    },
    methods: {
        toSlug(str) {
            return "_" + slugify(str, {
                replacement: '_',
                remove: undefined,
                lower: true,
                strict: true,
                locale: 'en',
                trim: true
            })
        }
    },
    computed: {
        groupedTariffs() {
            return groupBy(this.currentGroupTariffs, "group_name");
        }
    }
}
</script>

<style scoped lang="less">

</style>