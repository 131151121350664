import AbstractService from "@/services/AbstractService";

export default class ScriptService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/script/${id}`);
    }

    fetch(limit = 10, offset = 0) {
        return this.axios.get(`/script`, {
            params: {limit, offset}
        });
    }

    create({name, description, data, active, target, trademark, localities}) {
        return this.axios.post(`/script`, {name, description, data, active, target, trademark, localities});
    }

    update({id, name, description, data, active, target, trademark, localities}) {
        return this.axios.put(`/script/${id}`, {name, description, data, active, target, trademark, localities});
    }

    delete(id) {
        return this.axios.delete(`/script/${id}`);
    }
}
