<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-4">
                    <h4 class="text-light mb-0">
                        {{ $t('Товары') }}
                        <button v-if="is('administrator')"
                                @click="$router.push('/product')"
                                class="ml-3 btn btn-primary">
                            {{ $t('Добавить') }}
                        </button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <div v-if="products.length || is_init" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="products"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            :totalRows="total_products"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'name'">
                                    <a href="#"
                                       @click.prevent="$router.push('/product/' + props.row.id)"
                                       class="d-block font-weight-bold text-primary">{{ stringLimit(props.row.name) }}</a>
                                </div>
                                <div v-else-if="props.column.field === 'active'">
                                    <i v-if="props.row.active" class="fas fa-check-circle text-success"></i>
                                    <i v-else class="fas fa-times-circle text-danger"></i>
                                </div>
                                <div v-else-if="props.column.field === 'price'">
                                    <span class="text-primary">{{ props.row.price ? props.row.price + $t(' грн') : '-' }}</span>
                                </div>
                                <div v-else-if="props.column.field === 'images_links'">
                                    <img v-for="item in props.row.images_links.slice(0, 3)"
                                         :key="item"
                                         :src="item"
                                         class="d-inline ml-3 border rounded"
                                         style="width: 30px;height: 30px;object-fit: contain">
                                </div>
                                <div v-else-if="props.column.field === 'trademark'">
                                    <img v-for="item in props.row.trademark"
                                         :key="item"
                                         :src="trademarks.find(i => i.id == item).icon"
                                         class="d-inline ml-3 border rounded"
                                         style="width: 30px; height: 30px; object-fit: contain">
                                </div>
                                <div v-else-if="props.column.field === 'priority'">
                                    {{ getPriorityName(props.row.priority) }}
                                </div>
                                <div v-else>
                                    {{ stringLimit(props.formattedRow[props.column.field]) || '-' }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import TariffNote from "@/components/TariffNote";
import FullHeightBlock from "@/components/FullHeightBlock";
import ProductService from "@/services/ProductService";
import {mapState} from "vuex";
import Helper from "@/Helper";
import moment from "moment-mini";

const Product = new ProductService();

export default {
    name: 'Products',
    components: {
        Navbar,
        VueGoodTable,
        TariffNote,
        FullHeightBlock
    },
    data() {
        return {
            is_init: false,
            products: [],
            total_products: 0,
            limit: 10,
            offset: 0,
            isLoading: false,
        }
    },
    methods: {
        stringLimit: str => Helper.stringLimit(str, 20),
        async remove(id) {
            this.isLoading = true;
            await Product.delete(id);
            await this.loadProducts();
            this.isLoading = false;
        },
        async onSortChange(e) {
            const {field, type} = e[0];
            const orderType = ["asc", "desc"].indexOf(type) >= 0 ? type : null;
            await this.loadProducts(this.limit, this.offset, field, orderType);
        },
        async loadProducts(limit = 10, offset = 0, orderColumn = null, orderType = null) {
            this.isLoading = true;
            const {code, body} = await Product.fetch(limit, offset, orderColumn, orderType);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.products = body.content;
                this.total_products = body.total;
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadProducts(this.limit, this.offset);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadProducts(this.limit, this.offset);
        },
        getPriorityName(priority) {
            const names = {
                "-4": this.$tc("минимальный"),
                "-3": this.$tc("очень низкий"),
                "-2": this.$tc("низкий"),
                "-1": this.$tc("пониженный"),
                "0": this.$tc("обычный"),
                "1": this.$tc("повышенный"),
                "2": this.$tc("высокий"),
                "3": this.$tc("очень высокий"),
                "4": this.$tc("максимальный")
            };

            return names[priority * 1] || this.$tc('Неизвестно');
        }
    },
    computed: {
        ...mapState({
            trademarks: state => state.trademarks
        }),
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('Название'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$tc('Описание'),
                    field: 'description',
                    sortable: false,
                },
                {
                    label: this.$tc('Изображения'),
                    field: 'images_links',
                    sortable: false,
                },
                {
                    label: this.$tc('Комментарий'),
                    field: 'comment',
                    sortable: false,
                },
                {
                    label: 'Bitrix ID',
                    field: 'bitrix_id',
                    sortable: false,
                },
                {
                    label: 'TM',
                    field: 'trademark',
                    sortable: false,
                },
                {
                    label: this.$tc('Статус'),
                    field: 'active',
                    sortable: false,
                },
                {
                    label: this.$tc('Цена'),
                    field: 'price',
                    sortable: false,
                },
                {
                    label: this.$tc('Приоритет'),
                    field: 'priority',
                    sortable: true,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'updated_at',
                    sortable: false,
                    width: "250px",
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                }
            ];
        }
    },
    async mounted() {
        document.title = this.$tc("Товары");
        this.$preloader.show();
        await this.loadProducts(this.limit, this.offset);
        this.$preloader.hide();
        this.is_init = true;
    }
}
</script>

<style lang="less">
</style>
