import AbstractService from "@/services/AbstractService";

export default class OpponentService extends AbstractService {

    constructor() {
        super();
    }

    fetch(locality_id = null, limit = 1000, offset = 0) {
        return this.axios.get('/opponent', {
            params: {locality_id, limit, offset}
        });
    }

    create({locality_id, group_id, title, text}) {
        return this.axios.post('/opponent', {locality_id, group_id, title, text});
    }

    update({id, title, text}) {
        return this.axios.put(`/opponent/${id}`, {title, text});
    }

    delete(id) {
        return this.axios.delete(`/opponent/${id}`);
    }
}
