<template>
    <div class="app-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Content"
}
</script>

<style lang="less" scoped>
.app-content {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

</style>
