<template>
    <div>
        <label class="font-weight-bold text-muted">{{ $t('Приоритет отображения') }}
            <span class="font-weight-normal">({{ priorityName }})</span>
        </label>
        <div class="range-scale d-flex justify-content-between">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="form-group">
            <input type="range" class="form-control-range"
                   min="-4"
                   max="4"
                   step="1"
                   :value="value"
                   @input="$emit('input', $event.target.value)">
        </div>
    </div>
</template>

<script>
export default {
    name: "InputRange",
    props: {
        value: [String, Number]
    },
    computed: {
        priorityName() {
            const names = {
                "-4": this.$t('минимальный'),
                "-3": this.$t('очень низкий'),
                "-2": this.$t('низкий'),
                "-1": this.$t('пониженный'),
                "0": this.$t('обычный'),
                "1": this.$t('повышенный'),
                "2": this.$t('высокий'),
                "3": this.$t('очень высокий'),
                "4": this.$t('максимальный'),
            };

            return names[this.value] || this.$t('Неизвестно');
        }
    }
}
</script>

<style lang="less">

.range-scale {
    margin: 5px 5px 2px 5px;

    div {
        width: 3px;
        height: 8px;
        border-radius: 2px;
        background: #969696;
    }
}

</style>
