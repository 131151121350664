import AbstractService from "@/services/AbstractService";
import store from "@/store";

export default class LocalityNoteService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/locality_note/${id}`);
    }

    fetch(limit = 10, offset = 0, search_query = "") {
        return this.axios.get(`/locality_note`, {
            params: {
                limit,
                offset,
                search_query,
                department_id: store.getters.getCurrentDepartmentId
            }
        });
    }

    fetchByLocalityId(id) {
        return this.axios.get(`/locality/${id}/locality_note`, {
            params: {
                department_id: store.getters.getCurrentDepartmentId
            }
        });
    }

    create({note, title, locality_id, groups, color}) {
        return this.axios.post(`/locality_note`, {note, title, locality_id, groups, color});
    }

    update({id, note, title, locality_id, groups, color}) {
        return this.axios.put(`/locality_note/${id}`, {note, title, locality_id, groups, color});
    }

    delete(id) {
        return this.axios.delete(`/locality_note/${id}`);
    }
}
