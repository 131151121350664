<template>
    <div class="h-100 tariff-card-notes">
        <carousel class="w-100"
                  :perPage="2.5"
                  :scrollPerPage="false"
                  :paginationEnabled=false
                  :navigationEnabled=false
                  :loop="true">
            <slide v-for="(item, index) in tariff.notes" :key="Math.random()" class="pr-4 d-flex">
                <TariffNote controls :note="item" :tariff="tariff" />
            </slide>
        </carousel>
    </div>
</template>

<script>

import {Carousel, Slide} from 'vue-carousel';
import TariffNote from "@/components/TariffNote";

export default {
    name: "TariffCardNotes",
    components:{
        TariffNote,
        Carousel,
        Slide
    },
    props: {
        tariff: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="less" scoped>

.tariff-card-notes {
    overflow-y: auto;
    overflow-x: hidden;
}

</style>
