<template>
    <div v-show="localities.length > 0">
        <Navbar>
            <div class="row w-75">
                <div class="col-3">
                    <div class="d-flex align-items-center">
                        <select v-model="trademark" class="form-control mr-2">
                            <option value="1">Prosto</option>
                            <option value="2">Crystal</option>
                        </select>
                        <button class="btn btn-primary" @click="onCopy">{{ $t('Копировать')}}</button>
                    </div>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid pt-4">
            <div ref="container" style="height: auto; overflow-x: auto">
                <div v-for="locality in localities">
                    <div>{{locality.guid}} - {{locality.locality_name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import copy from 'copy-to-clipboard';

export default {
    name: 'Export',
    components: {
        Navbar,
    },
    data() {
        return {
            trademark: '1',
        }
    },
    methods: {
        onCopy() {
            copy(this.list)
        }
    },
    computed: {
        localities() {
            return this.$store.state.localities.filter(item => item.trademark_id == this.trademark) || [];
        },
        list() {
            let list = '';
            this.localities.map(item => {
                list += `${item.guid} - ${item.locality_name} \n`
            })
            return list;
        }
    },
    mounted() {
        document.title = this.$tc("Список НП");
    }
}
</script>
