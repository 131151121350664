import AbstractService from "@/services/AbstractService";

export default class UserService extends AbstractService {

    constructor() {
        super();
    }

    fetch() {
        return this.axios.get('/user');
    }

    get(id) {
        return this.axios.get(`/user/${id}`);
    }

    create({name, email, password, role_id, send_credentials, department_id}) {
        return this.axios.post('/user', {name, email, password, role_id, send_credentials, department_id});
    }

    update({id, name, password, role_id, send_credentials, department_id}) {
        return this.axios.put(`/user/${id}`, {name, password, role_id, send_credentials, department_id});
    }

    changePassword(old_password, new_password) {
        return this.axios.put("/user/password", {old_password, new_password});
    }

    delete(id) {
        return this.axios.delete(`/user/${id}`);
    }

}
