<template>
    <div class="row mt-3 mr-0 ml-1">
        <FullHeightBlock :offsetBottom="80" ref="messages" class="custom-scroll">
            <div ref="commentsWrapper" v-observe-visibility="visibilityMessagesChanged" class="w-100 pr-3">
                <CommentsMessage v-for="(item, index) in groupMessages"
                                 :key="index"
                                 :message="item"
                                 @read="markMessageAsViewed"
                                 @deleted="markMessageAsDeleted">
                </CommentsMessage>
            </div>
        </FullHeightBlock>
        <div class="col-12 mt-2 pl-0">
            <div class="d-flex justify-content-between">
                <textarea v-model="message" class="form-control flex-fill" style="height: 50px;" :placeholder="$t('Напишите комментарий')"></textarea>
                <button @click="sendMessage" :disabled="processing || !message.length" class="btn btn-primary btn-block ml-4"
                        style="height: 50px; width: 50px;">
                    <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status"></div>
                    <i v-else class="fas fa-paper-plane"></i>
                </button>
            </div>
        </div>
        <UpdateMessageModal @updated="updateMessage"></UpdateMessageModal>
    </div>
</template>

<script>

import _ from "underscore";
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import MessageService from "@/services/MessageService";
import {ObserveVisibility} from 'vue-observe-visibility'
import CommentsMessage from "@/components/CommentsMessage";
import UpdateMessageModal from "@/modals/UpdateMessageModal";
import FullHeightBlock from "@/components/FullHeightBlock";
import {mutationTypes} from "@/store";

const Message = new MessageService();

export default {
    name: "Comments",
    components: {
        CommentsMessage,
        UpdateMessageModal,
        FullHeightBlock
    },
    directives: {
        ObserveVisibility
    },
    props: ["group_id", "locality_id"],
    data() {
        return {
            viewedMessages: [],
            readMessages: [],
            messages: [],
            message: "",
            processing: false,
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: false
            },
        }
    },
    methods: {
        markMessageAsDeleted(message) {
            this.messages = _.map(this.messages, item => {
                return (item.id === message.id) ? message : item;
            });
        },
        markMessageAsViewed(message) {
            if (this.viewedMessages.indexOf(message.id * 1) === -1) {
                this.viewedMessages.push(message.id * 1)
            }
        },
        visibilityMessagesChanged(visible) {
            if (visible) {
                this.$nextTick(() => {
                    this.scrollMessagesToEnd();
                });
            }
        },
        scrollMessagesToEnd() {
            let objDiv = this.$refs.messages.$el;
            objDiv.scrollTop = objDiv.scrollHeight;
        },
        async sendMessage() {
            this.processing = true;

            const {code, body} = await Message.create({
                locality_id: this.locality_id,
                group_id: this.group_id,
                message: this.message
            });

            this.processing = false;

            if (code === 201) {
                this.messages.push(body);
                this.message = "";
                await this.$nextTick();
                this.scrollMessagesToEnd();
            }
        },
        async loadMessages() {
            const {code, body} = await Message.fetch(this.locality_id);

            if (code === 200) {
                this.messages = body.content.reverse();
            }
        },
        updateMessage(message) {
            this.messages = _.map(this.messages, item => {
                return message.id === item.id ? message : item;
            });
        }
    },
    computed: {
        groupMessages() {
            return _.filter(this.messages, item => {
                return item.group_id * 1 === this.group_id * 1;
            })
        }
    },
    watch: {
        groupMessages(val) {
            this.$emit("init", val.length);

            this.$nextTick(() => {
                this.scrollMessagesToEnd();
            });
        },
        viewedMessages: _.debounce(async function (messagesIds) {

            let newIds = _.filter(messagesIds, massageId => {
                return this.readMessages.indexOf(massageId) === -1;
            });

            if (newIds.length) {
                this.readMessages.push(...newIds);
                await Message.read(newIds);
                this.$store.commit(mutationTypes.SET_COUNT_UNREAD_MESSAGES, this.$store.state.countUnreadMessages - newIds.length);
            }
        }, 1000)
    },
    async mounted() {
        await this.loadMessages();
    }
}
</script>

<style lang="less" scoped>

</style>
