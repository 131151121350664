<template>
    <div class="container-fluid position-relative">
        <Logo class="position-absolute px-4" style="top:0; left:0;"></Logo>
        <div class="row">
            <div class="col-12">
                <div class="auth-box mx-auto">
                    <h4 class="text-center font-weight-bold mb-4">{{ $t('Регистрация') }}</h4>
                    <div v-if="!success" class="card p-4">
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">{{ $t('Имя') }}</label>
                            <input v-model="name" type="email" class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">Email</label>
                            <input v-model="email" type="email" class="form-control">
                        </div>
                        <div class="form-group mb-4">
                            <label class="font-weight-bold text-black-50">{{ $t('Пароль') }}</label>
                            <input v-model="password" type="password" class="form-control">
                        </div>
                        <div class="form-group mb-4">
                            <label class="font-weight-bold text-black-50">{{ $t('Повтор пароля') }}</label>
                            <input v-model="password_confirmation" type="password" class="form-control">
                        </div>
                        <button @click="register()" :disabled="processing || !isValid" class="btn btn-primary mb-3">
                            <div v-if="processing" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span v-else>{{ $t('Зарегистрироватся') }}</span>
                        </button>
                        <button @click="$router.push({name: 'Auth'})" class="btn btn-light text-primary">{{ $t('Авторизация') }}</button>
                    </div>
                    <div v-else class="card p-4">
                        <p class="mb-3">
                            {{ $t('Аккаунт зарегистрирован.') }} <br>
                            {{ $t('Аккаунт будет активирован после проверки администратором. После активации вы получите уведомление на') }}
                            <strong>{{ email }}</strong>. {{ $t('Если уведомление не пришло проверьте папку "спам".') }}
                        </p>
                        <button @click="$router.push({name: 'Auth'})" class="btn btn-light text-primary">{{ $t('Авторизация') }}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Logo from "@/components/Logo";
import AuthService from "@/services/AuthService";

const Auth = new AuthService();

export default {
    name: "Register",
    components: {
        Logo
    },
    data() {
        return {
            success: false,
            processing: false,
            email: "",
            password: "",
            password_confirmation: "",
            name: ""
        }
    },
    methods: {
        async register() {
            this.$preloader.hide();
            this.processing = true;
            const {code, body} = await Auth.register(this.name, this.email, this.password);
            this.processing = false;

            if (code === 201) {
                this.$toast.success(this.$tc("Регистрация успешна!"));
                this.$router.push({name: 'Auth'})
            } else {
                this.$toast.error(body.message);
            }
        },
    },
    computed: {
        isValid() {
            return this.password.length >= 8
                && this.password === this.password_confirmation
                && this.name.length >= 2
                && /\S+@\S+\.\S+/.test(this.email);
        }
    },
    mounted() {
        document.title = this.$tc("Регистрация");
    }
}
</script>

<style lang="less" scoped>

.container-fluid {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth-box {
    max-width: 350px;
}
</style>
