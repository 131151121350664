<template>
    <div class="container-fluid position-relative">
        <Logo class="position-absolute px-4" style="top:0; left:0;"></Logo>
        <div class="row">
            <div class="col-12">
                <div class="auth-box mx-auto">
                    <h4 class="text-center font-weight-bold mb-4">{{ $t('Авторизация') }}</h4>
                    <div class="card p-4">
                        <div class="form-group">
                            <label class="font-weight-bold text-black-50">Email</label>
                            <input v-model="email" type="email" class="form-control" placeholder="Email">
                        </div>
                        <div class="form-group mb-4">
                            <label class="font-weight-bold text-black-50">{{ $t('Пароль') }}</label>
                            <input v-model="password" type="password" class="form-control" :placeholder="$t('Пароль')">
                        </div>
                        <button @click="login()" :disabled="processing" class="btn btn-primary mb-3">
                            <div v-if="processing" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span v-else><i class="fas fa-fw fa-sign-in-alt"></i> {{ $t('Войти') }}</span>
                        </button>
                        <button v-if="allow_user_register" @click="$router.push({name: 'Register'})"
                                class="btn btn-light mb-3 text-primary">
                            {{ $t('Регистрация') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/Logo";
import AuthService from "@/services/AuthService";
import {mutationTypes} from "@/store";
import ConfigurationService from "@/services/ConfigurationService";

const Auth = new AuthService();
const Configuration = new ConfigurationService();

export default {
    name: "Auth",
    components: {
        Logo
    },
    data() {
        return {
            processing: false,
            email: "",
            password: "",
            allow_user_register: false
        }
    },
    methods: {
        async login() {
            this.processing = true;
            const {code, body} = await Auth.login(this.email, this.password);
            this.processing = false;

            if (code === 200) {
                this.$store.commit(mutationTypes.SET_BEARER_TOKEN, body.access_token);
                setTimeout(() => {
                    window.location.replace("/");
                });
            } else if (code === 403) {
                this.$toast.error(this.$tc("Неверный логин или пароль"));
            } else {
                this.$toast.error(this.$tc("Внутренняя ошибка сервера"));
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            Configuration.get().then(({body}) => {
                vm.allow_user_register = !!body?.allow_user_register;
                vm.$preloader.hide();
            });
            document.title = vm.$tc("Авторизация");
        });
    }
}
</script>

<style lang="less" scoped>

.container-fluid {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth-box {
    max-width: 350px;
}
</style>
