<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Слепок тарифа') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="background: #E5E5E5;">
                    <div v-if="loading" class="text-center py-5 my-5">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="tariff">
                            <div class="alert alert-success p-3 mb-4">
                                <div class="font-weight-bold">
                                    {{
                                        $t('Показан слепок тарифа на момент создания (редактирования) текущей заметки')
                                    }}.
                                </div>
                                {{ $t('Слепок создан') }}: {{ note.updated_at }}
                            </div>
                            <TariffCard hide-buttons :tariff="tariff"/>
                        </div>
                        <div v-else class="alert alert-danger font-weight-bold p-3 text-center">
                            {{ $t('Нет слепка тарифа для этой заметки') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import TariffCard from "@/components/TariffCard";
import TariffSnapshotService from "@/services/TariffSnapshotService";

const TariffSnapshot = new TariffSnapshotService();

export default {
    name: "TariffSnapshotModal",
    components: {
        TariffCard
    },
    data() {
        return {
            visible: false,
            loading: false,
            tariff: null,
            note: null
        }
    },
    methods: {
        async getTariffSnapshot(note) {
            this.loading = true;

            const {body} = await TariffSnapshot.fetch({
                note_id: note.id
            });

            if (body?.content?.length > 0) {
                this.tariff = JSON.parse(body.content[0].tariff_snapshot);
            }

            this.loading = false;
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data());
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        async onTariffSnapshotModalOpen(note) {
            this.visible = true;
            this.note = note;
            await this.getTariffSnapshot(note);
        },
        onHideBsModal() {
            this.visible = false;
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.TARIFF_SNAPSHOT_MODAL_OPEN, this.onTariffSnapshotModalOpen);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.TARIFF_SNAPSHOT_MODAL_OPEN, this.onTariffSnapshotModalOpen);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    }
}
</script>

<style scoped>

</style>
