import AbstractService from "@/services/AbstractService";

export default class TvBundleService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/tv/bundle/${id}`);
    }

}
