import AbstractService from "@/services/AbstractService";

export default class NotifierService extends AbstractService {

    constructor() {
        super();
    }

    send(params) {
        return this.axios.post("/notifier", params);
    }

    configuration() {
        return this.axios.get("/notifier/configuration");
    }
}
