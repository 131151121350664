import AbstractService from "@/services/AbstractService";

export default class SmsSampleService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/sms_sample/${id}`);
    }

    fetch(limit = 10, offset = 0) {
        return this.axios.get("/sms_sample", {
            params: {limit, offset}
        });
    }

    create({message, comment = null}) {
        return this.axios.post(`/sms_sample`, {message, comment});
    }

    update({id, message, comment = null}) {
        return this.axios.put(`/sms_sample/${id}`, {message, comment});
    }

    delete(id) {
        return this.axios.delete(`/sms_sample/${id}`);
    }
}
