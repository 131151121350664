<template>
    <div :class="{ 'app-sidebar-collapsed' : $store.state.sidebarCollapsed }"
         class="app-sidebar d-flex flex-column justify-content-between overflow-auto custom-sidebar-scroll">
        <Logo :collapsed="$store.state.sidebarCollapsed"></Logo>
        <div class="flex-fill px-3 py-3">
            <div v-for="(item, index) in links" :key="index"
                 class="app-sidebar__link"
                 :class="{ 'app-sidebar__link-active' : $route.name === item.routeName || isNestedRoute(item) }"
                 @click="($route.name !== item.routeName) && $router.push({name: item.routeName})">
                <i :class="item.iconClass"></i>
                <span class="app-sidebar__link-name pl-2">{{ item.name }}</span>
                <span v-if="item.badge > 0" class="badge ml-1 badge-pill badge-danger">{{ item.badge }}</span>
            </div>
        </div>
        <div class="text-right p-4 d-none d-md-block" :class="{ 'text-center' : $store.state.sidebarCollapsed }">
            <button class="btn btn-secondary"
                    @click="toggleSidebar">
                <i v-if="$store.state.sidebarCollapsed" class="fas fa-angle-double-right"></i>
                <i v-else class="fas fa-angle-double-left"></i>
            </button>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/Logo";
import {mutationTypes} from "@/store";

export default {
    name: "Sidebar",
    components: {
        Logo
    },
    methods: {
        isNestedRoute(item) {
            return item.nestedRoutes?.find(e => e === this.$router.currentRoute.name);
        },
        toggleSidebar() {
            this.$store.commit(mutationTypes.SET_SIDEBAR_COLLAPSED, !this.$store.state.sidebarCollapsed);
        }
    },
    computed: {
        links() {
            const links = [
                {
                    routeName: "Home",
                    nestedRoutes: ["Locality"],
                    iconClass: "fas fa-fw fa-globe",
                    name: this.$t('Населенные пункты'),
                    visibleRoles: [],
                    hiddenRoles: [],
                },
                {
                    routeName: "Users",
                    iconClass: "fas fa-fw fa-users",
                    name: this.$t('Пользователи'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
                {
                    routeName: "UserRoles",
                    iconClass: "fas fa-fw fas fa-user-tag",
                    name: this.$t('Типы пользователей'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
                {
                    routeName: "Departments",
                    iconClass: "fas fa-id-card-alt",
                    name: this.$t('Департаменты'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
                {
                    routeName: "LocalityNotes",
                    iconClass: "far fa-fw fa-clipboard",
                    name: this.$t('Заметки НП'),
                    visibleRoles: [],
                    hiddenRoles: [],
                },
                {
                    routeName: "TariffNotes",
                    iconClass: "fas fa-fw fa-clipboard",
                    name: this.$t('Заметки тарифов'),
                    visibleRoles: [],
                    hiddenRoles: [],
                },
                {
                    routeName: "Opponents",
                    iconClass: "fas fa-fw fa-wheelchair",
                    name: this.$t('Конкуренты'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
                {
                    routeName: "Comments",
                    iconClass: "fas fa-fw fa-comments",
                    name: this.$t('Комментарии'),
                    visibleRoles: [],
                    hiddenRoles: [],
                    badge: this.$store.state.countUnreadMessages
                },
                {
                    routeName: "Products",
                    iconClass: "fas fa-fw fa-store",
                    name: this.$t('Товары'),
                    visibleRoles: [],
                    hiddenRoles: [],
                },
                {
                    routeName: "Scripts",
                    iconClass: "fas fa-fw fa-scroll",
                    name: this.$t('Скрипты'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
                {
                    routeName: "SmsMessage",
                    iconClass: "fas fa-fw fa-comment",
                    name: this.$t('Сообщения'),
                    visibleRoles: [],
                    hiddenRoles: [],
                },
                {
                    routeName: "Export",
                    nestedRoutes: [],
                    iconClass: "fas fa-fw fa-file-export",
                    name: this.$t('Список НП'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
                {
                    routeName: "Settings",
                    iconClass: "fas fa-cog",
                    name: this.$t('Настройки'),
                    visibleRoles: [],
                    hiddenRoles: [],
                },

                {
                    routeName: "Activities",
                    iconClass: "fas fa-list",
                    name: this.$t('Активность'),
                    visibleRoles: ["administrator"],
                    hiddenRoles: [],
                },
            ];

            return links.filter(item => {
                return (item.visibleRoles.length === 0 || item.visibleRoles?.indexOf(this.userRoleSlug) > -1)
                    && item.hiddenRoles?.indexOf(this.userRoleSlug) === -1
            })
        }
    },
    mounted() {
        $(window).resize(() => {
            if ($(window).width() < 1024) {
                this.$store.commit(mutationTypes.SET_SIDEBAR_COLLAPSED, true);
            }
        });

        $(window).resize();
    }
}
</script>

<style lang="less" scoped>

@import "../styles/variables";

.custom-sidebar-scroll::-webkit-scrollbar {
    width: 5px;
}

.custom-sidebar-scroll::-webkit-scrollbar-track {
    background-color: #383E44;
    border-radius: 5px;
}

.custom-sidebar-scroll::-webkit-scrollbar-thumb {
    background: #007BFF;
    border-radius: 5px;
}

.app-sidebar {
    position: relative;
    width: 290px;
    height: 100vh;
    background: tint(#343A40, 2);
    z-index: 10;

    &__link {
        cursor: pointer;
        padding: 10px 15px;
        background: tint(#343A40, 5);
        border-radius: 5px;
        color: #a9a9a9;
        margin-bottom: 12px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        &:hover {
            color: #fff;
            background: tint(#343A40, 10);
        }

        &-active {
            color: #fff;
            background: tint(#343A40, 10);
            font-weight: 500;
            border-left: 4px solid #3495E3;
        }
    }
}

.app-sidebar-collapsed {
    width: auto;

    @media (max-width: 767px) {
        width: 20vw;
    }

    .app-sidebar__link {
        text-align: center;
        padding: 10px 5px;
    }

    .app-sidebar__link-name, .badge {
        display: none;
    }
}

</style>
