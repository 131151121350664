<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-4">
                    <h4 class="text-light mb-0">
                        {{ script_id ? $t('Скрипт') : $t('Добавление скрипта') }}
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <label class="font-weight-bold text-muted">{{ $t('Имя') }}</label>
                        <input v-model="script.name" :readonly="isNot('administrator')" type="text"
                               class="form-control mb-4">
                        <label class="font-weight-bold text-muted">{{ $t('Описание') }}</label>
                        <textarea v-model="script.description" :readonly="isNot('administrator')"
                                  class="form-control mb-4" rows="4"></textarea>
                        <hr>
                        <label class="font-weight-bold text-muted d-block mb-3">{{ $t('Редактор алгоритма') }}</label>
                        <div class="mb-4">
                            <button @click="drawflowOpened = true" v-if="script_id" class="btn btn-success">
                                {{ $t('Открыть редактор') }}
                                <i class="fas fa-arrow-right ml-2"></i>
                            </button>
                            <div v-else class="text-muted font-italic">
                                {{ $t('Редактор алгоритма будет доступен после нажатия кнопки "Сохранить"') }}
                            </div>
                        </div>
                        <hr>
                        <label class="font-weight-bold text-muted d-block mb-3">
                            <div>{{ $t('Отношение к населенным пунктам') }}</div>
                            <small class="d-block"><i>{{
                                    $t('(Если не выбран ни один НП - скрипт будет добавлен во все НП)')
                                }}</i></small>
                        </label>
                        <div class="mb-4">
                            <button @click="openLocalitiesPickerModal" class="btn btn-primary">
                                {{ $t('Выбрать') }} <span v-if="script.localities.length">({{
                                    script.localities.length
                                }})</span>
                                <i class="fas fa-arrow-right ml-2"></i>
                            </button>
                        </div>
                        <hr>
                        <!--                        <label class="font-weight-bold text-muted d-block mb-3">Отношение к ТМ</label>-->
                        <!--                        <div v-for="item in trademarks" :key="item.id"-->
                        <!--                             class="d-flex justify-content-start align-items-center">-->
                        <!--                            <toggle-button @change="setTrademark(item.id)"-->
                        <!--                                           class="mr-4"-->
                        <!--                                           :disabled="isNot('administrator')"-->
                        <!--                                           :font-size="10"-->
                        <!--                                           sync-->
                        <!--                                           :value="isTrademarkChecked(item.id)"-->
                        <!--                                           :width="50" :height="30"/>-->
                        <!--                            <img :src="item.icon" style="width: 30px; height: 30px; object-fit: contain"-->
                        <!--                                 class="border rounded m-2">-->
                        <!--                            <div class="mx-4 font-weight-bold text-black-50 pb-2">{{ item.name }}</div>-->
                        <!--                        </div>-->
                        <!--                        <hr>-->
                        <label class="font-weight-bold text-muted mb-3">{{ $t('Статус') }}</label>
                        <div class="d-flex justify-content-start align-items-center">
                            <toggle-button v-model="script.active"
                                           :disabled="isNot('administrator')"
                                           :font-size="11"
                                           :value="script.active"
                                           sync
                                           :width="50" :height="30"/>
                            <div class="font-weight-bold text-muted pl-4 pb-2">
                                {{ script.active ? $t('Активен') : $t('Не активен') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <ScriptViewer v-if="script.data" :data="script.data">
                            <label class="text-muted font-weight-bold">{{ $t('Пример') }}</label>
                        </ScriptViewer>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-5">
                        <button v-if="script_id && is('administrator')" :disabled="!isValid" @click="updateScript"
                                class="btn btn-primary">
                            {{ $t('Сохранить изменения') }}
                        </button>
                        <button v-else-if="is('administrator')" :disabled="!isValid" @click="createScript"
                                class="btn btn-primary">
                            {{ $t('Сохранить') }}
                        </button>
                        <button v-if="script_id && is('administrator')" @click="removeScript"
                                class="btn btn-danger d-inline-block ml-4">
                            {{ $t('Удалить скрипт') }}
                        </button>
                    </div>
                </div>
            </div>
        </FullHeightBlock>
        <Drawflow v-if="drawflowOpened"
                  :data="script.data"
                  @close="drawflowOpened = false"
                  @change="saveDrawflow"/>

    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import FullHeightBlock from "@/components/FullHeightBlock";
import {ToggleButton} from 'vue-js-toggle-button';
import {mapState} from "vuex";
import ScriptService from "@/services/ScriptService";
import Drawflow from "@/components/Drawflow";
import ScriptViewer from "@/components/ScriptViewer";
import {EventBus, Events} from "@/EventBus";

const Script = new ScriptService();

export default {
    name: 'Script',
    components: {
        Navbar,
        FullHeightBlock,
        ToggleButton,
        Drawflow,
        ScriptViewer
    },
    props: {
        script_id: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            drawflowOpened: false,
            script: {
                active: true,
                description: "",
                name: "",
                data: null,
                trademark: [],
                target: [],
                localities: [],
            }
        }
    },
    methods: {
        openLocalitiesPickerModal() {
            EventBus.$emit(Events.SHOW_LOCALITIES_PICKER_MODAL, this.script.localities);
        },
        async saveDrawflow(e) {
            this.script.data = e;
            await this.updateScript();
        },
        async createScript() {
            this.$preloader.show();
            const {code, body} = await Script.create({
                ...this.script,
                data: JSON.stringify(this.script.data),
                active: +this.script.active
            });
            this.$preloader.hide();

            if (code !== 201) {
                this.$toast.error(body.message);
            } else {
                this.$toast.success(this.$tc('Скрипт добавлен'));
                this.$router.push(`/script/${body.id}`);
            }
        },
        async updateScript() {
            this.$preloader.show();
            const {code, body} = await Script.update({
                ...this.script,
                id: this.script_id,
                data: JSON.stringify(this.script.data),
                active: +this.script.active
            });
            this.$preloader.hide();

            if (code !== 200) {
                this.$toast.error(body.message);
            } else {
                this.$toast.success(this.$tc("Скрипт обновлен"));
            }
        },
        async removeScript() {
            if (!confirm(this.$tc("Точно?"))) return;

            this.$preloader.show();
            const {code, body} = await Script.delete(this.script_id);
            this.$preloader.hide();

            if (code !== 200) {
                this.$toast.error(body.message);
            } else {
                this.$toast.success(this.$tc("Скрипт удален"));
                await this.$router.push({name: "Scripts"});
            }
        },
        setTrademark(trademarkId) {
            if (this.script.trademark.indexOf(trademarkId) > -1) {
                this.script.trademark = this.script.trademark.filter(item => item != trademarkId);
            } else {
                this.script.trademark.push(trademarkId);
            }
        },
        isTrademarkChecked(trademarkId) {
            return this.script.trademark?.indexOf(trademarkId) > -1;
        },
        onLocalitiesPickerModalSelected(selectedLocalities) {
            this.script.localities = selectedLocalities;
        }
    },
    computed: {
        ...mapState({
            trademarks: store => store.trademarks
        }),
        isValid() {
            return this.script.name.length && this.script.trademark.length;
        }
    },
    async mounted() {
        EventBus.$on(Events.LOCALITIES_PICKER_MODAL_SELECTED, this.onLocalitiesPickerModalSelected);

        document.title = this.product_id ? this.$tc('Скрипт') : this.$tc('Добавление скрипта');

        if (!this.script_id) {
            this.script.trademark = this.trademarks.map(item => item.id);
            return;
        }

        this.$preloader.show();
        const {body, code} = await Script.get(this.script_id);
        this.$preloader.hide();

        if (code === 200) {
            this.script = body;
            this.script.data = JSON.parse(body.data);
        }
    },
    beforeDestroy() {
        EventBus.$off(Events.LOCALITIES_PICKER_MODAL_SELECTED, this.onLocalitiesPickerModalSelected);
    }
}
</script>

<style lang="less">
</style>
