<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-4">
                    <h4 class="text-light mb-0">
                        {{ $t('Заметки тарифов') }}
                    </h4>
                </div>
                <div class="col-8">
                    <div class="input-group w-100">
                        <input v-model="searchQuery" type="text" class="form-control"
                               placeholder="Поиск по заметкам тарифов">
                        <div v-if="searchQuery.length" @click="searchQuery = ''" class="input-group-append">
                            <button class="btn btn-primary" type="button">
                                <i class="fas fa-times px-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <DepartmentTabs/>
                <div v-if="notes.length || is_init" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="notes"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="total_notes"
                            :isLoading.sync="isLoading"
                            @on-search="onSearch"
                            :search-options="{
                            enabled: true,
                            externalQuery: searchQuery,
                        }"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'locality'">
                                    <a href="#"
                                       @click.prevent="$router.push('/locality/' + props.row.locality_id)"
                                       class="d-block font-weight-bold text-primary">{{
                                            localityData(props.row.locality_id).locality_name || '-'
                                        }}</a>
                                    <small class="d-block text-black-50">{{
                                            localityData(props.row.locality_id).area_name
                                        }}</small>
                                    <small class="d-block text-black-50 font-weight-bold">{{
                                            localityData(props.row.locality_id).region_name
                                        }}</small>
                                </div>
                                <div v-else-if="props.column.field === 'note'">
                                    <TariffNote :controls="is('administrator')"
                                                :tariff="props.row.tariff"
                                                :note="props.row"/>
                                </div>
                                <div v-else-if="props.column.field === 'actions'">
                                    <button @click="remove(props.row.id)" class="btn btn-danger btn-sm">{{
                                            $t('Удалить')
                                        }}
                                    </button>
                                </div>
                                <div v-else-if="props.column.field === 'tariff'">
                                <span class="text-primary font-weight-bold d-block">
                                    {{ props.row.tariff && props.row.tariff.name }}
                                </span>
                                    <small class="text-secondary font-weight-bold d-block">
                                        ID: {{ props.row.tariff_id }}
                                    </small>
                                    <small>
                                        {{ props.row.tariff && props.row.tariff.group_name }}
                                    </small>
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
        <UpdateTariffNoteModal @updated="loadTariffs"/>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import TariffNoteService from "@/services/TariffNoteService";
import TariffService from "@/services/TariffService";
import TariffNote from "@/components/TariffNote";
import {cloneDeep} from "lodash";
import FullHeightBlock from "@/components/FullHeightBlock";
import moment from "moment-mini";
import DepartmentTabs from "@/components/DepartmentTabs.vue";
import {mapState} from "vuex";
import UpdateTariffNoteModal from "@/modals/UpdateTariffNoteModal.vue";

const TariffNotes = new TariffNoteService();
const Tariff = new TariffService();

export default {
    name: 'TariffNotes',
    components: {
        UpdateTariffNoteModal,
        DepartmentTabs,
        Navbar,
        VueGoodTable,
        TariffNote,
        FullHeightBlock
    },
    data() {
        return {
            is_init: false,
            notes: [],
            total_notes: 0,
            limit: 10,
            offset: 0,
            isLoading: false,
            searchQuery: ""
        }
    },
    methods: {
        async onSearch({searchTerm}) {
            this.searchQuery = searchTerm;
            this.limit = 10;
            this.offset = 0;

            await this.loadNotes(this.limit, this.offset, this.searchQuery);
        },
        async remove(id) {
            this.isLoading = true;
            await TariffNotes.delete(id);
            await this.loadNotes();
            this.isLoading = false;
        },
        async loadNotes(limit = 10, offset = 0, searchQuery = "") {
            this.isLoading = true;
            const {code, body} = await TariffNotes.fetch(limit, offset, [], searchQuery);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.notes = body.content;
                this.total_notes = body.total;
                await this.loadTariffs();
            }
        },
        async loadTariffs() {
            const cache = new Map();

            for (let i in this.notes) {

                if (!this.notes[i]) {
                    return;
                }

                let cache_key = `${this.notes[i].locality_id}-${this.notes[i].trademark_id}}`;

                if (!cache.has(cache_key)) {
                    let tariff = await Tariff.fetch(this.notes[i].locality_id, this.notes[i].trademark_id);

                    cache.set(
                        cache_key,
                        tariff?.body?.find(t => t.uniq_id === this.notes[i].tariff_id)
                    );
                }

                this.$set(this.notes, i, {
                    ...this.notes[i],
                    tariff: cache.get(cache_key)
                });
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadNotes(this.limit, this.offset, this.searchQuery);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadNotes(this.limit, this.offset, this.searchQuery);
        },
        localityData(id) {
            return this.$store.state.localities.find(item => item.id == id) || {};
        }
    },
    computed: {
        ...mapState({
            currentDepartment: state => state.currentDepartment
        }),
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('НП'),
                    field: 'locality',
                    sortable: false,
                    width: "300px"
                },
                {
                    label: this.$tc('Тариф'),
                    field: 'tariff',
                    sortable: false,
                    width: "300px"
                },
                {
                    label: this.$tc('Заметка'),
                    field: 'note',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'updated_at',
                    sortable: false,
                    width: "250px",
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                }
            ];
        }
    },
    watch: {
        currentDepartment: {
            deep: true,
            async handler() {
                this.limit = 10;
                this.offset = 0;
                await this.loadNotes(this.limit, this.offset, this.searchQuery);
            }
        },
    },
    async mounted() {
        document.title = this.$tc("Заметки тарифов");
        this.$preloader.show();
        await this.loadNotes(this.limit, this.offset);
        this.$preloader.hide();
        this.is_init = true;
    }
}
</script>

<style lang="less">
</style>
