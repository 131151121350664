import AbstractService from "@/services/AbstractService";
import store from "@/store";

export default class TariffNoteService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/tariff_note/${id}`);
    }

    fetch(limit = 10, offset = 0, id = [], search_query = "") {
        return this.axios.get(`/tariff_note`, {
            params: {
                limit,
                offset,
                id,
                search_query,
                department_id: store.getters.getCurrentDepartmentId
            }
        });
    }

    create({note, title, tariff_id, trademark_id, locality_id, color}) {
        return this.axios.post(`/tariff_note`, {note, title, tariff_id, trademark_id, locality_id, color});
    }

    update({id, note, title, color}) {
        return this.axios.put(`/tariff_note/${id}`, {note, title, color});
    }

    delete(id) {
        return this.axios.delete(`/tariff_note/${id}`);
    }
}
