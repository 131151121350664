<template>
    <a href="javascript:void(0)"
       v-show="items.length"
       data-toggle="popover"
       data-placement="top"
       data-html="true"
       class="text-secondary sms-sample-popover"
       :title="title"
       :data-content="content">
        <i class="fas fa-history"></i>
    </a>
</template>

<script>

import {mapState} from "vuex";
import {uniqueId} from "lodash";
import Helper from "@/Helper";

export default {
    name: "SmsSamplePopover",
    props: {
        smsSamples: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            uniqPopoverId: uniqueId("sms-sample-popover-")
        }
    },
    methods: {
        handleItemClick(elementId) {
            const index = elementId.replace("_", "") * 1;
            this.$emit("click", this.items[index]);
        }
    },
    computed: {
        ...mapState({}),
        title() {
            return this.$t('Шаблоны сообщений')
        },
        items() {
            return this.smsSamples.map(item => item.message);
        },
        content() {
            let items = this.items;

            let html = `<div class="sms-sample-popover__wrapper custom-scroll">`;
            html += `<div id="${this.uniqPopoverId}" class="sms-sample-popover__inner">`;

            for (let i in items) {
                // data атрибут какого то хуя не устанавливается, сделал через id
                // language=HTML
                html += `
                    <div id="_${i}" class="alert p-2 mb-2 alert-secondary">
                        <span class="d-block">${Helper.nl2br(items[i])}</span>
                    </div>
                `;
            }

            html += `</div></div>`;

            return html;
        },
    },
    beforeDestroy() {
        let self = this;
        $(document).off("click", `#${this.uniqPopoverId} .alert`, function () {
            self.handleItemClick($(this).attr("id"));
        });
    },
    mounted() {
        let self = this;
        $(document).on("click", `#${this.uniqPopoverId} .alert`, function () {
            self.handleItemClick($(this).attr("id"));
        });
    }
}
</script>

<style lang="less">

.sms-sample-popover {
    position: absolute;
    bottom: 2rem;
    right: .5rem;

    &__wrapper {
        max-height: 500px;
        overflow-y: auto;
    }

    &__inner {
        .alert {
            cursor: pointer;
            margin-right: .5rem;

            &:hover {
                border-color: #0055ff !important;
            }
        }
    }
}


</style>
