<template>
    <ul class="app-nav-tabs nav nav-tabs mb-4 flex-column flex-md-row">
        <li v-for="(item, index) in computedVisibleDepartments"
            @click.prevent="changeDepartment(item)"
            :key="index"
            :class="{ active : item.id === currentDepartmentId }"
            class="nav-item">
            <a class="nav-link" href="#">
                {{ item.name }}
            </a>
        </li>
        <li @click.prevent="changeDepartment(null)"
            :class="{ active : null === currentDepartmentId }"
            class="nav-item">
            <a class="nav-link" href="#">
                {{ $t("Все") }}
            </a>
        </li>
    </ul>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {mutationTypes} from "@/store";
import {EventBus, Events} from "@/EventBus";

export default {
    name: "LocalityDepartmentTabs",
    methods: {
        changeDepartment(department) {
            EventBus.$emit(Events.DEPARTMENT_CURRENT_TAB_CHANGED);
            this.$store.commit(mutationTypes.SET_CURRENT_DEPARTMENT, department);
        }
    },
    computed: {
        ...mapState({
            currentDepartment: state => state.currentDepartment,
        }),
        ...mapGetters({
            visibleDepartments: "getVisibleDepartments"
        }),
        currentDepartmentId() {
            return this.currentDepartment?.id || null;
        },
        computedVisibleDepartments() {
            // вкладка Суперадмины - видна только суперадминам
            return this.visibleDepartments.filter(item => {
                if (this.isDepartment('su')) {
                    return true
                }

                return item.slug !== "su";
            })
        }
    }
}
</script>


<style scoped lang="less">

</style>
