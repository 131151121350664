<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-4">
                    <h4 class="text-light mb-0">
                        {{ product_id ? $t('Товар') : $t('Добавление товара') }}
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <label class="font-weight-bold text-muted">{{ $t('Имя') }}</label>
                        <input v-model="product.name" :readonly="isNot('administrator')" type="text" class="form-control mb-4">

                        <label class="font-weight-bold text-muted">{{ $t('Описание') }}</label>
                        <textarea v-model="product.description" :readonly="isNot('administrator')" class="form-control mb-4" rows="4"></textarea>

                        <label class="font-weight-bold text-muted">{{ $t('Комментарий') }}</label>
                        <textarea v-model="product.comment" :readonly="isNot('administrator')" class="form-control mb-4" rows="4"></textarea>

                        <template v-if="is('administrator')">
                            <InputRange v-model="product.priority"/>
                            <small class="text-muted d-block font-italic mb-4">
                                {{ $t('Чем выше приоритет, тем выше элемент будет отображаться в списке.') }}
                            </small>
                        </template>

                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="font-weight-bold text-muted">{{ $t('Цена') }}</label>
                                <div class="input-group mb-4">
                                    <input v-model="product.price" :readonly="isNot('administrator')" type="number" class="form-control">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ $t('грн') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <label class="font-weight-bold text-muted">Bitrix ID</label>
                                <input v-model="product.bitrix_id" :readonly="isNot('administrator')" type="number" class="form-control mb-4">
                            </div>
                        </div>

                        <hr>
                        <label class="font-weight-bold text-muted mb-3">{{ $t('Отношение товара к ТМ') }}</label>
                        <div v-for="item in trademarks" :key="item.id" class="d-flex justify-content-start align-items-center">
                            <toggle-button @change="setTrademark(item.id)"
                                           class="mr-4"
                                           :disabled="isNot('administrator')"
                                           :font-size="10"
                                           sync
                                           :value="isTrademarkChecked(item.id)"
                                           :width="50" :height="30"/>
                            <img :src="item.icon" style="width: 30px; height: 30px; object-fit: contain" class="border rounded m-2">
                            <div class="mx-4 font-weight-bold text-black-50 pb-2">{{ item.name }}</div>
                        </div>
                        <hr>
                        <label class="font-weight-bold text-muted mb-3">Доступность товара</label>
                        <div class="d-flex justify-content-start align-items-center">
                            <toggle-button v-model="product.active"
                                           :disabled="isNot('administrator')"
                                           :font-size="11"
                                           :value="product.active"
                                           sync
                                           :width="50" :height="30"/>
                            <div class="font-weight-bold text-muted pl-4 pb-2">
                                {{ product.active ? 'Доступен' : 'Не доступен' }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                        <div class="alert alert-secondary p-4 mt-4">
                            <div v-for="(image, index) in images"
                                 :key="getInputFileSrc(image)"
                                 class="position-relative d-inline-block m-2">
                                <img :src="getInputFileSrc(image)"
                                     style="width: 150px; height: 150px; object-fit: cover; border: 1px solid #ccc;"/>
                                <button @click="removeSelectedImage(index)"
                                        v-if="is('administrator')"
                                        class="btn btn-danger position-absolute btn-sm"
                                        style="bottom: 1rem; left: 50%; transform: translateX(-50%)">
                                    {{ $t('Удалить') }}
                                </button>
                            </div>
                            <div v-if="is('administrator')" class="py-4">
                                <label class="btn btn-primary">
                                    {{ $t('Выбрать изображения') }} <input ref="images" type="file" @change="selectImages" multiple hidden>
                                </label>
                                <div>
                                    <small class="text-muted d-block">
                                        {{ $t('Доступные форматы') }}: <strong>{{ allowTypes.map(item => item.split('/')[1]).join(", ") }}</strong>
                                    </small>
                                    <small class="text-muted d-block">
                                        {{ $t('Максимальный размер каждого файла') }}: <strong>{{ maxFileSize / 1000000 }} MB</strong>
                                    </small>

                                    <small class="text-muted">
                                        {{ $t('Максимальное количество файлов') }}: <strong>{{ maxFiles }}</strong>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-5">
                        <button v-if="product_id && is('administrator')" @click="updateProduct" class="btn btn-primary">
                            {{ $t('Сохранить изменения') }}
                        </button>
                        <button v-else-if="is('administrator')" @click="createProduct" class="btn btn-primary">
                            {{ $t('Добавить товар') }}
                        </button>
                        <button v-if="product_id && is('administrator')" @click="removeProduct" class="btn btn-danger d-inline-block ml-4">
                            {{ $t('Удалить товар') }}
                        </button>
                    </div>
                </div>
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import TariffNote from "@/components/TariffNote";
import FullHeightBlock from "@/components/FullHeightBlock";
import ProductService from "@/services/ProductService";
import {ToggleButton} from 'vue-js-toggle-button';
import {mapState} from "vuex";
import InputRange from "@/components/InputRange";

const Product = new ProductService();

export default {
    name: 'Product',
    components: {
        Navbar,
        TariffNote,
        FullHeightBlock,
        ToggleButton,
        InputRange
    },
    props: {
        product_id: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            allowTypes: ["image/jpeg", "image/png", "image/gif"],
            maxFileSize: 4000000,
            maxFiles: 10,
            product: {
                active: true,
                bitrix_id: 0,
                comment: "",
                description: "",
                name: "",
                price: 0,
                trademark: [],
                priority: 0
            },
            images: [],
        }
    },
    methods: {
        async createProduct() {
            let formData = new FormData();

            formData.append("name", this.product.name);
            formData.append("description", this.product.description);
            formData.append("comment", this.product.comment || "");
            formData.append("active", this.product.active ? "1" : "0");
            formData.append("price", this.product.price || "0");
            formData.append("bitrix_id", this.product.bitrix_id || "0");
            formData.append("priority", this.product.priority);

            this.images.forEach((image, index) => {
                formData.append(`images[${index}]`, image);
            });

            this.product.trademark.forEach((item, index) => {
                formData.append(`trademark[${index}]`, item);
            });

            this.$preloader.show();
            const {code, body} = await Product.create(formData);
            this.$preloader.hide();

            if (code !== 201) {
                this.$toast.error(body.message);
            } else {
                this.$toast.success(`Товар добавлен <a href="/product/${body.id}" class="btn btn-light ml-4">${this.$tc('Перейти к товару')}</a>`);
                Object.assign(this.$data, this.$options.data.apply(this));
            }
        },
        async updateProduct() {
            let formData = new FormData();
            formData.append("name", this.product.name);
            formData.append("description", this.product.description);
            formData.append("comment", this.product.comment || "");
            formData.append("active", this.product.active ? "1" : "0");
            formData.append("price", this.product.price || "0");
            formData.append("bitrix_id", this.product.bitrix_id || "0");
            formData.append("priority", this.product.priority);

            this.images.forEach((image, index) => {
                formData.append(`images[${index}]`, image);
            });

            this.product.trademark.forEach((item, index) => {
                formData.append(`trademark[${index}]`, item);
            });

            this.$preloader.show();
            const {code, body} = await Product.update(this.product_id, formData);
            this.$preloader.hide();

            if (code !== 200) {
                this.$toast.error(body.message);
            } else {
                this.$toast.success(this.$tc("Товар обновлен"));
            }
        },
        async removeProduct() {
            if (!confirm(this.$tc("Точно?"))) return;

            this.$preloader.show();
            const {code, body} = await Product.delete(this.product_id);
            this.$preloader.hide();

            if (code !== 200) {
                this.$toast.error(body.message);
            } else {
                this.$toast.success(this.$tc("Товар удален"));
                await this.$router.push({name: "Products"});
            }
        },
        removeSelectedImage(index) {
            this.images = this.images.filter((item, idx) => {
                return idx !== index
            });
        },
        selectImages(e) {
            let counter = 0;

            Array.from(e.target.files).forEach(file => {

                if (counter > this.maxFiles) {
                    this.$toast.error(`"${file.name}" - ${this.$tc('не загружен! Превышено количество файлов')}`);
                    return;
                } else if (this.allowTypes.indexOf(file.type) === -1) {
                    this.$toast.error(`"${file.name}" - ${this.$tc('не загружен! Некорректный формат файла')}`);
                } else if (file.size > this.maxFileSize) {
                    this.$toast.error(`"${file.name}" - ${this.$tc('не загружен! Превышен размер файла')}`);
                } else {
                    this.images.push(file);
                    counter++;
                }
            });

            this.$refs.images.value = null;
        },
        getInputFileSrc(file) {
            return URL.createObjectURL(file);
        },
        async getFileUrlToFileObject(image) {
            const response = await fetch(image);
            const blob = await response.blob();
            return new File([blob], image, {type: blob.type});
        },
        setTrademark(trademarkId) {
            if (this.product.trademark.indexOf(trademarkId) > -1) {
                this.product.trademark = this.product.trademark.filter(item => item != trademarkId);
            } else {
                this.product.trademark.push(trademarkId);
            }
        },
        isTrademarkChecked(trademarkId) {
            return this.product.trademark?.indexOf(trademarkId) > -1;
        }
    },
    computed: {
        ...mapState({
            trademarks: store => store.trademarks
        })
    },
    async mounted() {
        document.title = this.product_id ? this.$tc('Товар') : this.$tc('Добавление товара');

        if (!this.product_id) {
            return;
        }

        this.$preloader.show();
        const {body, code} = await Product.get(this.product_id);
        this.$preloader.hide();

        if (code === 200) {
            this.product = body;

            this.product.images_links?.forEach(image => {
                this.getFileUrlToFileObject(image).then(data => {
                    this.images.push(data);
                });
            });
        }
    }
}
</script>

<style lang="less">
</style>
