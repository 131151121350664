<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Добавление шаблона') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Сообщение') }} <span class="text-danger">*</span></label>
                        <textarea v-model="message" class="form-control" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Комментарий') }}</label>
                        <textarea v-model="comment" class="form-control" rows="3"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create" :disabled="processing" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import SmsSampleService from "../services/SmsSampleService";

const SmsSample = new SmsSampleService();

export default {
    name: "CreateSmsSampleModal",
    data() {
        return {
            visible: false,
            processing: false,
            message: "",
            comment: "",
        }
    },
    methods: {
        async create() {
            this.processing = true;
            const {code, body} = await SmsSample.create(this.$data);
            this.processing = false;

            if (code === 201) {
                this.$toast.success(this.$t('SMS шаблон добавлен'));
                this.$emit("created", body);
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onShowModal() {
            this.visible = true;
        },
        onHideBsModal() {
            this.visible = false;
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.CREATE_SMS_SAMPLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.CREATE_SMS_SAMPLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    }
}
</script>

<style scoped>

</style>
