<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark app-navbar">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                :aria-label="$t('Toggle navigation')">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
            <slot></slot>
            <div class="navbar-text ml-auto mr-4">
                <button @click="$router.currentRoute.name !== 'Profile' && $router.push({name: 'Profile'})"
                        class="btn btn-sm btn-secondary d-flex align-items-center py-1 px-2">
                    <i class="fas fa-user-circle fa-2x pr-2"></i>
                    <div>
                        <small class="d-block font-weight-bold text-light" style="line-height: 1.1">{{ $store.state.user.email }}</small>
                        <div>
                            <small class="text-capitalize text-warning" style="line-height: 1.1">
                                {{ userRoleName }}
                            </small>
                            <small v-if="userDepartmentName" class="text-capitalize text-warning" style="line-height: 1.1">
                                ({{ userDepartmentName }})
                            </small>
                        </div>
                    </div>
                </button>
            </div>
            <div class="navbar-text">
                <button @click="logout" class="btn btn-danger"><i class="fas fa-fw fa-sign-out-alt"></i>{{ $t('Выйти') }}</button>
            </div>
        </div>
    </nav>
</template>

<script>
import Logo from "@/components/Logo";
import AuthService from "@/services/AuthService";
import {mutationTypes} from "@/store";

const Auth = new AuthService();

export default {
    name: "Navbar",
    components: {
        Logo
    },
    methods: {
        async logout() {
            this.$preloader.show();
            await Auth.logout();
            this.$preloader.hide();
            this.$store.commit(mutationTypes.RESET_STATE);
            await this.$router.push({name: 'Auth'});
        }
    }
}
</script>

<style lang="less" scoped>
.app-navbar {
    position: relative;
    z-index: 20;
}
</style>
