<template>
    <div class="row h-100" style="min-width: 1024px; overflow-x: auto; overflow-y: hidden;">
        <h5 class="mb-4 ml-3">{{ $t('Найдено совпадений') }} <span class="text-black-50"> - {{ results.length }}</span>
        </h5>
        <vue-custom-scrollbar v-show="results.length > 0" class="col-12 h-100" :settings="scrollSettings">
            <table class="table table-bordered bg-light mb-5 mx-auto">
                <thead class="thead-dark">
                <tr>
                    <th scope="col">{{ $t('EBS ID') }}</th>
                    <th scope="col">{{ $t('Торговая марка') }}</th>
                    <th scope="col">{{ $t('Область') }}</th>
                    <th scope="col">{{ $t('Район') }}</th>
                    <th scope="col">{{ $t('Населенный пункт') }}</th>
                    <th scope="col">{{ $t('Действия') }}</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(item , index) in results" :key="index">
                    <td scope="row">{{ item.id }}</td>
                    <td>
                        <img :src="trademarkData(item).icon"
                             style="width: 25px; height: 25px; object-fit: contain; margin-right: .75rem;"/>
                        <strong class="text-black-50">{{ trademarkData(item).name }}</strong>
                    </td>
                    <td>{{ item.region_name }}</td>
                    <td>{{ item.area_name }}</td>
                    <td>
                        <a href="#" @click.prevent="$router.push({name: 'Locality', params: {locality_id: item.id} })"
                           class="font-weight-bold">
                            {{ item.locality_name }}
                        </a>
                    </td>
                    <td style="width: 200px;">
                        <button v-if="!$store.state.shiftKeyPressed"
                                @click="$router.push({name: 'Locality', params: {locality_id: item.id} })"
                                class="btn btn-sm btn-block btn-primary">
                            {{ $t('Тарифы') }}
                            <i class="ml-2 fas fa-chevron-right"></i>
                        </button>

                        <button v-else @click="emit('locality', item)"
                                class="btn btn-sm btn-block btn-primary">
                            <i class="fas fa-cog ml-2"></i>
                        </button>
                    </td>
                </tr>
                </tbody>


            </table>
        </vue-custom-scrollbar>

        <div v-show="results.length === 0" class="col-12 text-center">
            <span class="display-4 text-black-50 center-page">{{ $t('Ничего не нашлось ') }} :(</span>
        </div>
    </div>
</template>

<script>

import _ from "underscore";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import Helper from "@/Helper";
import MockFactory from "@/classess/MockFactory";
import {EventBus, Events} from "@/EventBus";
import {mapState} from "vuex";

export default {
    name: "LocalitiesSerp",
    props: ["localities", "search"],
    components: {
        vueCustomScrollbar
    },
    data() {
        return {
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: false
            }
        }
    },
    methods: {
        trademarkData(item) {
            return _.find(this.trademarks, trademark => {
                return item.trademark_id === trademark.id;
            }) || {
                name: this.$t('Нет данных'),
                icon: require("../assets/question.png")
            };
        },
        emit(type, item) {
            //
        },
    },
    computed: {
        ...mapState({
            trademarks: state => state.trademarks,
        }),
        results() {
            const query = Helper.keyboardConverter(this.search.toLowerCase());
            const filtered = _.filter(this.localities, item => {
                return item.locality_search_name.toLowerCase().includes(query);
            });

            return _.chain(filtered)
                .sortBy("locality_name")
                .take(20)
                .value();
        },
    }
}
</script>

<style lang="less" scoped>

.items {
    height: calc(100vh - 250px);
    overflow-y: auto;
    border-right: 1px solid #d0d0d0;
    padding-right: 2rem;

    .item {
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 48px;
        padding: .7rem 1rem;
        background: #fff;
        margin-bottom: .5rem;
        border-radius: .5rem;

        &:hover {
            background: #f5f5f5;
        }

        &.active {
            background: #3495E3;
            color: #fff;

            i {
                color: #fff;
            }

            img {
                background: #fff;
                border-radius: .5rem;
            }
        }

        i {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            color: #D0D0D0;
        }

        img {
            padding: .2rem;
            width: 28px;
            height: 28px;
            margin-right: .5rem;
        }
    }
}

.center-page {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>
