<template>
    <div ref="block" class="to-bottom">
        <slot></slot>
    </div>
</template>

<script>
import {EventBus, Events} from "@/EventBus";

export default {
    name: "FullHeightBlock",
    props: {
        offsetBottom: {
            type: Number,
            required: false,
            default: 15
        }
    },
    data() {
        return {
            prevHeight: null,
        }
    },
    methods: {
        setHeight() {
            this.$nextTick(() => {
                const isMobile = window.outerWidth < 600;
                const rect = this.$refs?.block?.getBoundingClientRect();
                const height = Math.round(window.innerHeight - rect.top) - this.offsetBottom;

                if (this.prevHeight !== height && !isMobile) {
                    this.prevHeight = height;
                    this.$refs.block.style.height = height + "px";
                }
            });
        }
    },
    mounted() {
        EventBus.$on(Events.DOM_CHANGE, this.setHeight);
        window.addEventListener("resize", this.setHeight);
        window.addEventListener("click", this.setHeight);
        this.setHeight();
    },
    beforeDestroy() {
        EventBus.$off(Events.DOM_CHANGE, this.setHeight);
        window.removeEventListener("resize", this.setHeight);
        window.removeEventListener("click", this.setHeight);
    }
}
</script>

<style lang="less" scoped>

.to-bottom {
    position: relative;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

</style>
