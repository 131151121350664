<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-4">
                    <h4 class="text-light mb-0">
                        {{ $t('Заметки НП') }}
                        <button v-if="is('administrator')"
                                class="ml-3 btn btn-primary dropdown-toggle position-relative"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t('Добавить') }}
                            <div class="dropdown-menu">
                                <a @click.stop="openCreateLocalityNoteModal" class="dropdown-item font-weight-bold"
                                   href="#">
                                    <i class="fas fa-plus fa-fw"></i>
                                    {{ $t('Для одного НП') }}
                                </a>
                                <div class="dropdown-divider"></div>
                                <a @click.stop="openCreateLocalityGroupNoteModal" class="dropdown-item font-weight-bold"
                                   href="#">
                                    <i class="fas fa-tasks fa-fw"></i>
                                    {{ $t('Для нескольких НП') }}
                                </a>
                            </div>
                        </button>
                    </h4>
                </div>
                <div class="col-8">
                    <div class="input-group w-100">
                        <input v-model="searchQuery" type="text" class="form-control"
                               placeholder="Поиск по заметкам НП">
                        <div v-if="searchQuery.length" @click="searchQuery = ''" class="input-group-append">
                            <button class="btn btn-primary" type="button">
                                <i class="fas fa-times px-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>

        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">

                <ul class="app-nav-tabs nav nav-tabs mb-3 position-relative">
                    <li @click.stop="tab = 0" class="nav-item" :class="{ 'active' : tab === 0 }">
                        <a class="nav-link" href="#">{{ $t('Заметки НП') }}</a>
                    </li>
                    <li @click.stop="tab = 1" class="nav-item" :class="{ 'active' : tab === 1 }">
                        <a class="nav-link" href="#">{{ $t('Групповые заметки НП') }}</a>
                    </li>
                    <DepartmentTabs class="position-absolute" style="right: 0"/>
                </ul>

                <div v-if="tab === 0">
                    <div v-if="notes.length || is_init" :key="`${tab}-${currentDepartmentSlug}`" class="row">
                        <div class="col-12">
                            <vue-good-table
                                :columns="columns"
                                :rows="notes"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="total_notes"
                                :isLoading.sync="isLoading"
                                @on-search="onSearch"
                                :search-options="{
                            enabled: true,
                            externalQuery: searchQuery,
                        }"
                                :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field === 'locality'">
                                        <a href="#"
                                           @click.prevent="$router.push('/locality/' + props.row.locality_id)"
                                           class="d-block font-weight-bold text-primary">{{
                                                localityData(props.row.locality_id).locality_name || '-'
                                            }}</a>
                                        <small class="d-block text-black-50">{{
                                                localityData(props.row.locality_id).area_name
                                            }}</small>
                                        <small class="d-block text-black-50 font-weight-bold">{{
                                                localityData(props.row.locality_id).region_name
                                            }}</small>
                                    </div>
                                    <div v-else-if="props.column.field === 'note'">
                                        <LocalityNote :controls="is('administrator')"
                                                      @deleted="loadNotes(limit, offset)"
                                                      :max-width="1500" :note="props.row"></LocalityNote>
                                    </div>
                                    <div v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                    <div v-else class="centered">
                        <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                    </div>
                </div>

                <div v-else>
                    <div v-if="group_notes.length || is_init" :key="`${tab}-${currentDepartmentSlug}`" class="row">
                        <div class="col-12">
                            <vue-good-table
                                :columns="columns"
                                :rows="group_notes"
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="total_group_notes"
                                :isLoading.sync="isLoading"
                                @on-search="onSearch"
                                :search-options="{
                            enabled: true,
                            externalQuery: searchQuery,
                        }"
                                :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field === 'locality'">
                                        <a href="#"
                                           @click.stop="openShowLocalitiesModal(props.row.locality_id)"
                                           class="d-block font-weight-bold text-primary">
                                            {{ props.row.locality_id.length }} НП
                                        </a>
                                    </div>
                                    <div v-else-if="props.column.field === 'note'">
                                        <LocalityGroupNote :controls="is('administrator')"
                                                           @deleted="loadGroupNotes(limit, offset)"
                                                           :max-width="1500" :note="props.row"></LocalityGroupNote>
                                    </div>
                                    <div v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                    <div v-else class="centered">
                        <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                    </div>
                </div>

                <CreateLocalityNoteModal @created="loadNotes(limit, offset, searchQuery)"/>
                <UpdateLocalityNoteModal @updated="loadNotes(limit, offset, searchQuery)"/>
                <CreateLocalityGroupNoteModal @created="loadGroupNotes"/>
                <UpdateLocalityGroupNoteModal @updated="loadGroupNotes"/>
            </div>
        </FullHeightBlock>

    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import _ from "underscore";
import LocalityNoteService from "@/services/LocalityNoteService";
import CreateLocalityNoteModal from "@/modals/CreateLocalityNoteModal";
import {EventBus, Events} from "@/EventBus";
import LocalityNote from "@/components/LocalityNote";
import UpdateLocalityNoteModal from "@/modals/UpdateLocalityNoteModal";
import FullHeightBlock from "@/components/FullHeightBlock";
import CreateLocalityGroupNoteModal from "@/modals/CreateLocalityGroupNoteModal";
import UpdateLocalityGroupNoteModal from "@/modals/UpdateLocalityGroupNoteModal";
import LocalityGroupNoteService from "@/services/LocalityGroupNoteService";
import LocalityGroupNote from "@/components/LocalityGroupNote";
import moment from "moment-mini";
import DepartmentTabs from "@/components/DepartmentTabs.vue";
import {mapState} from "vuex";

const LocalityNotes = new LocalityNoteService();
const LocalityGroupNotes = new LocalityGroupNoteService();

export default {
    name: 'LocalityNotes',
    components: {
        DepartmentTabs,
        Navbar,
        VueGoodTable,
        CreateLocalityGroupNoteModal,
        UpdateLocalityGroupNoteModal,
        CreateLocalityNoteModal,
        UpdateLocalityNoteModal,
        LocalityNote,
        FullHeightBlock,
        LocalityGroupNote
    },
    data() {
        return {
            tab: 0,
            is_init: false,
            notes: [],
            total_notes: 0,
            group_notes: [],
            total_group_notes: 0,
            limit: 10,
            offset: 0,
            isLoading: false,
            searchQuery: ""
        }
    },
    methods: {
        async onSearch({searchTerm}) {
            this.searchQuery = searchTerm;
            this.limit = 10;
            this.offset = 0;

            if (!searchTerm) {
                await this.loadNotes(this.limit, this.offset, this.searchQuery);
                await this.loadGroupNotes(this.limit, this.offset, this.searchQuery);
                return;
            }

            if (this.tab === 0) {
                await this.loadNotes(this.limit, this.offset, this.searchQuery);
            } else {
                await this.loadGroupNotes(this.limit, this.offset, this.searchQuery);
            }
        },
        openCreateLocalityNoteModal() {
            EventBus.$emit(Events.CREATE_LOCALITY_NOTE_MODAL_OPEN);
        },
        openCreateLocalityGroupNoteModal() {
            EventBus.$emit(Events.CREATE_LOCALITY_GROUP_NOTE_MODAL_OPEN);
        },
        openUpdateLocalityNoteModal(note) {
            EventBus.$emit(Events.UPDATE_LOCALITY_NOTE_MODAL_OPEN, note);
        },
        localityData(locality_id) {
            return _.find(this.$store.state.localities, item => {
                return item.id == locality_id;
            }) || {};
        },
        async loadNotes(limit = 10, offset = 0, searchQuery = "") {
            this.isLoading = true;
            const {code, body} = await LocalityNotes.fetch(limit, offset, searchQuery);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.notes = body.content;
                this.total_notes = body.total
            }
        },
        async loadGroupNotes(limit = 10, offset = 0, searchQuery = "") {
            this.isLoading = true;
            const {code, body} = await LocalityGroupNotes.fetch(limit, offset, searchQuery);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.group_notes = body.content;
                this.total_group_notes = body.total
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;

            if (this.tab === 0) {
                await this.loadNotes(this.limit, this.offset, this.searchQuery);
            } else {
                await this.loadGroupNotes(this.limit, this.offset, this.searchQuery);
            }
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;

            if (this.tab === 0) {
                await this.loadNotes(this.limit, this.offset, this.searchQuery);
            } else {
                await this.loadGroupNotes(this.limit, this.offset, this.searchQuery);
            }
        },
        openShowLocalitiesModal(locality_id) {
            EventBus.$emit(Events.SHOW_LOCALITIES_MODAL, locality_id);
        },
    },
    computed: {
        ...mapState({
            currentDepartment: state => state.currentDepartment
        }),
        currentDepartmentSlug() {
            return this.currentDepartment?.slug || '-'
        },
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('НП'),
                    field: 'locality',
                    sortable: false,
                    width: '300px',
                },
                {
                    label: this.$tc('Заметка'),
                    field: 'note',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'updated_at',
                    width: '250px',
                    sortable: false,
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                }
            ];
        },
    },
    watch: {
        currentDepartment: {
            deep: true,
            async handler() {
                this.limit = 10;
                this.offset = 0;
                if (this.tab === 0) {
                    await this.loadNotes(this.limit, this.offset, this.searchQuery);
                } else {
                    await this.loadGroupNotes(this.limit, this.offset, this.searchQuery);
                }
            }
        },
        async tab(val) {
            this.limit = 10;
            this.offset = 0;
            if (val === 0) {
                await this.loadNotes(this.limit, this.offset, this.searchQuery);
            } else {
                await this.loadGroupNotes(this.limit, this.offset, this.searchQuery);
            }
        }
    },
    async mounted() {
        document.title = this.$tc("Заметки НП");
        this.$preloader.show();
        await this.loadNotes(this.limit, this.offset);
        this.$preloader.hide();
        this.is_init = true;
    }
}
</script>

<style lang="less">
</style>
