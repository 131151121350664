import Vue from "vue";
import Vuex from "vuex";
import StorageAdapter from "@/classess/StorageAdapter";
import i18n from "../i18n";

const storage = new StorageAdapter();

Vue.use(Vuex);

export const mutationTypes = {
    SET_BEARER_TOKEN: "SET_BEARER_TOKEN",
    SET_LOCALITIES: "SET_LOCALITIES",
    SET_USER: "SET_USER",
    SET_COUNT_UNREAD_MESSAGES: "SET_COUNT_UNREAD_MESSAGES",
    SET_SHIFT_KEY_PRESSED: "SET_SHIFT_KEY_PRESSED",
    SET_SIDEBAR_COLLAPSED: "SET_SIDEBAR_COLLAPSED",
    SET_LOCALITY_NOTES_HISTORY: "SET_LOCALITY_NOTES_HISTORY",
    SET_TARIFF_NOTES_HISTORY: "SET_TARIFF_NOTES_HISTORY",
    SET_ROLES_LIST: "SET_ROLES_LIST",
    SET_APP_LANGUAGE: "SET_APP_LANGUAGE",
    SET_AFTER_AUTH_PATH: "SET_AFTER_AUTH_PATH",
    SET_DEPARTMENTS: "SET_DEPARTMENTS",
    SET_CURRENT_DEPARTMENT: "SET_CURRENT_DEPARTMENT",
    RESET_STATE: "RESET_STATE",
};


const getDefaultState = () => {
    return {
        helpLink: "https://bitrix.freeutg.com/company/personal/user/99/",
        bearerToken: storage.get("bearerToken"),
        appLanguage: storage.get("appLanguage", "ru"),
        localities: [],
        rolesList: [],
        groupsList: {
            1: i18n.t("Многоэтажный сектор"),
            2: i18n.t("Частный сектор"),
            3: i18n.t("Радио"),
            4: i18n.t("КТВ / ОТТ Вселенная"),
            60: i18n.t("Архивные"),
        },
        user: {},
        trademarks: [
            {
                id: 0,
                name: "Empty Trademark",
                icon: "https://via.placeholder.com/28x28?text=E",
            },
            {
                id: 1,
                name: "Prosto",
                icon: require("../assets/prosto.png"),
            },
            {
                id: 2,
                name: "Crystal Telecom",
                icon: require("../assets/crystal.png"),
            },
        ],
        countUnreadMessages: 0,
        shiftKeyPressed: false,
        sidebarCollapsed: storage.get("sidebarCollapsed", false),
        localityNotesHistory: storage.get("localityNotesHistory", []),
        tariffNotesHistory: storage.get("tariffNotesHistory", []),
        afterAuthPath: storage.get("afterAuthPath", null),
        departments: [],
        currentDepartment: storage.get("currentDepartment", "not_set"),
        visibleDepartmentsSlug: [
            "cc", // контакт центр
            "tm", // телемаркетинг
            "su" // суперадмины
        ]
    }
}


export default new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        [mutationTypes.SET_AFTER_AUTH_PATH](state, payload) {
            state.afterAuthPath = payload;
            storage.set("afterAuthPath", payload);
        },
        [mutationTypes.SET_APP_LANGUAGE](state, payload) {
            state.appLanguage = payload;
            storage.set("appLanguage", payload);
        },
        [mutationTypes.SET_BEARER_TOKEN](state, payload) {
            state.bearerToken = payload;
            storage.set("bearerToken", payload);
        },
        [mutationTypes.SET_LOCALITIES](state, payload) {
            state.localities = payload;
        },
        [mutationTypes.SET_USER](state, payload) {
            state.user = payload;
        },
        [mutationTypes.SET_COUNT_UNREAD_MESSAGES](state, payload) {
            state.countUnreadMessages = payload;
        },
        [mutationTypes.SET_SHIFT_KEY_PRESSED](state, payload) {
            state.shiftKeyPressed = payload;
        },
        [mutationTypes.SET_SIDEBAR_COLLAPSED](state, payload) {
            state.sidebarCollapsed = payload;
            storage.set("sidebarCollapsed", payload);
        },
        [mutationTypes.SET_LOCALITY_NOTES_HISTORY](state, payload) {
            state.localityNotesHistory = [
                payload,
                ...state.localityNotesHistory,
            ];
            state.localityNotesHistory = state.localityNotesHistory.slice(
                0,
                10
            );
            storage.set("localityNotesHistory", state.localityNotesHistory);
        },
        [mutationTypes.SET_TARIFF_NOTES_HISTORY](state, payload) {
            state.tariffNotesHistory = [payload, ...state.tariffNotesHistory];
            state.tariffNotesHistory = state.tariffNotesHistory.slice(0, 10);
            storage.set("tariffNotesHistory", state.tariffNotesHistory);
        },
        [mutationTypes.SET_ROLES_LIST](state, payload) {
            state.rolesList = payload;
        },
        [mutationTypes.SET_DEPARTMENTS](state, payload) {
            state.departments = payload;
        },
        [mutationTypes.SET_CURRENT_DEPARTMENT](state, payload) {
            state.currentDepartment = payload;
            storage.set("currentDepartment", payload);
        },
        [mutationTypes.RESET_STATE](state) {
            storage.flush();
            Object.assign(state, getDefaultState());
        }
    },
    getters: {
        getCurrentDepartmentId(state) {
            return state.currentDepartment?.id || null;
        },
        getVisibleDepartments(state) {
            return state.departments.filter(item => state.visibleDepartmentsSlug.includes(item.slug))
        }
    },
    actions: {},
    modules: {},
});
