import AbstractService from "@/services/AbstractService";

export default class UserActivitiesService extends AbstractService {

    constructor() {
        super();
    }

    fetch({limit = 10, offset = 0, search_query = null, start_date = null, end_date = null, user_id = null, department_id = null}) {
        return this.axios.get(`/user_activity`, {
            params: {limit, offset, search_query, start_date, end_date, user_id, department_id}
        });
    }

    fetchUsers({department_id = null}) {
        return this.axios.get(`/user_activity/all-users`, {
            params: {department_id}
        });
    }
    fetchDepartments() {
        return this.axios.get(`/user_activity/all-departments`);
    }

    fetchUserEvents({search_query = null, start_date = null, end_date = null, user_id = null, department_id = null}) {
        return this.axios.get(`/user_activity/events`, {
            params: {search_query, start_date, end_date, user_id, department_id}
        });
    }

    fetchDepartmentsCharts({search_query = null, start_date = null, end_date = null, user_id = null, department_id = null}) {
        return this.axios.get(`/user_activity/events/department`, {
            params: {search_query, start_date, end_date, user_id, department_id}
        });
    }

    fetchUsersCharts({search_query = null, start_date = null, end_date = null, user_id = null, department_id = null}) {
        return this.axios.get(`/user_activity/events/user`, {
            params: {search_query, start_date, end_date, user_id, department_id}
        });
    }
}
