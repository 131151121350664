var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"text-light mb-0"},[_vm._v(_vm._s(_vm.$t('Конкуренты')))])])])]),_c('div',{staticClass:"container-fluid pt-4"},[(_vm.opponents.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.opponents,"mode":"remote","totalRows":_vm.total_opponents,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: true,
                        perPageDropdown: [7, 20, 50, 100],
                        dropdownAllowAll: false,
                        nextLabel: _vm.$t('туда'),
                        prevLabel: _vm.$t('сюда'),
                        ofLabel: _vm.$t('из'),
                        rowsPerPageLabel: _vm.$t('Записей на страницу'),
                    }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'locality')?_c('div',[_c('small',{staticClass:"d-block font-weight-bold text-primary"},[_vm._v(_vm._s(_vm.localityData(props.row.locality_id).locality_name))]),_c('small',{staticClass:"d-block text-black-50"},[_vm._v(_vm._s(_vm.localityData(props.row.locality_id).area_name))]),_c('small',{staticClass:"d-block text-black-50 font-weight-bold"},[_vm._v(_vm._s(_vm.localityData(props.row.locality_id).region_name))])]):(props.column.field === 'group')?_c('div',[_c('small',{},[_vm._v(_vm._s(_vm.$store.state.groupsList[props.row.group_id]))])]):(props.column.field === 'text')?_c('div',[_c('small',{domProps:{"innerHTML":_vm._s(_vm.convertLinks(props.row.text) || _vm.prepText)}})]):(props.column.field === 'date')?_c('div',[_c('small',{},[_vm._v(_vm._s(_vm._f("formatDate")(props.row.created_at)))])]):(props.column.field === 'action')?_c('div',[_c('div',{staticClass:"btn-group btn-block"},[_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.$router.push({name: 'Locality', params: {locality_id : props.row.locality_id}})}}},[_vm._v(" "+_vm._s(_vm.$t('На страницу НП'))+" "),_c('i',{staticClass:"fas fa-arrow-right fa-fw"})])])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,242010192)})],1)]):_c('div',{staticClass:"centered"},[_c('div',{staticClass:"display-4 text-black-50"},[_vm._v(_vm._s(_vm.$t('Тут пока ничего нет')))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }