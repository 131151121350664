import AbstractService from "@/services/AbstractService";

export default class LocalityService extends AbstractService {

    constructor() {
        super();
    }

    fetch(do_not_track = null) {
        return this.axios.get('/locality', {
            params : {do_not_track}
        });
    }

}
