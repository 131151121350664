<template>
    <div>
        <label class="font-weight-bold">{{ $t('Телефон') }}</label>
        <div class="card p-4 bg-light">
            <div :class="{ 'invisible' : preloader }">
                <div v-for="(item, index) in oldPhones" class="input-group mb-4">
                    <div class="input-group-prepend">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ types[oldPhones[index].type] || $t('Тип') }}
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="(name, type) in types"
                               :key="type"
                               @click.prevent="oldPhones[index].type = type" href="#">
                                {{ name }}
                            </a>
                        </div>
                    </div>
                    <input v-model="oldPhones[index].phone" type="text" class="form-control">
                    <div class="input-group-append">
                        <button class="btn btn-success"
                                v-if="oldPhones[index].phone !== phones[index].phone || oldPhones[index].type !== phones[index].type"
                                @click="update(oldPhones[index])" type="button">
                            <i class="fas fa-check"></i>
                        </button>
                        <button class="btn btn-danger"
                                v-else
                                @click="remove(oldPhones[index])" type="button">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ types[newPhone.type] || 'Тип' }}
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="(name, type) in types"
                               :key="type"
                               @click.prevent="newPhone.type = type" href="#">
                                {{ name }}
                            </a>
                        </div>
                    </div>
                    <input type="text" v-model="newPhone.phone" class="form-control">
                    <div class="input-group-append">
                        <button :disabled="!(newPhone.type && newPhone.phone)" class="btn btn-success" @click="create" type="button">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="preloader" class="position-absolute centered">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">{{ $t('Загрузка...') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import {cloneDeep} from "lodash";
import {userPhoneTypes} from "@/views/Users"

export default {
    name: "UpdateUserModalPhones",
    props: {
        phones: Array,
        preloader: Boolean
    },
    data() {
        return {
            types: userPhoneTypes,
            oldPhones: [],
            newPhone: {
                type: null,
                phone: null
            }
        }
    },
    methods: {
        create() {
            this.$emit("create", this.newPhone);
            this.newPhone = {
                type: null,
                phone: null
            };
        },
        update(phone) {
            this.$emit('update', phone);
        },
        remove(phone) {
            this.$emit('remove', phone);
        }
    },
    watch: {
        phones: {
            immediate: true,
            deep: true,
            handler(val) {
                this.oldPhones = cloneDeep(val);
            }
        }

    }
}
</script>

<style lang="less">

</style>
