var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar',[_c('div',{staticClass:"row w-75"},[_c('div',{staticClass:"col-4"},[_c('h4',{staticClass:"text-light mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Скрипты'))+" "),(_vm.is('administrator'))?_c('button',{staticClass:"ml-3 btn btn-primary",on:{"click":function($event){return _vm.$router.push('/script')}}},[_vm._v(" "+_vm._s(_vm.$t('Добавить'))+" ")]):_vm._e()])])])]),_c('FullHeightBlock',{staticClass:"custom-scroll"},[_c('div',{staticClass:"container-fluid py-4"},[(_vm.scripts.length || _vm.is_init)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.scripts,"mode":"remote","totalRows":_vm.total_scripts,"isLoading":_vm.isLoading,"pagination-options":{
                        enabled: true,
                        perPageDropdown: [10, 20, 50, 100],
                        dropdownAllowAll: false,
                        nextLabel: _vm.$t('туда'),
                        prevLabel: _vm.$t('сюда'),
                        ofLabel: _vm.$t('из'),
                        rowsPerPageLabel: _vm.$t('Записей на страницу'),
                    }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('div',[_c('a',{staticClass:"d-block font-weight-bold text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/script/' + props.row.id)}}},[_vm._v(_vm._s(_vm.stringLimit(props.row.name)))])]):(props.column.field === 'active')?_c('div',[(props.row.active)?_c('i',{staticClass:"fas fa-check-circle text-success"}):_c('i',{staticClass:"fas fa-times-circle text-danger"})]):(props.column.field === 'trademark')?_c('div',_vm._l((props.row.trademark),function(item){return _c('img',{key:item,staticClass:"d-inline ml-3 border rounded",staticStyle:{"width":"30px","height":"30px","object-fit":"contain"},attrs:{"src":_vm.trademarks.find(i => i.id == item).icon}})}),0):(props.column.field === 'localities')?_c('div',[(props.row.localities.length)?_c('a',{staticClass:"font-weight-bold",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showLocalitiesModal(props.row)}}},[_vm._v(_vm._s(props.row.localities.length)+" "+_vm._s(_vm.$t('НП')))]):_c('span',[_vm._v(_vm._s(_vm.$t('Все')))])]):_c('div',[_vm._v(" "+_vm._s(_vm.stringLimit(props.formattedRow[props.column.field]) || '-')+" ")])]}}],null,false,3833799635)})],1)]):_c('div',{staticClass:"centered"},[_c('div',{staticClass:"display-4 text-black-50 mb-4"},[_vm._v(_vm._s(_vm.$t('Тут пока ничего нет')))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }