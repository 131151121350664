<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Редактирование типа пользвателя') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Имя') }} <span class="text-danger">*</span></label>
                        <input v-model="name" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Slug') }} <span class="text-danger">*</span></label>
                        <input v-model="slug" readonly type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Комментарий') }} <span
                            class="text-danger">*</span></label>
                        <textarea v-model="comment" rows="5" class="form-control"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="update" :disabled="processing" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import UserRoleService from "@/services/UserRoleService";

const UserRole = new UserRoleService()

export default {
    name: "UpdateUserRoleModal",
    data() {
        return {
            id: null,
            visible: false,
            processing: false,
            name: "",
            slug: "",
            comment: "",
        }
    },
    methods: {
        async update() {
            this.processing = true;
            const {code, body} = await UserRole.update(this.$data);
            this.processing = false;

            if (code === 200) {
                this.$toast.success(this.$t('Тип пользователя обновлен'));
                this.$emit("updated", body);
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data());
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal(role) {
            this.visible = true;
            this.id = role.id;
            this.slug = role.slug;
            this.name = role.name;
            this.comment = role.comment;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.UPDATE_USER_ROLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.UPDATE_USER_ROLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style scoped>

</style>
