<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Типы пользователей') }}
                        <button @click="create()" class="ml-3 btn btn-primary">{{ $t('Добавить') }}</button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid pt-4">
            <div v-if="roles.length" class="row">
                <div class="col-12">
                    <vue-good-table
                        :columns="columns"
                        :rows="roles"
                        :pagination-options="{
                            enabled: true,
                            perPage: 10,
                            perPageDropdown: [5, 10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                        <template slot="table-row" slot-scope="props">
                            <div class="font-weight-bold text-black-50" v-if="props.column.field === 'name'">
                                {{ props.row.name }}
                            </div>
                            <div v-else-if="props.column.field === 'slug'">
                                {{ props.row.slug }}
                            </div>
                            <div v-else-if="props.column.field === 'users_count'" class="text-primary">
                                {{ props.row.users_count }}
                            </div>
                            <div v-else-if="props.column.field === 'action'">
                                <div class="btn-group btn-block">
                                    <button @click="update(props.row)" class="btn btn-sm btn-outline-primary">
                                        <i class="fas fa-user-edit fa-fw"></i>
                                        {{ $t('Править') }}
                                    </button>
                                    <button v-if="props.row.users_count === 0" @click="destroy(props.row.id)" class="btn btn-sm btn-outline-danger">
                                        <i class="fas fa-user-times fa-fw"></i>
                                        {{ $t('Удалить') }}
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </div>
            <div v-else class="centered">
                <div class="display-4 text-black-50">Тут пока ничего нет</div>
            </div>
        </div>
        <CreateUserRoleModal @created="fetch(false)"/>
        <UpdateUserRoleModal @updated="fetch(false)"/>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import {EventBus, Events} from "@/EventBus";
import UserRoleService from "@/services/UserRoleService";
import CreateUserRoleModal from "@/modals/CreateUserRoleModal";
import UpdateUserRoleModal from "@/modals/UpdateUserRoleModal";
import {mutationTypes} from "@/store";

const UserRole = new UserRoleService();

export default {
    name: 'UsersRoles',
    components: {
        Navbar,
        VueGoodTable,
        CreateUserRoleModal,
        UpdateUserRoleModal
    },
    data() {
        return {
            roles: [],
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    type: 'number'
                },
                {
                    label: this.$tc('Тип'),
                    field: 'name',
                },
                {
                    label: 'Slug',
                    field: 'slug',
                },
                {
                    label: this.$tc('Комментарий'),
                    field: 'comment',
                },
                {
                    label: this.$tc('Пользователей'),
                    field: 'users_count',
                },
                {
                    label: this.$tc('Действия'),
                    field: 'action',
                },
            ],
        }
    },
    methods: {
        create() {
            EventBus.$emit(Events.CREATE_USER_ROLE_MODAL_OPEN);
        },
        update(user) {
            EventBus.$emit(Events.UPDATE_USER_ROLE_MODAL_OPEN, user);
        },
        async destroy(id) {
            if (!confirm(this.$tc("Удалить тип пользователя?"))) {
                return;
            }

            EventBus.$emit(Events.SHOW_PRELOADER);
            const {code, body} = await UserRole.delete(id);
            EventBus.$emit(Events.HIDE_PRELOADER);

            if (code === 200) {
                await this.fetch();
                this.$toast.success(this.$tc('Тип удален'));
            } else {
                this.$toast.error(body.message);
            }
        },
        async fetch(preloader = true) {
            preloader && this.$preloader.show();
            const {code, body} = await UserRole.fetch();
            preloader && this.$preloader.hide();

            if (code === 401) {
                await this.$router.push({name: 'Auth'})
            } else {
                this.roles = body;
                this.$store.commit(mutationTypes.SET_ROLES_LIST, body);
            }
        }
    },
    async mounted() {
        document.title = this.$tc("Типы пользователей");
        await this.fetch();
    }
}
</script>
