import AbstractService from "@/services/AbstractService";

export default class UserPhoneService extends AbstractService {

    constructor() {
        super();
    }

    get(id) {
        return this.axios.get(`/user_phone/${id}`);
    }

    create({user_id, type, phone}) {
        return this.axios.post(`/user_phone/${user_id}`, {type, phone});
    }

    update({id, type, phone}) {
        return this.axios.put(`/user_phone/${id}`, {type, phone});
    }

    delete(id) {
        return this.axios.delete(`/user_phone/${id}`);
    }
}
