<template>
    <div class="filters d-flex flex-grow-1 flex-grow-0">
        <!-- Фільтр за департаментами -->
        <div class="input-group mx-3">
            <div class="form-control position-relative p-0">
                <select :size="inputDepartmentSelectSize"
                        v-model="innerFilters.department_id"
                        class="form-control"
                        @change="innerFilters.user_id = null"
                        @blur="inputDepartmentSelectSize = 0"
                        @focus="inputDepartmentSelectSize = 20">
                    <option disabled :value="null">{{ $t('Выберите департамент') }}</option>
                    <option v-for="department in sortedDepartments" :key="department.id" :value="department.id">
                        {{ department.name }}
                    </option>
                </select>
            </div>
            <div v-if="innerFilters.department_id" class="input-group-append">
                <button class="btn btn-primary" @click="innerFilters.department_id = null; innerFilters.user_id = null;">
                    <i class="fas fa-times px-2"></i>
                </button>
            </div>
        </div>
        <!-- Фільтр за користувачем -->
        <div class="input-group mx-3">
            <div class="form-control position-relative p-0">
                <select :size="inputUserSelectSize"
                        v-model="innerFilters.user_id"
                        class="form-control"
                        @blur="inputUserSelectSize = 0"
                        @focus="inputUserSelectSize = 20">
                    <option disabled :value="null">{{ $t('Выберите пользователя') }}</option>
                    <option v-for="user in sortedUsers" :key="user.id" :value="user.id">
                        {{ user.name }}
                    </option>
                </select>
            </div>
            <div v-if="innerFilters.user_id" class="input-group-append">
                <button class="btn btn-primary" @click="innerFilters.user_id = null">
                    <i class="fas fa-times px-2"></i>
                </button>
            </div>
        </div>

        <!-- Пошук по роутах -->
        <div class="input-group mx-3 flex-grow-1">
            <input
                v-model="innerFilters.search_query"
                class="form-control"
                :placeholder="$t('Поиск по роутах')"
                type="text"
            />
            <div v-if="innerFilters.search_query && innerFilters.search_query.length" class="input-group-append">
                <button class="btn btn-primary" @click="innerFilters.search_query = null">
                    <i class="fas fa-times px-2"></i>
                </button>
            </div>
        </div>

        <!-- Вибір дат -->
        <div class="input-group mx-3">
            <input type="date" v-model="innerFilters.start_date" class="form-control"/>
            <input type="date" v-model="innerFilters.end_date" class="form-control"/>
            <div v-if="innerFilters.start_date || innerFilters.end_date" class="input-group-append">
                <button class="btn btn-primary" @click="innerFilters.end_date = innerFilters.start_date = null">
                    <i class="fas fa-times px-2"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {cloneDeep, debounce} from "lodash";

export default {
    props: {
        users: {
            type: Array,
            required: true
        },
        departments: {
            type: Array,
            required: true
        },
        filters: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            innerFilters: {
                search_query: null,
                user_id: null,
                start_date: null,
                end_date: null,
            },
            inputUserSelectSize: 0,
            inputDepartmentSelectSize: 0
        };
    },
    methods: {
        fireEvent: debounce(function (val) {
           this.$emit('update:filters', cloneDeep(val));
        }, 500)
    },
    computed: {
        sortedUsers() {
            return this.users.sort((a, b) => a.name.localeCompare(b.name));
        },
        sortedDepartments() {
            return this.departments.sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    watch: {
        innerFilters: {
            immediate: true,
            deep: true,
            handler(val) {
                this.fireEvent(val);
                this.inputUserSelectSize = 0;
                this.inputDepartmentSelectSize = 0;
            }
        }
    },
    mounted() {
        this.innerFilters = cloneDeep(this.filters);
    }
};
</script>

<style lang="less" scoped>
.filters {
    z-index: 1;
}
</style>
