import moment from "moment-mini";

export default {
    keyboardConverter(str) {
        const s = [
            "й", "ц", "у", "к", "е", "н", "г", "ш", "щ", "з", "х", "ъ",
            "ф", "ы", "в", "а", "п", "р", "о", "л", "д", "ж", "э",
            "я", "ч", "с", "м", "и", "т", "ь", "б", "ю"
        ];

        const r = [
            "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "\\[", "\\]",
            "a", "s", "d", "f", "g", "h", "j", "k", "l", ";", "'",
            "z", "x", "c", "v", "b", "n", "m", ",", "\\."
        ];

        for (let i = 0; i < r.length; i++) {
            const reg = new RegExp(r[i], 'mig');
            str = str.replace(reg, function (a) {
                return a == a.toLowerCase() ? s[i] : s[i].toUpperCase();
            });
        }

        return str;
    },
    stringLimit(str, limit = 10, end = "...") {
        if(!str) return str;

        if (str.length > limit) {
            str = str.substring(0, limit) + end;
        }
        return str;
    },
    wordForms(n, textForms) {
        n = Math.abs(n) % 100;
        var n1 = n % 10;
        if (n > 10 && n < 20) {
            return textForms[2];
        }
        if (n1 > 1 && n1 < 5) {
            return textForms[1];
        }
        if (n1 == 1) {
            return textForms[0];
        }
        return textForms[2];
    },
    stripTags(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText;
    },
    isActiveDate(_start = null, _stop = null) {
        let start = moment(_start).isValid() ? moment(_start) : null;
        let stop = moment(_stop).isValid() ? moment(_stop) : null;
        let now = moment();

        // даты не указаны
        if (!start && !stop) {
            return true;
        }

        // указана только дата старта
        if (start && !stop) {
            return now.isSameOrAfter(start);
        }

        // указана только дата стопа
        if (!start && stop) {
            return now.isBefore(stop);
        }

        // указаны обе даты
        if (start && stop) {
            return now.isSameOrAfter(start) && now.isBefore(stop);
        }
    },

    nl2br(str, is_xhtml = false) {
        // http://kevin.vanzonneveld.net
        // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Philip Peterson
        // +   improved by: Onno Marsman
        // +   improved by: Atli Þór
        // +   bugfixed by: Onno Marsman
        // +      input by: Brett Zamir (http://brett-zamir.me)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Brett Zamir (http://brett-zamir.me)
        // +   improved by: Maximusya
        // *     example 1: nl2br('Kevin\nvan\nZonneveld');
        // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
        // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
        // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
        // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
        // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
}
