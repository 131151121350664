<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Редактирование шаблона') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Сообщение') }} <span class="text-danger">*</span></label>
                        <textarea class="form-control" v-model="message" rows="3"></textarea>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Комментарий') }}</label>
                        <textarea class="form-control" v-model="comment" rows="3"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="update" :disabled="processing" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import SmsSampleService from "../services/SmsSampleService";

const SmsSample = new SmsSampleService();

export default {
    name: "UpdateSmsSampleModal",
    data() {
        return {
            id: null,
            message: '',
            comment: '',
            processing: false,
            visible: false,
        }
    },
    methods: {
        async update() {
            this.processing = true;
            const {code, body} = await SmsSample.update({
                id: this.id,
                message: this.message,
                comment: this.comment
            });
            this.processing = false;

            if (code === 200) {
                this.$toast.success(this.$t('Шаблон обновлён'));
                this.$emit("updated", body);
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data());
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal(sms_sample) {
            this.visible = true;
            this.id = sms_sample.id;
            this.message = sms_sample.message;
            this.comment = sms_sample.comment;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.UPDATE_SMS_SAMPLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.UPDATE_SMS_SAMPLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style scoped>

</style>
