import AbstractService from "@/services/AbstractService";

export default class TariffService extends AbstractService {

    constructor() {
        super();
    }

    fetch(locality_id, trademark_id) {
        return this.axios.get('/tariff', {
            params: {locality_id, trademark_id}
        });
    }

}
