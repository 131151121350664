<template>
    <a href="javascript:void(0)"
       data-toggle="popover"
       data-html="true"
       :title="title"
       :data-content="content">
        <slot></slot>
    </a>
</template>

<script>

export default {
    name: "TariffCardCtvPopover",
    props: {
        tariff: {
            type: Object,
            required: true
        }
    },
    computed: {
        title() {
            return this.$t("ОТТ Вселенная / КТВ");
        },
        content() {
            return `
                ${this.$t('КТВ и IPTV количество каналов и технология подключения зависит от устройств абонента')}<br><br>
                ${this.$t('КТВ 70+каналов аналогового телевидения (Национальные, информационные, фильмовые, детские)')}<br><br>
                ${this.$t('Подключение коаксиальным кабелем к телевизру (более 3-х не подключаем)')}<br><br>
                ${this.$t('IPTV 300+ топовых каналов (Национальные, спортивные, информационные и познавательные) + кинозал на 20 000 фильмов')}<br><br>
                ${this.$t('Подключение на любое устройство с интернетом')}
            `;
        },
    },
}
</script>

<style scoped>

</style>
