<template>
    <div v-if="events.length" class="row">
        <div class="col-md-3 col-sm-6" v-for="(event, index) in events" :key="index">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="internalSelectedEvents"
                    :value="event.name"
                    @change="updateSelectedEvents"
                />
                <label class="form-check-label">{{ event.name }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        events: {
            type: Array,
            required: true
        },
        selectedEvents: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            internalSelectedEvents: [...this.selectedEvents]
        };
    },
    methods: {
        updateSelectedEvents() {
            this.$emit("updateSelectedEvents", this.internalSelectedEvents);
        }
    },
    watch: {
        selectedEvents(newVal) {
            this.internalSelectedEvents = [...newVal];
        }
    }
};
</script>
