<template>
    <div class="alert w-100 p-2 mr-4 mb-0 overflow-hidden"
         :style="`max-width: ${maxWidth}px; height: ${height}`"
         :class="'alert-' + note.color">
        <div class="w-100 h-100 d-flex align-items-stretch">
            <div class="d-flex flex-fill">
                <div class="d-flex flex-column justify-content-between">
                    <span class="username-label">{{ noteUserName }}</span>
                    <div class="overflow-hidden" style="font-size: 13px;">
                        <span class="d-block font-weight-bold">{{ stringLimit(note.title, 70) }}</span>
                        <span v-html="noteText" class="d-block overflow-wrap-anywhere"></span>
                    </div>
                    <div>
                        <small v-if="sortable" class="badge badge-secondary mr-1 handle-drag" style="cursor: move">
                            <i class="fas fa-grip-lines"></i>
                        </small>
                        <span v-if="!groups.length">
                            <small class="badge mr-1" :class="'badge-' + note.color">
                                {{ $t('Все группы') }}
                            </small>
                        </span>
                        <span v-else>
                            <small v-for="(item, index) in groups" :key="index"
                                   class="badge mr-1" :class="'badge-' + note.color">
                                {{ item }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-between">
                <i v-if="is('administrator') && controls && isEditable" @click="openUpdateLocalityNoteModal"
                   class="d-block message-icon far fa-edit fa-fw"></i>
                <i v-if="is('administrator') && controls && isEditable" @click.stop="deleteNote"
                   class="d-block message-icon far fa-trash-alt fa-fw"></i>
                <i @click="showLocalitySnapshotModal" class="d-block message-icon fas fa-eye fa-fw"></i>
                <i @click="showFullText" class="d-block message-icon fas fa-align-justify fa-fw"></i>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from "@/Helper";
import {EventBus, Events} from "@/EventBus";
import LocalityNoteService from "@/services/LocalityNoteService";
import linkifyHtml from 'linkify-html';
import {mapState} from "vuex";

const LocalityNote = new LocalityNoteService();

export default {
    name: "LocalityNote",
    props: {
        note: {
            type: Object,
            required: true
        },
        maxWidth: {
            type: Number,
            required: false,
            default: 1000
        },
        controls: {
            type: Boolean,
            required: false,
            default: false
        },
        height: {
            type: String,
            required: false,
            default: "auto"
        },
        sortable: {
            type: Boolean,
            default: false
        }
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 70)
    },
    data() {
        return {
            deleting: false
        }
    },
    methods: {
        stringLimit: (str, limit) => Helper.stringLimit(str, limit),
        showFullText() {
            EventBus.$emit(Events.SHOW_NOTE_MESSAGE_MODAL, this.note);
        },
        async deleteNote() {
            if (!confirm(this.$t('Удалить заметку?'))) return;

            this.deleting = true;
            const {code, body} = await LocalityNote.delete(this.note.id);
            this.deleting = false;

            if (code === 200) {
                this.$emit("deleted", body);
                this.$toast.success(this.$t('Заметка удалена!'));
            } else {
                this.$toast.error(this.$t('Ошибка удаления!'));
            }
        },
        openUpdateLocalityNoteModal() {
            EventBus.$emit(Events.UPDATE_LOCALITY_NOTE_MODAL_OPEN, this.note);
        },
        showLocalitySnapshotModal() {
            EventBus.$emit(Events.LOCALITY_SNAPSHOT_MODAL_OPEN, this.note);
        }
    },
    computed: {
        ...mapState({
            user: state => state.user
        }),
        isEditable() {
            return this.note.department_id === this.userDepartmentId || this.isDepartment("su");
        },
        groups() {
            return this.note?.groups?.map(item => {
                return this.$store.state.groupsList[item];
            })
        },
        noteUserName() {
            return this.note?.user?.name;
        },
        noteText() {
            const text = Helper.stringLimit(
                Helper.nl2br(this.note.note),
                320
            );

            return linkifyHtml(text, {
                target: "_blank"
            });
        }
    }
}
</script>

<style lang=less scoped>
.message-icon {
    cursor: pointer;
    color: #a1a1a1;
    font-size: .7rem;

    &:hover {
        color: #000;
    }
}

.username-label {
    position: absolute;
    font-size: 9px;
    font-weight: bold;
    top: 7px;
    right: 2rem;
    background: #717171;
    color: #fff;
    padding: 0 4px;
    border-radius: 10px;
}
</style>
