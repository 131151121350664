<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Добавление нового типа пользователя') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Имя') }} <span class="text-danger">*</span></label>
                        <input v-model="name" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Slug') }} <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <input v-model="slug" type="email" class="form-control">
                            <div class="input-group-append">
                                <button @click="createSlug" :disabled="!name.length" class="btn btn-primary"
                                        type="button">
                                    <i class="fas fa-magic"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Комментарий') }} <span
                            class="text-danger">*</span></label>
                        <textarea v-model="comment" rows="5" class="form-control"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create" :disabled="processing" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import UserRoleService from "@/services/UserRoleService";
import slugify from 'slugify';

const UserRole = new UserRoleService();

export default {
    name: "CreateUserRoleModal",
    data() {
        return {
            id: null,
            visible: false,
            processing: false,
            name: "",
            slug: "",
            comment: "",
        }
    },
    methods: {
        async create() {
            this.processing = true;
            const {code, body} = await UserRole.create(this.$data);
            this.processing = false;

            if (code === 201) {
                this.$toast.success(this.$t('Тип пользователя добавлен'));
                this.$emit("created", body);
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        createSlug() {
            this.slug = slugify(this.name, {
                replacement: '_',
                lower: true,
                trim: true
            });
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal() {
            this.visible = true;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.CREATE_USER_ROLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.CREATE_USER_ROLE_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    },
}
</script>

<style scoped>

</style>
