<template>
    <div class="mb-4">
        <div class="tariff-card" :class="{ inactive : !isActive }">
            <div class="d-md-flex text-center text-md-left  justify-content-between align-items-center border-bottom mb-3 pb-3">
                <div>
                    <h5 class="font-weight-bold d-inline">{{ tariff.name }}</h5>
                    <span data-toggle="tooltip" :title="idTooltip" class="text-black-50 pl-3">
                        ID: {{ tariff.uniq_id }}
                    </span>
                    <span class="text-danger font-weight-bold pl-3" :class="{ 'd-none' : isActive }">
                        {{ $t('Скрыт на сайте') }}
                        <i class="far fa-question-circle" data-toggle="tooltip" :title="$t('Этот тариф скрыт на сайте, но есть в биллинге')"></i>
                    </span>
                </div>
                <div v-if="!hideButtons" class="mt-2 mt-md-0">
                    <button v-if="tariff.notes.length" @click="visibleNotes = !visibleNotes" class="btn btn-sm btn-primary mx-2">
                        {{ visibleNotes ? $t('Скрыть заметки') : $t('Показать заметки') + ' (' + tariff.notes.length + ')' }}
                    </button>
                    <button v-if="is('administrator') && isDepartment('tm')" @click="$emit('createNote', tariff)" class="btn btn-sm btn-success"
                            :class="{ 'btn-block' : !tariff.notes.length }">
                        <i class="fas fa-plus"></i>
                        <span v-if="!tariff.notes.length" class="pl-2">{{ $t('Добавить заметку') }}</span>
                    </button>
                </div>
            </div>
            <TariffCardNotes v-if="visibleNotes" :tariff="tariff"></TariffCardNotes>
            <div v-else class="row">
                <div class="col-12 col-md-4">
                    <div v-if="tariff.tariff_type === 'internet'" class="row">
                        <div class="col-8 mb-3">
                            <i class="fas fa-fw mr-2 fa-tachometer-alt text-black-50"></i>
                            {{ $t('Скорость') }}
                        </div>
                        <div class="col-4 font-weight-bold text-primary">
                            {{ tariff.speed }}
                            <small class="text-black-50">{{ $t('мбит/сек') }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 mb-2">
                            <i class="fas fa-fw mr-2 fa-coins text-black-50"></i>
                            {{ $t('Абонплата') }}
                        </div>
                        <div class="col-4 d-flex justify-content-start">
                            <del v-if="tariff.fee_original > 0 && tariff.fee_original !== tariff.fee"
                                 class="text-danger pr-2">
                                {{ tariff.fee_original }}
                            </del>
                            <div class="text-primary font-weight-bold">
                                {{ tariff.fee }}
                                <small class="text-black-50">{{ $t('грн/мес') }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 mb-2">
                            <i class="fas fa-fw mr-2 fa-plug text-black-50"></i>
                            {{ $t('Стоимость подключения') }}
                        </div>
                        <div class="col-4 font-weight-bold text-primary">
                            {{ tariff.connect_price }}
                            <small class="text-black-50">{{ $t('грн') }}</small>
                        </div>
                    </div>
                    <div v-if="tariff.iptv_count_channels" class="row">
                        <div class="col-8 mb-2">
                            <i class="fas fa-fw mr-2 fa-tv text-black-50"></i>
                            {{ tariff.tariff_type === 'internet' ? $t('Цифровое TV') : $t('Количество каналов') }}
                            <TariffCardTvPopover v-if="tariff.tariff_type === 'internet'" :tariff="tariff">
                                <i class="far fa-fw fa-question-circle"></i>
                            </TariffCardTvPopover>
                            <TariffCardCtvPopover v-else :tariff="tariff">
                                <i class="far fa-fw fa-question-circle"></i>
                            </TariffCardCtvPopover>
                        </div>
                        <div class="col-4 font-weight-bold text-primary">
                            <a v-if="tariff.tariff_type === 'internet'" href="#" @click.prevent="openBundleChannelsModal(tariff)">
                                {{ tariff.iptv_count_channels }}
                            </a>
                            <span v-else>{{ tariff.iptv_count_channels }} </span>
                            <small class="text-black-50">{{ wordForms(tariff.iptv_count_channels, [$t('канал'), $t('канала'), $t('каналов')]) }}</small>
                        </div>
                    </div>
                    <div v-if="tariff.white_ip" class="row">
                        <div class="col-8 mb-2">
                            <i class="fas fa-fw mr-2 fa-network-wired text-black-50"></i>
                            {{ $t('Белый IP') }}
                        </div>
                        <div class="col-4 font-weight-bold text-success">
                            {{ $t('Есть') }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 border-left">
                    <div v-if="tariff.ctv && tariff.tariff_type === 'internet'" class="row">
                        <div class="col-8 mb-3">
                            <i class="fas fa-fw mr-2 fa-tv text-black-50"></i>
                            {{ $t('ОТТ Вселенная / КТВ') }}
                            <TariffCardCtvPopover :tariff="tariff">
                                <i class="far fa-fw fa-question-circle"></i>
                            </TariffCardCtvPopover>
                        </div>
                        <div class="col-4 font-weight-bold text-success">
                            {{ $t('Есть') }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 mb-2">
                            <i class="far fa-clock fa-fw  text-black-50 mr-2"></i>
                            {{ $t('Период действия тарифа') }}
                        </div>
                        <div class="col-4 font-weight-bold">
                            <span v-if="!tariff.date_stop">∞</span>
                            <span v-if="tariff.date_stop">
                        <small class="text-black-50">{{ $t('По') }}</small>
                        <small class="text-danger font-weight-bold"> {{ tariff.date_stop }}</small>
                    </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 mb-2">
                            <i class="fas fa-layer-group fa-fw  text-black-50 mr-2"></i>
                            {{ $t('Группа') }}
                        </div>
                        <div class="col-8">
                            <marquee-text :duration="5">
                                <span class="font-weight-bold text-primary pr-4">{{ tariff.group_name }} </span>
                            </marquee-text>
                        </div>
                    </div>
                    <div v-if="isActivePeriod(tariff.action)" class="row">
                        <div class="col-8 mb-2 text-danger">
                            <i class="fas fa-fw mr-2 fa-plug"></i>
                            {{ $t('Акционная стоим. подкл') }}.
                        </div>
                        <div class="col-4 font-weight-bold text-danger">
                            {{ parseInt(tariff.action.connect_price) }}
                            <small>{{ $t('грн') }}</small>
                            <i v-if="actionRequiredPeriodTooltip" class="far ml-2 fa-fw fa-question-circle" data-toggle="tooltip" :title="actionRequiredPeriodTooltip"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 border-left">
                    <div v-if="isActiveConnectDevicePeriod(tariff.action_connect_device)" class="row">
                        <div class="col-8 mb-2 text-danger">
                            <i class="fas fa-fw mr-2 fa-exchange-alt"></i>
                            {{ $t('Акционный переход') }}
                        </div>
                        <div class="col-4 font-weight-bold text-danger">
                            {{ parseInt(tariff.action_connect_device.value) }}
                            <small>{{ $t('грн') }}</small>
                        </div>
                    </div>
                    <div v-if="!isLightStart" class="row mb-1">
                        <div class="col-8 mb-2 text-info">
                            <i class="fas mr-2 fa-fw fa-gift"></i>
                            {{ $t('Акция 12+3') }}
                            <i class="far fa-fw fa-question-circle" data-toggle="tooltip" :title="action12plus3Tooltip"></i>
                        </div>
                        <div class="col-4 font-weight-bold text-info">
                            {{ tariff.fee * 12 }}
                            <small>{{ $t('грн') }}</small>
                        </div>
                    </div>
                    <div v-if="!isLightStart" class="row mb-1">
                        <div class="col-8 mb-2 text-info">
                            <i class="fas mr-2 fa-fw fa-gift"></i>
                            {{ $t('Акция 6+1') }}
                            <i class="far fa-fw fa-question-circle" data-toggle="tooltip" :title="action6plus1Tooltip"></i>
                        </div>
                        <div class="col-4 font-weight-bold text-info">
                            {{ tariff.fee * 6 }}
                            <small>{{ $t('грн') }}</small>
                        </div>
                    </div>
                    <div  class="row mb-1" v-if="isLocalityIds4plus2 && !isLightStart">
                        <div class="col-8 mb-2 text-info">
                            <i class="fas mr-2 fa-fw fa-gift"></i>
                            {{ $t('Акция 4+2') }}
                            <i class="far fa-fw fa-question-circle" data-toggle="tooltip" :title="action4plus2Tooltip"></i>
                        </div>
                        <div class="col-4 font-weight-bold text-info">
                            {{ tariff.fee * 4 }}
                            <small>{{ $t('грн') }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Helper from "@/Helper";
import MarqueeText from 'vue-marquee-text-component';
import TariffCardNotes from "@/components/TariffCardNotes";
import TariffCardTvPopover from "@/components/TariffCardTvPopover";
import {EventBus, Events} from "@/EventBus";
import TariffCardCtvPopover from "@/components/TariffCardCtvPopover";
import config from "@/config";

export default {
    name: "TariffCard",
    components: {
        MarqueeText,
        TariffCardNotes,
        TariffCardTvPopover,
        TariffCardCtvPopover
    },
    props: {
        tariff: {
            type: Object,
            required: true
        },
        locality_id: {
            type: Number|String,
            required: false,
            default: 0
        },
        hideButtons: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            visibleNotes: false
        }
    },
    methods: {
        wordForms(n, forms) {
            return Helper.wordForms(n, forms)
        },
        isActivePeriod(data) {
            if (!data || !data.connect_price) {
                return false;
            }

            return Helper.isActiveDate(data?.date_start, data?.date_stop);
        },
        isActiveConnectDevicePeriod(data) {
            if (!data) {
                return false;
            }

            return Helper.isActiveDate(data?.date_start, data?.date_stop);
        },
        openBundleChannelsModal(tariff) {
            EventBus.$emit(Events.TV_BUNDLE_CHANNELS_MODAL_OPEN, tariff?.iptv?.service_id);
        }
    },
    computed: {
        isActive() {
            return !(this.tariff?.hidden || this.tariff?.deleted || this.tariff?.duplicate)
                && Helper.isActiveDate(this.tariff?.date_start, this.tariff?.date_stop);
        },
        idTooltip() {
            return `<p class="text-left">ID НП: ${this.tariff.uniq_id.split(".")[0]}<br>
             ${this.$t('ID Группы')}: ${this.tariff.ebs_tariffs_group_id}<br>
             ${this.$t('ID Тарифа')}: ${this.tariff.ebs_tariff_id}<p>`;
        },
        action12plus3Tooltip() {
            return `<p class="text-left">
                ${this.$t('Разовый платеж')}: ${this.tariff.fee * 12} ${this.$t('грн')}.<br>
                ${this.$t('Экономия')}: ${this.tariff.fee * 3} ${this.$t('грн')}.<br>
                ${this.$t('Экономия в месяц')}: ${((this.tariff.fee * 3) / 15).toFixed(2)} ${this.$t('грн')}.<br><br>
                ${this.$t('Все суммы расчитаны за период 15 месяцев')}.
                </p>`;
        },
        action6plus1Tooltip() {
            return `<p class="text-left">
                ${this.$t('Разовый платеж')}: ${this.tariff.fee * 6} ${this.$t('грн')}.<br>
                ${this.$t('Экономия')}: ${this.tariff.fee} ${this.$t('грн')}.<br>
                ${this.$t('Экономия в месяц')}: ${(this.tariff.fee / 7).toFixed(2)} ${this.$t('грн')}.<br><br>
                ${this.$t('Все суммы расчитаны за период 7 месяцев')}.
                </p>`;
        },
        action4plus2Tooltip() {
            return `<p class="text-left">
                ${this.$t('Разовый платеж')}: ${this.tariff.fee * 4} ${this.$t('грн')}.<br>
                ${this.$t('Экономия')}: ${this.tariff.fee * 2} ${this.$t('грн')}.<br>
                ${this.$t('Экономия в месяц')}: ${(this.tariff.fee * 2 / 6).toFixed(2)} ${this.$t('грн')}.<br><br>
                ${this.$t('Все суммы расчитаны за период 6 месяцев')}.
                </p>`;
        },
        isLocalityIds4plus2() {
            return config.localityIds4plus2.includes(parseInt(this.locality_id, 10));
        },
        isLightStart() {
            return this.tariff.name.toLowerCase().includes('легкий')
        },
        actionRequiredPeriodTooltip() {
            return this.tariff?.action?.min_period
                ? `${this.$t('Обязательный период пользования')}: ${parseInt(this.tariff.action.min_period)} ${this.$t('мес')}.`
                : false;
        }
    },
    watch: {
        // при удалении заметки, если заметок не осталось, переключаем вид карточки на данные тарифа
        "tariff.notes"(val) {
            if (!val?.length) {
                this.visibleNotes = false;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.tariff-card {
    width: 100%;
    background: #fff;
    position: relative;
    padding: 1rem;
    margin-bottom: .5rem;
    border-radius: .2rem;
    overflow: hidden;

    @media (max-width: 767px) {
        width: calc(80vw - 10px);
        height: auto;
    }

    & div {
        font-size: .9rem;
    }
}

.inactive {
    opacity: 1;
}

</style>
