<template>
    <div class="hide" :class="{'show': isAccordionOpen}">
        <BarChart
            ref="chart"
            :chart-data="computedChartData"
            :options="chartOptions"
            :isAccordionOpen="isAccordionOpen"
        ></BarChart>
    </div>
</template>

<script>
import {Bar} from "vue-chartjs";
export default {
    components: {
        BarChart: {
            extends: Bar,
            props: ["chartData", "options", "isAccordionOpen"],
            mounted() {
                this.renderChart(this.chartData, this.options);
            },
            watch: {
                chartData(newData) {
                    this.renderChart(newData, this.options);
                },
                isAccordionOpen() {
                    this.renderChart(this.chartData, this.options);
                },
            },
        },
    },
    props: {
        departmentsCharts: {
            type: Array,
            required: true,
        },
        isAccordionOpen: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            }
        };
    },
    computed: {
        computedChartData() {
            return {
                labels: this.departmentsCharts.map((event) => event.department_name),
                datasets: [
                    {
                        label: this.$t('Количество событий'),
                        backgroundColor: "#42b983",
                        data: this.departmentsCharts.map((event) => event.event_count),
                    },
                ],
            };
        },
    },
};
</script>

<style lang="less" scoped>
.hide {
    opacity: 0;
    transition: opacity 0.5s ease;

    &.show {
        opacity: 1;
    }
}
</style>
