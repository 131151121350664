<template>
    <div class="w-100 pt-4 mr-0">
        <FullHeightBlock :offsetBottom="15" class="custom-scroll">
            <div v-for="item in scripts" :key="item.id" @click="showScript(item)" class="script-item">
                <i class="fas fa-fw fa-scroll pr-4"></i>
                <strong>{{ stringLimit(item.name, 40) }}</strong>
            </div>
            <div v-if="!scripts.length" class="pt-5 text-center text-muted">
                {{ $t('Нет скриптов для даного НП') }}
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import FullHeightBlock from "@/components/FullHeightBlock";
import Helper from "@/Helper";
import {EventBus, Events} from "@/EventBus";
import ScriptService from "@/services/ScriptService";

const Script = new ScriptService();

export default {
    name: "LocalityScripts",
    components: {
        vueCustomScrollbar,
        FullHeightBlock
    },
    props: {
        locality_id: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            scripts: [],
        }
    },
    methods: {
        stringLimit: Helper.stringLimit,
        showScript(script) {
            EventBus.$emit(Events.SHOW_SCRIPT_MODAL, script);
        }
    },
    async mounted() {
        const {code, body} = await Script.fetch(1000);
        if (code === 200) {
            this.scripts = body.content.filter(item => {
                return item.localities.indexOf(this.locality_id * 1) > -1
                    || item.localities.length === 0;
            });
            this.$emit('init', this.scripts.length);
        }
    }
}
</script>

<style lang="less" scoped>

.script-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    background: #fff;
    color: #007BFF;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 6px;

    &:hover {
        background: #007BFF;
        color: #fff;
    }
}

</style>
