import {EventBus, Events} from "@/EventBus";

export default {
    install: function (Vue) {
        Vue.prototype.$preloader = {
            _status: false,
            show(animation = true) {
                this._status = true;
                EventBus.$emit(Events.SHOW_PRELOADER, animation);
            },
            hide(animation = true) {
                this._status = false;
                EventBus.$emit(Events.HIDE_PRELOADER, animation);
            },
            isLoading() {
                return this._status;
            },
            DISABLED_ANIMATION: false,
        }
    }
};
