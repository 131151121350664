<template>
    <div v-if="step.length">
        <slot></slot>
        <div class="alert alert-light p-4">
            <div class="d-flex justify-content-between mb-4">
                <button @click="prev"
                        :disabled="!history.length"
                        class="btn btn-primary">
                    <i class="fas pr-1 fa-redo"></i>
                    {{ $t('Шаг назад') }}
                </button>
                <button @click="reset"
                        :disabled="!history.length"
                        class="btn btn-danger">
                    <i class="fas pr-1 fa-sync-alt"></i>
                    {{ $t('Сбросить в начало') }}
                </button>
            </div>
            <div class="list-group">
                <a v-for="item in step"
                   href="#"
                   @click.prevent="next(item)"
                   class="list-group-item list-group-item-action py-4">
                    {{ item.data.name || 'нода без текста' }}
                    <i v-if="hasNext(item)" class="fas fa-angle-right fa-lg float-right"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import {cloneDeep} from "lodash";

export default {
    name: "ScriptViewer",
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            step: [],
            history: []
        }
    },
    methods: {
        prev() {
            if (this.history.length) {
                this.step = this.history.pop();
            }
        },
        hasNext(item) {
            return item.outputs.output_1.connections.length > 0;
        },
        next(item) {
            if (!item.outputs.output_1.connections.length) {
                return;
            }

            const nodes = item.outputs.output_1.connections.map(item => {
                return item.node;
            });

            this.history.push(cloneDeep(this.step));
            this.step = [];

            nodes.forEach(node => {
                this.step.push(this?.data?.drawflow?.Home?.data[node])
            });
        },
        reset() {
            this.step = [];
            this.history = [];

            Object.entries(this?.data?.drawflow?.Home?.data).forEach(item => {
                if (!item[1].inputs.input_1.connections.length) {
                    this.step.push(item[1]);
                }
            });
        }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.$nextTick(this.reset);
            }
        }
    },
    mounted() {
        this.reset();
    }
}
</script>

<style scoped>

</style>
