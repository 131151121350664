<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3 pb-3 border-bottom">
            <div class="flex-grow-1">
                <div class="input-group">
                    <input v-model="searchQuery" type="text" class="form-control" :placeholder="$t('Поиск')">
                    <div v-show="searchQuery.length > 0" class="input-group-append">
                        <button @click="searchQuery = ''" class="btn btn-danger">{{ $t('очистить') }}</button>
                    </div>
                </div>

            </div>
            <button v-if="searchQuery.length === 0" @click="tab = (tab !== 1 ? 1 : 0)" class="ml-4 btn btn-success">
                {{ tab === 1 ? $t('Скрыть выбранные') : $t('Показать выбранные') }} - {{ selectedLocalities.length }}
            </button>
        </div>

        <div v-if="tab === 0" class="row">
            <div class="col-4 font-weight-bold mb-3">{{ $t('Область') }}</div>
            <div class="col-4 font-weight-bold mb-3 d-flex justify-content-between align-items-center">
                {{ $t('Район') }}
                <div v-if="areas.length" class="btn-group btn-group-sm mr-3" role="group">
                    <button @click="multiSelectAreasLocalities" type="button" class="btn btn-outline-primary">
                        {{ $t('Отметить все') }}
                    </button>
                    <button @click="multiUnselectAreasLocalities" type="button" class="btn btn-outline-primary">
                        {{ $t('Снять все') }}
                    </button>
                </div>
            </div>
            <div class="col-4 font-weight-bold mb-3 d-flex justify-content-between align-items-center">
                {{ $t('НП') }}
                <div v-if="localities.length" class="btn-group btn-group-sm mr-3" role="group">
                    <button @click="multiSelectLocalities" type="button" class="btn btn-outline-primary">
                        {{ $t('Отметить все') }}
                    </button>
                    <button @click="multiUnselectLocalities" type="button" class="btn btn-outline-primary">
                        {{ $t('Снять все') }}
                    </button>
                </div>
            </div>
            <div class="col-4" style="height: 500px; overflow-y: auto;">
                <div class="list-group">
                    <button v-for="(item, index) in regions"
                            :key="index"
                            :class="{ 'active' : isEqual(selectedRegion, item) }"
                            @click="selectedRegion = item"
                            class="list-group-item list-group-item-action d-flex justify-content-between">
                        {{ item.region_name }}
                        <span v-if="getRegionSelectedLocalities(item)">
                            {{ getRegionSelectedLocalities(item) }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="col-4 border-left" style="max-height: 500px; overflow-y: auto;">
                <div class="list-group">
                    <button v-for="(item, index) in areas"
                            :key="index"
                            :class="{ 'active' : isEqual(selectedArea, item) }"
                            @click="selectedArea = item"
                            class="list-group-item list-group-item-action d-flex justify-content-between">
                        {{ item.area_name }}
                        <span v-if="getAreaSelectedLocalities(item)">
                            {{ getAreaSelectedLocalities(item) }}
                        </span>
                    </button>
                </div>
            </div>
            <div class="col-4 border-left" style="max-height: 500px; overflow-y: auto;">
                <div class="list-group">
                    <button v-for="(item, index) in localities"
                            :key="index"
                            @click="selectLocality(item)"
                            class="list-group-item list-group-item-action">
                        <img class="mr-3 border rounded"
                             style="width: 20px; height: 20px; object-fit: contain;"
                             :src="trademark(item).icon"/>
                        {{ item.locality_name }}
                        <i v-if="isSelected(item)" class="fas fa-check-square float-right text-primary"></i>
                        <i v-else class="far fa-square float-right text-primary"></i>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="tab === 1" class="row">
            <div class="col-12 font-weight-bold mb-3">{{ $t('Выбранные НП') }}</div>
            <div class="col-12" style="height: 500px; overflow-y: auto;">
                <div class="list-group">
                    <button v-for="(item, index) in selectedLocalitiesList"
                            :key="index"
                            @click="selectLocality(item)"
                            class="list-group-item list-group-item-action">
                        <img class="mr-3 border rounded"
                             style="width: 20px; height: 20px; object-fit: contain;"
                             :src="trademark(item).icon"/>
                        {{ item.region_name }} {{ $t('обл') }}. / {{ item.area_name }} {{ $t('р-н') }}. / <strong>{{
                            item.locality_name
                        }}</strong>
                        <i class="fas fa-check-square float-right text-primary"></i>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="tab === 2" class="row">
            <div class="col-12 font-weight-bold mb-3">{{ $t('Найденные НП') }}</div>
            <div class="col-12" style="height: 500px; overflow-y: auto;">
                <div class="list-group">
                    <button v-for="(item, index) in searchedLocalities"
                            :key="index"
                            @click="selectLocality(item)"
                            class="list-group-item list-group-item-action">
                        <img class="mr-3 border rounded"
                             style="width: 20px; height: 20px; object-fit: contain;"
                             :src="trademark(item).icon"/>
                        {{ item.region_name }} {{ $t('обл') }}. / {{ item.area_name }} {{ $t('р-н') }}. / <strong>{{
                            item.locality_name
                        }}</strong>
                        <i v-if="isSelected(item)" class="fas fa-check-square float-right text-primary"></i>
                        <i v-else class="far fa-square float-right text-primary"></i>
                    </button>
                </div>
                <div v-if="!searchedLocalities.length" class="text-center centered text-muted display-4">
                    {{ $t('Ничего не найдено') }} (·.·)
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import {mapState} from "vuex";
import _ from "underscore";
import Helper from "@/Helper";
import MockFactory from "@/classess/MockFactory";

export default {
    name: "ModalLocalityPicker",
    props: {
        selectedLocalitiesProp: {
            type: Array
        }
    },
    data() {
        return {
            tab: 0,
            selectedRegion: null,
            selectedArea: null,
            selectedLocalities: [],
            searchQuery: ""
        }
    },
    methods: {
        isEqual: _.isEqual,
        isSelected(locality) {
            return this.selectedLocalities.indexOf(locality.id * 1) !== -1;
        },
        getAreaSelectedLocalities(area) {
            return this.localitiesList.filter(item => {
                return item.area_name === area.area_name
                    && this.selectedLocalities.indexOf(item.id * 1) > -1;
            })?.length;
        },
        getRegionSelectedLocalities(region) {
            return this.localitiesList.filter(item => {
                return item.region_name === region.region_name
                    && this.selectedLocalities.indexOf(item.id * 1) > -1;
            })?.length;
        },
        selectLocality(locality) {
            if (this.isSelected(locality)) {
                this.selectedLocalities = this.selectedLocalities.filter(item => item != locality.id);
            } else {
                this.selectedLocalities.push(locality.id * 1);
            }
        },
        trademark(locality) {
            return this.trademarks.find(item => item.id == locality.trademark_id);
        },
        multiSelectLocalities() {
            this.localities.forEach(locality => {
                if (!this.isSelected(locality)) {
                    this.selectedLocalities.push(locality.id * 1);
                }
            });
        },
        multiUnselectLocalities() {
            this.localities.forEach(locality => {
                if (this.isSelected(locality)) {
                    this.selectedLocalities = this.selectedLocalities.filter(item => item != locality.id);
                }
            });
        },
        multiSelectAreasLocalities() {
            this.localitiesList.filter(item => {
                return item.region_name === this.selectedRegion.region_name;
            }).forEach(locality => {
                if (!this.isSelected(locality)) {
                    this.selectedLocalities.push(locality.id * 1);
                }
            });
        },
        multiUnselectAreasLocalities() {
            this.localitiesList.filter(item => {
                return item.region_name === this.selectedRegion.region_name;
            }).forEach(locality => {
                if (this.isSelected(locality)) {
                    this.selectedLocalities = this.selectedLocalities.filter(item => item != locality.id);
                }
            });
        }
    },
    computed: {
        ...mapState({
            localitiesList: state => state?.localities,
            trademarks: state => state?.trademarks,
        }),
        regions() {
            return _.uniq(this.localitiesList, item => item.region_name)
                .sort((a, b) => a.region_name.localeCompare(b.region_name));
        },
        areas() {
            const areas = this.localitiesList.filter(item => {
                return item.region_name === this.selectedRegion?.region_name;
            });

            return _.uniq(areas, item => item.area_name)
                .sort((a, b) => a.area_name.localeCompare(b.area_name));
        },
        localities() {
            return this.localitiesList.filter(item => {
                return item.region_name === this.selectedRegion?.region_name
                    && item.area_name === this.selectedArea?.area_name
            }).sort((a, b) => a.locality_name.localeCompare(b.locality_name));
        },
        selectedLocalitiesList() {
            return this.selectedLocalities.map(locality_id => {
                return this.localitiesList.find(item => item.id == locality_id) || MockFactory.getLocalityObject()
            });
        },
        searchedLocalities() {
            const query = Helper.keyboardConverter(this.searchQuery.toLowerCase());
            const filtered = _.filter(this.localitiesList, item => {
                return item.locality_search_name.toLowerCase().includes(query);
            });

            return _.chain(filtered)
                .sortBy("locality_name")
                .take(15)
                .value();
        }
    },
    watch: {
        searchQuery(val) {
            this.tab = val.length ? 2 : 0;
        },
        selectedLocalities(val) {
            this.$emit("change", val);
        }
    },
    mounted() {
        this.selectedLocalities = this.selectedLocalitiesProp;
    }
}
</script>

<style scoped>

</style>
