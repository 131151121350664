<template>
    <div>
        <Navbar>
            <div class="row w-75">
                <div class="col-4">
                    <h4 class="text-light mb-0">
                        {{ $t('Скрипты') }}
                        <button v-if="is('administrator')"
                                @click="$router.push('/script')"
                                class="ml-3 btn btn-primary">
                            {{ $t('Добавить') }}
                        </button>
                    </h4>
                </div>
            </div>
        </Navbar>
        <FullHeightBlock class="custom-scroll">
            <div class="container-fluid py-4">
                <div v-if="scripts.length || is_init" class="row">
                    <div class="col-12">
                        <vue-good-table
                            :columns="columns"
                            :rows="scripts"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="total_scripts"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [10, 20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'name'">
                                    <a href="#"
                                       @click.prevent="$router.push('/script/' + props.row.id)"
                                       class="d-block font-weight-bold text-primary">{{
                                            stringLimit(props.row.name)
                                        }}</a>
                                </div>
                                <div v-else-if="props.column.field === 'active'">
                                    <i v-if="props.row.active" class="fas fa-check-circle text-success"></i>
                                    <i v-else class="fas fa-times-circle text-danger"></i>
                                </div>
                                <div v-else-if="props.column.field === 'trademark'">
                                    <img v-for="item in props.row.trademark"
                                         :key="item"
                                         :src="trademarks.find(i => i.id == item).icon"
                                         class="d-inline ml-3 border rounded"
                                         style="width: 30px; height: 30px; object-fit: contain">
                                </div>
                                <div v-else-if="props.column.field === 'localities'">
                                    <a v-if="props.row.localities.length"
                                       @click.prevent="showLocalitiesModal(props.row)"
                                       href="#"
                                       class="font-weight-bold">{{ props.row.localities.length }} {{ $t('НП') }}</a>
                                    <span v-else>{{ $t('Все') }}</span>
                                </div>
                                <div v-else>
                                    {{ stringLimit(props.formattedRow[props.column.field]) || '-' }}
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <div v-else class="centered">
                    <div class="display-4 text-black-50 mb-4">{{ $t('Тут пока ничего нет') }}</div>
                </div>
            </div>
        </FullHeightBlock>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import FullHeightBlock from "@/components/FullHeightBlock";
import {mapState} from "vuex";
import Helper from "@/Helper";
import ScriptService from "@/services/ScriptService";
import {EventBus, Events} from "@/EventBus";
import moment from "moment-mini";

const Script = new ScriptService();

export default {
    name: 'Scripts',
    components: {
        Navbar,
        VueGoodTable,
        FullHeightBlock
    },
    data() {
        return {
            is_init: false,
            scripts: [],
            total_scripts: 0,
            limit: 10,
            offset: 0,
            isLoading: false,
        }
    },
    methods: {
        stringLimit: str => Helper.stringLimit(str, 20),
        async remove(id) {
            this.isLoading = true;
            await Script.delete(id);
            await this.loadScripts();
            this.isLoading = false;
        },
        showLocalitiesModal(script) {
            EventBus.$emit(Events.SHOW_LOCALITIES_MODAL, script.localities);
        },
        async loadScripts(limit = 10, offset = 0) {
            this.isLoading = true;
            const {code, body} = await Script.fetch(limit, offset);
            this.isLoading = false;

            if (code === 401) {
                await this.$router.push({name: 'Auth'});
            } else {
                this.scripts = body.content;
                this.total_scripts = body.total;
            }
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadScripts(this.limit, this.offset);
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadScripts(this.limit, this.offset);
        },
    },
    computed: {
        ...mapState({
            trademarks: state => state.trademarks
        }),
        columns() {
            return [
                {
                    label: 'ID',
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$tc('Название'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$tc('Описание'),
                    field: 'description',
                    sortable: false,
                },
                {
                    label: this.$tc('НП'),
                    field: 'localities',
                    sortable: false,
                },
                // {
                //     label: 'TM',
                //     field: 'trademark',
                //     sortable: false,
                // },
                {
                    label: this.$tc('Статус'),
                    field: 'active',
                    sortable: false,
                },
                {
                    label: this.$tc('Дата'),
                    field: 'updated_at',
                    sortable: false,
                    width: "250px",
                    formatFn: value => {
                        return value ? moment(value).format("YYYY-MM-DD HH:mm") : null;
                    }
                }
            ];
        }
    },
    async mounted() {
        document.title = this.$tc("Скрипты");
        this.$preloader.show();
        await this.loadScripts(this.limit, this.offset);
        this.$preloader.hide();
        this.is_init = true;
    }
}
</script>

<style lang="less">
</style>
