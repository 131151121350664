import i18n from "@/i18n";

export default class MockFactory {

    static getLocalityObject(params) {
        return {
            id: 0,
            trademark_id: 0,
            guid: "#",
            locality_name: i18n.t("НП удален"),
            locality_search_name: i18n.t("НП удален"),
            region_name: "-",
            area_name: "-",
            ...params
        }
    }
}
