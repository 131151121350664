<template>
    <div id="drawflow-wrapper">
        <div class="buttons">
            <button class="btn btn-primary m-2" @click="addNode">
                <i class="fas fa-plus"></i>
                {{ $t('Добавить ноду') }}
            </button>
            <button class="btn btn-danger m-2" @click="clear">
                <i class="fas fa-eraser pr-1"></i>
                {{ $t('Очистить все') }}
            </button>
            <button class="btn btn-secondary float-right m-2" @click="$emit('close')">
                <i class="fas fa-times pr-1"></i>
                {{ $t('Выйти без сохранения') }}
            </button>
            <button class="btn btn-success float-right m-2" @click="saveAndClose">
                <i class="fas fa-check pr-1"></i>
                {{ $t('Сохранить и выйти') }}
            </button>
        </div>
        <div @wheel.prevent="wheel" id="drawflow"></div>
    </div>
</template>

<script>

import Vue from 'vue'
import Drawflow from 'drawflow'
import 'drawflow/dist/drawflow.min.css'

export default {
    name: "Drawflow",
    props: {
        data: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            editor: null,
            x: 300,
            y: 200
        }
    },
    methods: {
        wheel(e) {
            if (e.deltaY < 0) {
                this.editor.zoom_in();
            } else {
                this.editor.zoom_out();
            }
        },
        clear() {
            if (!confirm(this.$t('Удалить все элементы?'))) return;
            this.editor.clear();
        },
        addNode() {
            let html = `<div><textarea :placeholder="${this.$t('Текст')}" df-name></textarea></div>`;
            let data = {"name": ''};
            this.editor.addNode('node-item', 1, 1, this.x, this.y, 'node-item', data, html);
        },
        saveAndClose() {
            if (!confirm(this.$t('Сохранить изменения?'))) return;
            this.$emit('change', this.editor.export());
            this.$emit('close');
        },
        setCurrentNodePosition(id){
            const offset = 20;
            const node = this.editor.getNodeFromId(id);

            this.x = node.pos_x + offset;
            this.y = node.pos_y + offset;
        }
    },
    mounted() {
        let id = document.getElementById("drawflow");
        this.editor = new Drawflow(id, Vue, this);
        this.editor.draggable_inputs = false;
        this.editor.start();

        if (this.data) {
            this.editor.import(this.data);
        }

        this.editor.on('nodeMoved', this.setCurrentNodePosition);
        this.editor.on('nodeDataChanged', this.setCurrentNodePosition);
        this.editor.on('nodeSelected', this.setCurrentNodePosition);
    },
    beforeDestroy() {
        this.editor.off('nodeMoved', this.setCurrentNodePosition);
        this.editor.off('nodeDataChanged', this.setCurrentNodePosition);
        this.editor.off('nodeSelected', this.setCurrentNodePosition);
    }
}
</script>

<style lang="less">

.drawflow-delete{
    font-family: 'Roboto Mono', Monaco, courier, monospace;
    line-height: 1.6;
    font-size: 16px;
}

.node-item {
    width: 250px !important;
    padding: 25px 10px 10px 10px !important;
    border: 1px solid #f7f7f7 !important;
    background: #f7f7f7 !important;
    box-shadow: 0 2px 15px 2px #cacaca;

    &.selected {
        box-shadow: 0 2px 15px 2px #4ea9ff;
    }

    textarea {
        width: 100%;
        font-size: 15px !important;
    }
}

#drawflow-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;

    .buttons {
        z-index: 1100;
        width: 100%;
    }

    #drawflow {
        border-top: 1px solid #ccc;
        width: 100%;
        height: 100vh;
        background: var(--background-color);
        background-size: 25px 25px;
        background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px), linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
    }
}


</style>
