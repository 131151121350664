import AbstractService from "@/services/AbstractService";

export default class AuthService extends AbstractService {

    constructor() {
        super();
    }

    login(email, password) {
        return this.axios.post('/auth/login', {email, password});
    }

    authByEbsToken(token){
        return this.axios.post('/auth/ebs_token', {token});
    }

    register(name, email, password) {
        return this.axios.post('/auth/register', {name, email, password});
    }

    me() {
        return this.axios.get('/auth/me');
    }

    logout() {
        return this.axios.delete('/auth/logout');
    }

    refresh() {
        return this.axios.post('/auth/refresh');
    }

}
