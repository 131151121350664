import AbstractService from "@/services/AbstractService";

export default class ConfigurationService extends AbstractService {
    constructor() {
        super();
    }

    get() {
        return this.axios.get("/configuration");
    }
}
