<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="{ 'modal-xl' : tab === 1 }">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Добавление групповой заметки НП') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs mb-3">
                        <li class="nav-item">
                            <a @click.stop="tab = 0" :class="{ 'active' : tab === 0 }" class="nav-link"
                               href="#">{{ $t('Заметка') }}</a>
                        </li>
                        <li class="nav-item">
                            <a @click.stop="tab = 1" :class="{ 'active' : tab === 1 }" class="nav-link" href="#">
                                {{ $t('Выбрать НП') }} ({{ selectedLocalities.length }})
                            </a>
                        </li>
                    </ul>

                    <template v-if="tab === 0">
                        <div class="form-group">
                            <label class="font-weight-bold">{{ $t('Группы') }}</label>
                            <div v-for="(item, index) in $store.state.groupsList" :key="index" class="form-check">
                                <input type="checkbox" v-model="selectedGroups" :value="index" class="form-check-input">
                                <label class="form-check-label">{{ item }}</label>
                            </div>
                            <small class="text-black-50">{{ $t('Не отмечать если заметка для всего НП') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold d-block">{{ $t('Цвет заметки') }}</label>
                            <div class="d-flex justify-content-between">
                                <label v-for="item in colors" :key="item" class="color-radio">
                                    <input type="radio" v-model="selectedColor" :value="item">
                                    <div class="color-radio-bg" :class="'bg-' + item">
                                        <i class="fas fa-check"></i>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold d-block">{{ $t('Тип заметки (влияет на цвет)') }}</label>
                            <select class="form-control"
                                    @change="selectedColor = $event.target.value === 0 ? 'primary' : $event.target.value">
                                <option :value="0">{{ $t('Не выбрано') }}</option>
                                <option :selected="selectedColor === 'warning'" value="warning">
                                    {{ $t('Исполнитель / координатор') }}
                                </option>
                                <option :selected="selectedColor === 'primary'" value="primary">
                                    {{ $t('Реализация оборудования') }}
                                </option>
                                <option :selected="selectedColor === 'danger'" value="danger">{{
                                        $t('Исключения')
                                    }}
                                </option>
                                <option :selected="selectedColor === 'success'" value="success">{{
                                        $t('Переход')
                                    }}
                                </option>
                                <option :selected="selectedColor === 'info'" value="info">{{ $t('Контакты') }}</option>
                                <option :selected="selectedColor === 'dark'" value="dark">{{
                                        $t('Постоянные акции')
                                    }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold">{{ $t('Заголовок') }} <span
                                class="text-danger">*</span></label>
                            <div class="position-relative">
                                <input v-model="title" type="text" class="form-control">
                                <TitleHistoryPopover type="locality" @click="title = $event"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold">{{ $t('Текст') }} <span class="text-danger">*</span></label>
                            <div class="position-relative">
                                <textarea v-model="note" class="form-control" rows="3"></textarea>
                                <NoteHistoryPopover type="locality" @click="note = $event"/>
                            </div>
                        </div>
                    </template>
                    <ModalLocalityPicker v-else
                                         :selectedLocalitiesProp="selectedLocalities"
                                         @change="selectedLocalities = $event"/>

                </div>

                <div class="modal-footer">
                    <button @click="create" :disabled="processing || !isValidForm" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import {mapState} from "vuex";
import NoteHistoryPopover from "@/components/NoteHistoryPopover";
import TitleHistoryPopover from "@/components/TitleHistoryPopover";
import ModalLocalityPicker from "@/components/ModalLocalityPicker";
import LocalityGroupNoteService from "@/services/LocalityGroupNoteService";
import {mutationTypes} from "@/store";

const LocalityGroupNote = new LocalityGroupNoteService();

export default {
    name: "CreateLocalityGroupNoteModal",
    components: {
        NoteHistoryPopover,
        TitleHistoryPopover,
        ModalLocalityPicker
    },
    data() {
        return {
            tab: 0,
            visible: false,
            processing: false,
            title: "",
            note: "",
            selectedLocalities: [],
            selectedGroups: [],
            selectedColor: "primary",
            colors: [
                "primary",
                "secondary",
                "success",
                "danger",
                "warning",
                "info",
                "dark",
            ]
        }
    },
    methods: {
        async create() {
            this.processing = true;
            const {code, body} = await LocalityGroupNote.create({
                note: this.note,
                title: this.title,
                locality_id: this.selectedLocalities,
                group_id: this.selectedGroups,
                color: this.selectedColor
            });

            if (code === 201) {
                this.$store.commit(mutationTypes.SET_LOCALITY_NOTES_HISTORY, body);
                this.$toast.success(this.$t('Заметка добавлена'));
                this.$emit("created");
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }

            this.processing = false;
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal() {
            this.visible = true;
        }
    },
    computed: {
        ...mapState({
            localities: state => state.localities
        }),
        isValidForm() {
            return this.title.length && this.note.length && this.selectedLocalities.length;
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.CREATE_LOCALITY_GROUP_NOTE_MODAL_OPEN, this.onShowModal);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.CREATE_LOCALITY_GROUP_NOTE_MODAL_OPEN, this.onShowModal);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    }
}
</script>

<style lang="less" scoped>

.color-radio {

    .color-radio-bg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;

        i {
            margin-top: 12px;
            color: #fff;
            visibility: hidden;
        }
    }

    input {
        display: none;
    }

    input:checked + .color-radio-bg i {
        visibility: visible;
    }
}

</style>
