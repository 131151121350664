<template>
    <div>
        <div v-if="preloader" class="sortable-preloader">
            <span>{{ $t('Сортируем...') }}</span>
        </div>
        <div ref="sortableBox">
            <slot></slot>
        </div>
    </div>
</template>

<script>

import Sortable from 'sortablejs';

export default {
    name: "SortableBox",
    props: {
        datasetKey: {
            type: String,
            default: "id"
        },
        preloader: {
            type: Boolean,
            default: false
        },
        enabled: {
            type: Boolean,
            default: false
        },
        handleClass: {
            type: String,
            default: ".handle-drag"
        }
    },
    mounted() {
        if (!this.enabled) {
            return;
        }

        Sortable.create(this.$refs.sortableBox, {
            group: "sortableBox_" + Math.random(),
            handle: this.handleClass,
            animation: 100,
            onEnd: e => {
                const data = Array.from(e.from.childNodes).map(item => item.dataset[this.datasetKey]);
                this.$emit("onSort", data);
            }
        });
    }
}
</script>

<style lang="less" scoped>

.sortable-preloader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;

    span {
        text-align: center;
        padding: 10px;
        display: block;
        background: fade(#000, 60);
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
    }
}

</style>
